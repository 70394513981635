import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'


function JurisdicoesAnoQuantidade() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('um')
  
    const [ano, setAno]=useState('2024')
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')
    const [paislist, setPaisList]=useState(false)
  
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];
  
    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
        let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
    let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setLoading(false)
  },[projetos])

  const lista=()=>{
    
    setTodos(projetos.concat(ProjetosHistorico))
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
        let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
    let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setPaisList(true)
  }
  
  const handleChart =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    
  
    const projsAno = todos.filter((val)=>{if(val.data.projStartDate.includes(ano) && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
  
    const numJaneiro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 0){return val}})
    const LJaneiro = numJaneiro.length
  
    const numFevereiro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 1){return val}})
    const LFevereiro = numFevereiro.length
  
    const numMarco = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 2){return val}})
    const LMarco = numMarco.length
  
    const numAbril = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 3){return val}})
    const LAbril = numAbril.length
  
    const numMaio = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 4){return val}})
    const LMaio = numMaio.length
  
    const numJunho = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 5){return val}})
    const LJunho = numJunho.length
  
    const numJulho = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 6){return val}})
    const LJulho = numJulho.length
  
    const numAgosto = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 7){return val}})
    const LAgosto = numAgosto.length
  
    const numSetembro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 8){return val}})
    const LSetembro = numSetembro.length
  
    const numOutubro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 9){return val}})
    const LOutubro = numOutubro.length
  
    const numNovembro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 10){return val}})
    const LNovembro = numNovembro.length
  
    const numDezembro = projsAno.filter((val)=>{if(new Date(val.data.projStartDate).getMonth() === 11){return val}})
    const LDezembro = numDezembro.length
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Quantidade - ${cliente1}`,
        data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: 'rgba(255,84,0,.5)',
      },
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    
    const numJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    
    const LJaneiro = numJaneiro.length
    const LFevereiro = numFevereiro.length
    const LMarco = numMarco.length
    const LAbril = numAbril.length
    const LMaio = numMaio.length
    const LJunho = numJunho.length
    const LJulho = numJulho.length
    const LAgosto = numAgosto.length
    const LSetembro = numSetembro.length
    const LOutubro = numOutubro.length
    const LNovembro = numNovembro.length
    const LDezembro = numDezembro.length
  
    const numJaneiro1 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numFevereiro1 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numMarco1 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numAbril1 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numMaio1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numJunho1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numJulho1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numAgosto1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numSetembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numOutubro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numNovembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numDezembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
  
  
  const LJaneiro1 = numJaneiro1.length
  const LFevereiro1 = numFevereiro1.length
  const LMarco1 = numMarco1.length
  const LAbril1 = numAbril1.length
  const LMaio1 = numMaio1.length
  const LJunho1 = numJunho1.length
  const LJulho1 = numJulho1.length
  const LAgosto1 = numAgosto1.length
  const LSetembro1 = numSetembro1.length
  const LOutubro1 = numOutubro1.length
  const LNovembro1 = numNovembro1.length
  const LDezembro1 = numDezembro1.length
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Quantidade - ${cliente1}`,
        data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: 'rgba(255,84,0,.5)',
      },
      {
        label:`Quantidade - ${cliente2}`,
        data: [LJaneiro1, LFevereiro1, LMarco1, LAbril1, LMaio1, LJunho1, LJulho1, LAgosto1, LSetembro1, LOutubro1, LNovembro1, LDezembro1 ],
        fill: true,
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      },
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart3 =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
     const numJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
      
      const numNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
      
      const numDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    
    const LJaneiro = numJaneiro.length
    const LFevereiro = numFevereiro.length
    const LMarco = numMarco.length
    const LAbril = numAbril.length
    const LMaio = numMaio.length
    const LJunho = numJunho.length
    const LJulho = numJulho.length
    const LAgosto = numAgosto.length
    const LSetembro = numSetembro.length
    const LOutubro = numOutubro.length
    const LNovembro = numNovembro.length
    const LDezembro = numDezembro.length
  
    const numJaneiro1 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numFevereiro1 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numMarco1 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numAbril1 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numMaio1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numJunho1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numJulho1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numAgosto1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numSetembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numOutubro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
      
      const numNovembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
      
      const numDezembro1= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
  
  
  const LJaneiro1 = numJaneiro1.length
  const LFevereiro1 = numFevereiro1.length
  const LMarco1 = numMarco1.length
  const LAbril1 = numAbril1.length
  const LMaio1 = numMaio1.length
  const LJunho1 = numJunho1.length
  const LJulho1 = numJulho1.length
  const LAgosto1 = numAgosto1.length
  const LSetembro1 = numSetembro1.length
  const LOutubro1 = numOutubro1.length
  const LNovembro1 = numNovembro1.length
  const LDezembro1 = numDezembro1.length
  
  const numJaneiro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)  ){return val}})
      
    const numFevereiro2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numMarco2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})
    
    const numAbril2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numMaio2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numJunho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numJulho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numAgosto2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numSetembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})
    
    const numOutubro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
    
    const numNovembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})
    
    const numDezembro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
  
  const LJaneiro2 = numJaneiro2.length
  const LFevereiro2 = numFevereiro2.length
  const LMarco2 = numMarco2.length
  const LAbril2 = numAbril2.length
  const LMaio2 = numMaio2.length
  const LJunho2 = numJunho2.length
  const LJulho2 = numJulho2.length
  const LAgosto2 = numAgosto2.length
  const LSetembro2 = numSetembro2.length
  const LOutubro2 = numOutubro2.length
  const LNovembro2 = numNovembro2.length
  const LDezembro2 = numDezembro2.length
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Quantidade - ${cliente1}`,
        data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: 'rgba(255,84,0,.5)',
      },
      {
        label:`Quantidade - ${cliente2}`,
        data: [LJaneiro1, LFevereiro1, LMarco1, LAbril1, LMaio1, LJunho1, LJulho1, LAgosto1, LSetembro1, LOutubro1, LNovembro1, LDezembro1 ],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      },
  
      {
        label:`Quantidade - ${cliente3}`,
        data: [LJaneiro2, LFevereiro2, LMarco2, LAbril2, LMaio2, LJunho2, LJulho2, LAgosto2, LSetembro2, LOutubro2, LNovembro2, LDezembro2 ],
        fill: true,
        borderColor: '#315F30',
        backgroundColor: 'rgba(49,95,48,.5)',
      },
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
    return (
      <div>
      <Header />
      {loading &&
         <div className="pageTitle">
         <div>Carregando...</div>
       </div>
      }
      {!loading &&
        <>
        <div className="pageTitle">
          <div>Jurisdições - Quantitativo de Projetos em Ano</div>
          {
            !paislist &&
            <div><button onClick={lista}>Gerar Lista</button></div>
           }
           
            {paislist  &&
            <>
          <div>
              <p>
                  Tipo:
                  <select defaultValue={'um'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                  <option value="">Selecione</option>
                  <option value="um">Uma Jurisdição</option>
                  <option value="dois">Duas Jurisdições</option>
                  <option value="tres">Três Jurisdições</option>
              </select>
              </p>
          </div>
          {tipo === 'um' &&
              <div className='tipo'>
                 <div className='selecionar'>
                  <p>Selecione um ano:</p>
                  <select defaultValue={'2024'} onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {anos.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' &&
                  <div><button onClick={handleChart}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'dois' &&
              <div className='tipo'>
                 <div className='selecionar'>
                  <p>Selecione um ano:</p>
                  <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {anos.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' && cliente2 !== '' &&
                  <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'tres' &&
            
              <div className='tipo'>
                <div className='selecionar'>
                  <p>Selecione um ano:</p>
                  <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {anos.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione uma Jurisdição:</p>
                  <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                  <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
              }
              </div>
          }
         </>}
          
        </div>
        {grafico &&
          <div className="pageContent">
          <div className="pageChart">
            <LineChart chartData={projectData} />
          </div>
        </div>
        }
  
        </>     
      }
  </div>
    )
}

export default JurisdicoesAnoQuantidade