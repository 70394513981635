import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'
import BarChart from '../../components/Charts/BarChart';

function MaioresPaisesAno() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])

    const [grafico, setGrafico]=useState(false)
   

    const [tipo, setTipo]=useState('Ano')

    const [ano1, setAno1]=useState('2024')
    const [ano2, setAno2]=useState('')
    const [ano3, setAno3]=useState('')

    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ]

    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
       
        return()=>{change()}
    
     },[]);

     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))
          setLoading(false)
        },[projetos])

        const handleChart = ()=>{
            setGrafico(false)

            let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projHiringCountry)
            let clientesemDuplicados = clientesAndamento.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
               
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length
          
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.quantidade < b.quantidade) {
                  return 1;
                }
                if (a.quantidade > b.quantidade) {
                  return -1;
                }
                return 0;
              })

            var clientes3 = clientes2.slice(0,15)
            
          
          

            setProjetData({
                labels: clientes3.map((i)=>i.name),
                datasets:[
                 {
                     label:`Quantidade de Projetos - ${ano1}`,
                     data: clientes3.map((item)=>item.quantidade),
                     fill: true,
                     backgroundColor: '#11D5EB',
                   },
                
                
         
               ]
              })

            setGrafico(true)
        }

        const handleChart2 = ()=>{
            setGrafico(false)
        
            let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projHiringCountry)
            let clientesAndamento2 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano2)){return val}}).map((i)=>i.data.projHiringCountry)
            let todosClientes = clientesAndamento.concat(clientesAndamento2)
            let clientesemDuplicados = todosClientes.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
               
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "soma":todosClientes.filter((val)=>{if(val === item){return val}}).length,
                  "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length,
                  "quantidade2": clientesAndamento2.filter((val)=>{if(val === item){return val}}).length
          
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.soma < b.soma) {
                  return 1;
                }
                if (a.soma > b.soma) {
                  return -1;
                }
                return 0;
              })

            var clientes3 = clientes2.slice(0,15)
            
          
          

            setProjetData({
                labels: clientes3.map((i)=>i.name),
                datasets:[
                    {
                     label:`Soma - ${ano1} ${ano2}`,
                     data: clientes3.map((item)=>item.soma),
                     fill: true,
                     backgroundColor: '#11D5EB',
                   },
                 {
                     label:`Quantidade de Projetos - ${ano1}`,
                     data: clientes3.map((item)=>item.quantidade),
                     fill: true,
                     backgroundColor: '#FF876C',
                   },
                
                   {
                    label:`Quantidade de Projetos - ${ano2}`,
                    data: clientes3.map((item)=>item.quantidade2),
                    fill: true,
                    backgroundColor: '#9EAD42',
                  },
         
               ]
              })

            setGrafico(true)
        }

        const handleChart3 = ()=>{
            setGrafico(false)
        
            let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projHiringCountry)
            let clientesAndamento2 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano2)){return val}}).map((i)=>i.data.projHiringCountry)
            let clientesAndamento3 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projStartDate.includes(ano3)){return val}}).map((i)=>i.data.projHiringCountry)
            let todosClientes = clientesAndamento.concat(clientesAndamento2)
            let todosClientes2 = todosClientes.concat(clientesAndamento3)
            let clientesemDuplicados = todosClientes2.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
               
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "soma":todosClientes2.filter((val)=>{if(val === item){return val}}).length,
                  "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length,
                  "quantidade2": clientesAndamento2.filter((val)=>{if(val === item){return val}}).length,
                  "quantidade3": clientesAndamento3.filter((val)=>{if(val === item){return val}}).length
          
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.soma < b.soma) {
                  return 1;
                }
                if (a.soma > b.soma) {
                  return -1;
                }
                return 0;
              })

            var clientes3 = clientes2.slice(0,15)
            
          
          

            setProjetData({
                labels: clientes3.map((i)=>i.name),
                datasets:[
                    {
                     label:`Soma - ${ano1} ${ano2} ${ano3}`,
                     data: clientes3.map((item)=>item.soma),
                     fill: true,
                     backgroundColor: '#11D5EB',
                   },
                 {
                     label:`Quantidade de Projetos - ${ano1}`,
                     data: clientes3.map((item)=>item.quantidade),
                     fill: true,
                     backgroundColor: '#FF876C',
                   },
                
                   {
                    label:`Quantidade de Projetos - ${ano2}`,
                    data: clientes3.map((item)=>item.quantidade2),
                    fill: true,
                    backgroundColor: '#9EAD42',
                  },
                  {
                    label:`Quantidade de Projetos - ${ano3}`,
                    data: clientes3.map((item)=>item.quantidade3),
                    fill: true,
                    backgroundColor: '#FF9900',
                  },
         
               ]
              })

            setGrafico(true)
        }
  return (
    <div>
    <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Maiores Países Ano -  Quantidade de Projetos</div>
       <div className='subtitle'>15 Maiores (soma de projetos)</div>
       <div>
            <p>
                Tipo:
                <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setAno1(''); setAno2(''); setAno3('');}}>
                <option value="">Selecione</option>
                <option value="Ano">Ano</option>
                <option value="Ano2">Dois Anos</option>
                <option value="Ano3">Três Anos</option>
            </select>
            </p>
        </div>
       
        {tipo === 'Ano' &&
            <div className='tipo'>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select defaultValue={'2024'} onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                {ano1 !== '' &&
                    <div><button onClick={handleChart}>Gerar Gráfico</button></div>
                }
        </div>
        }
        {tipo === 'Ano2' &&
            <div className='tipo'>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAno2(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                {ano1 !== '' && ano2 !== '' &&
                    <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
                }
        </div>
        }
         {tipo === 'Ano3' &&
            <div className='tipo'>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAno2(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAno3(e.target.value); setGrafico(false)}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>
                </div>
                {ano1 !== '' && ano2 !== '' && ano3 !== '' &&
                    <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
                }
        </div>
        }
         
     </div>
     {grafico &&
       <div className="pageContent">
       <div className="pageChart">
         <BarChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
</div>
  )
}

export default MaioresPaisesAno