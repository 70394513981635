import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function PaisesPeriodoQuantidade() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('')
  
   
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')
  
    const [de1, setDe1]=useState('')
      const [ate1, setAte1]=useState('')
      const [de2, setDe2]=useState('')
      const [ate2, setAte2]=useState('')
      const [de3, setDe3]=useState('')
      const [ate3, setAte3]=useState('')

      const [paislist, setPaisList]=useState(false)
  
   
    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setLoading(false)
  },[projetos])
  const lista =()=>{
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
    let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setPaisList(true)
  }
  
  const handleChart =()=>{
  
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    
  
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    
  
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno3 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
   
    
  
    const projsAno4 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
      {
        label:`Quantidade de Projetos - ${cliente2}`,
        data: [projsAno3, projsAno4],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: '#FF5400',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  const handleChart3 =()=>{
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    
  
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno3 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
   
    
  
    const projsAno4 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
    const projsAno5 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente3)  ){return val}}).length
   
    
  
    const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente3)  ){return val}}).length
  
  
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
      {
        label:`Quantidade de Projetos - ${cliente2}`,
        data: [projsAno3, projsAno4],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: '#FF5400',
      },
      {
        label:`Quantidade de Projetos - ${cliente3}`,
        data: [projsAno5, projsAno6],
        fill: true,
        borderColor: '#315F30',
        backgroundColor: '#315F30',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  const handleChart4 =()=>{
  
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    
  
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2, projsAno3],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  const handleChart5 =()=>{
  
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno4 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
   
    const projsAno5 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
    const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2, projsAno3],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
      {
        label:`Quantidade de Projetos - ${cliente2}`,
        data: [projsAno4, projsAno5 , projsAno6],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: '#FF5400',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  const handleChart6 =()=>{
  
    setGrafico(false)
    const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
   
    const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente1)  ){return val}}).length
  
    const projsAno4 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
   
    const projsAno5 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
    const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente2)  ){return val}}).length
  
    const projsAno7 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente3)  ){return val}}).length
   
    const projsAno8 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente3)  ){return val}}).length
  
    const projsAno9 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projHiringCountry.includes(cliente3)  ){return val}}).length
  
    setProjetData({
      labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
      datasets:[
       {
        label:`Quantidade de Projetos - ${cliente1}`,
        data: [projsAno, projsAno2, projsAno3],
        fill: true,
        borderColor: '#11D5EB',
        backgroundColor: '#11D5EB',
      },
      {
        label:`Quantidade de Projetos - ${cliente2}`,
        data: [projsAno4, projsAno5 , projsAno6],
        fill: true,
        borderColor: '#FF5400',
        backgroundColor: '#FF5400',
      },
      {
        label:`Quantidade de Projetos - ${cliente3}`,
        data: [projsAno7, projsAno8 , projsAno9],
        fill: true,
        borderColor: '#315F30',
        backgroundColor: '#315F30',
      },
     ],
     
     
    })
  
    setGrafico(true)
  }
  
  
    return (
      <div>
      <Header />
      {loading &&
         <div className="pageTitle">
         <div>Carregando...</div>
       </div>
      }
      {!loading &&
        <>
        <div className="pageTitle">
          <div>Países - Quantitativo de Projetos em Período</div>
          {
          !paislist &&
          <div><button onClick={lista}>Gerar Lista</button></div>
          }
          {paislist &&
            <>
          <div>
              <p>
                  Tipo:
                  <select onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                  <option value="">Selecione</option>
                  <option value="um">Um País - Dois Períodos</option>
                  <option value="dois">Dois Países - Dois Períodos</option>
                  <option value="tres">Três Países - Dois Períodos</option>
                  <option value="umt">Um País - Três Períodos</option>
                  <option value="doist">Dois Países - Três Períodos</option>
                  <option value="trest">Três Países - Três Períodos</option>
              </select>
              </p>
          </div>
          {tipo === 'um' &&
              <div className='tipo'>
                 <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' &&
                  <div><button onClick={handleChart}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'dois' &&
              <div className='tipo'>
                 <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
                 
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' && cliente2 !== '' &&
                  <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'tres' &&
              <>
              <div className='tipo'>
                <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              </div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                  <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
              }
              
              </>
          }
             {tipo === 'umt' &&
              <div className='tipo'>
                 <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione o 3° período:</p>
                  <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' &&
                  <div><button onClick={handleChart4}>Gerar Gráfico</button></div>
              }
              </div>
          }
           {tipo === 'doist' &&
              <div className='tipo'>
                 <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione o 3° período:</p>
                  <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' && cliente2 !== '' &&
                  <div><button onClick={handleChart5}>Gerar Gráfico</button></div>
              }
              </div>
          }
          {tipo === 'trest' &&
            <>
              <div className='tipo'>
                 <div className='selecionar'>
              <p>Selecione o 1° período:</p>
                  <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
              </div>
              <div className='selecionar'>
                  <p>Selecione o 2° período:</p>
                  <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione o 3° período:</p>
                  <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                  <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                      
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              <div className='selecionar'>
                  <p>Selecione um País:</p>
                  <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {clientes.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              </div>
              <div>
              {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                  <div><button onClick={handleChart6}>Gerar Gráfico</button></div>
              }
              </div>
              </>
          }
         </>}
          
        </div>
        {grafico &&
          <div className="pageContent">
          <div className="pageChart">
            <BarChart chartData={projectData} />
          </div>
        </div>
        }
  
        </>     
      }
  </div>
    )
}

export default PaisesPeriodoQuantidade