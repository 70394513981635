import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function QuantitativoPerioddo() {

    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    const [grafico2, setGrafico2]=useState(false)
    const [grafico3, setGrafico3]=useState(false)
    const [grafico4, setGrafico4]=useState(false)
    const [grafico5, setGrafico5]=useState(false)

    const [tipo, setTipo]=useState('Ano')

    const [ano1, setAno1]=useState('2024')
    const [ano2, setAno2]=useState('')
    const [ano3, setAno3]=useState('')

    const [de1, setDe1]=useState('')
    const [ate1, setAte1]=useState('')
    const [de2, setDe2]=useState('')
    const [ate2, setAte2]=useState('')
    const [de3, setDe3]=useState('')
    const [ate3, setAte3]=useState('')



    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      return()=>{change()}
  
   },[]);



 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
    setLoading(false)
  },[projetos])


  const handleChart = ()=>{

    setGrafico(false)
    setGrafico2(false)
    setGrafico3(false)
    setGrafico4(false)
    setGrafico5(false)

        
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
      const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
  
      const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
  
      const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumSetrembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
      
      const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
  
     const LJaneiro = NumJaneiro.length
     const LFevereiro = NumFevereiro.length
     const LMarco = NumMarco.length
     const LAbril= NumAbril.length
     const LMaio= NumMaio.length
     const LJunho= NumJunho.length
     const LJulho= NumJulho.length
     const LAgosto= NumAgosto.length
     const LSetembro= NumSetrembro.length
     const LOutubro= NumOutubro.length
     const LNovembro= NumNovembro.length
     const LDezembro= NumDezembro.length

   
       setProjetData({
           labels: meses,
           datasets:[
            {
             label:`Quantidade de Projetos - ${ano1}`,
             data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
             fill: true,
             borderColor: '#FF5400',
             backgroundColor: 'rgba(255,84,0,.5)',
           },
           
    
          ],
          
          
         })
         setGrafico(true);
     }

     const handleChart2 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
                
        const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    
        
       
        const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumSetrembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
      
         const LJaneiro = NumJaneiro.length
         const LFevereiro = NumFevereiro.length
         const LMarco = NumMarco.length
         const LAbril= NumAbril.length
         const LMaio= NumMaio.length
         const LJunho= NumJunho.length
         const LJulho= NumJulho.length
         const LAgosto= NumAgosto.length
         const LSetembro= NumSetrembro.length
         const LOutubro= NumOutubro.length
         const LNovembro= NumNovembro.length
         const LDezembro= NumDezembro.length


       // ANO 2

       const NumJaneiro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-01-01` && val.data.projStartDate <= `${ano2}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumFevereiro2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-02-01` && val.data.projStartDate <= `${ano2}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumMarco2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-03-01` && val.data.projStartDate <= `${ano2}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumAbril2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-04-01` && val.data.projStartDate <= `${ano2}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumMaio2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-05-01` && val.data.projStartDate <= `${ano2}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumJunho2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-06-01` && val.data.projStartDate <= `${ano2}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumJulho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-07-01` && val.data.projStartDate <= `${ano2}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumAgosto2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-08-01` && val.data.projStartDate <= `${ano2}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumSetrembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-09-01` && val.data.projStartDate <= `${ano2}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumOutubro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-10-01` && val.data.projStartDate <= `${ano2}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumNovembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-11-01` && val.data.projStartDate <= `${ano2}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumDezembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-12-01` && val.data.projStartDate <= `${ano2}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const LJaneiro2 = NumJaneiro2.length
        const LFevereiro2 = NumFevereiro2.length
        const LMarco2 = NumMarco2.length
        const LAbril2= NumAbril2.length
        const LMaio2= NumMaio2.length
        const LJunho2= NumJunho2.length
        const LJulho2= NumJulho2.length
        const LAgosto2= NumAgosto2.length
        const LSetembro2= NumSetrembro2.length
        const LOutubro2= NumOutubro2.length
        const LNovembro2= NumNovembro2.length
        const LDezembro2= NumDezembro2.length
    
       
           setProjetData({
               labels: meses,
               datasets:[
                {
                 label:`Quantidade de Projetos - ${ano1}`,
                 data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
                 fill: false,
                 borderColor: '#FF5400',
                 backgroundColor: 'rgba(255,84,0,.5)',
               },
               {
                label:`Quantidade de Projetos - ${ano2}`,
                data: [LJaneiro2, LFevereiro2, LMarco2, LAbril2, LMaio2, LJunho2, LJulho2, LAgosto2, LSetembro2, LOutubro2, LNovembro2, LDezembro2 ],
                fill: false,
                borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
              },
               
        
              ],
              
              
             })
             setGrafico2(true);
    }

    const handleChart3 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
                
        const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    
        const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-01-01` && val.data.projStartDate <= `${ano1}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-02-01` && val.data.projStartDate <= `${ano1}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-03-01` && val.data.projStartDate <= `${ano1}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-04-01` && val.data.projStartDate <= `${ano1}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-05-01` && val.data.projStartDate <= `${ano1}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-06-01` && val.data.projStartDate <= `${ano1}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-07-01` && val.data.projStartDate <= `${ano1}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-08-01` && val.data.projStartDate <= `${ano1}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumSetrembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-09-01` && val.data.projStartDate <= `${ano1}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-10-01` && val.data.projStartDate <= `${ano1}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-11-01` && val.data.projStartDate <= `${ano1}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano1}-12-01` && val.data.projStartDate <= `${ano1}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
      
         const LJaneiro = NumJaneiro.length
         const LFevereiro = NumFevereiro.length
         const LMarco = NumMarco.length
         const LAbril= NumAbril.length
         const LMaio= NumMaio.length
         const LJunho= NumJunho.length
         const LJulho= NumJulho.length
         const LAgosto= NumAgosto.length
         const LSetembro= NumSetrembro.length
         const LOutubro= NumOutubro.length
         const LNovembro= NumNovembro.length
         const LDezembro= NumDezembro.length


       // ANO 2

       const NumJaneiro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-01-01` && val.data.projStartDate <= `${ano2}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumFevereiro2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-02-01` && val.data.projStartDate <= `${ano2}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumMarco2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-03-01` && val.data.projStartDate <= `${ano2}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
        const NumAbril2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-04-01` && val.data.projStartDate <= `${ano2}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumMaio2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-05-01` && val.data.projStartDate <= `${ano2}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumJunho2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-06-01` && val.data.projStartDate <= `${ano2}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumJulho2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-07-01` && val.data.projStartDate <= `${ano2}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumAgosto2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-08-01` && val.data.projStartDate <= `${ano2}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumSetrembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-09-01` && val.data.projStartDate <= `${ano2}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumOutubro2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-10-01` && val.data.projStartDate <= `${ano2}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumNovembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-11-01` && val.data.projStartDate <= `${ano2}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const NumDezembro2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano2}-12-01` && val.data.projStartDate <= `${ano2}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const LJaneiro2 = NumJaneiro2.length
        const LFevereiro2 = NumFevereiro2.length
        const LMarco2 = NumMarco2.length
        const LAbril2= NumAbril2.length
        const LMaio2= NumMaio2.length
        const LJunho2= NumJunho2.length
        const LJulho2= NumJulho2.length
        const LAgosto2= NumAgosto2.length
        const LSetembro2= NumSetrembro2.length
        const LOutubro2= NumOutubro2.length
        const LNovembro2= NumNovembro2.length
        const LDezembro2= NumDezembro2.length

        //ANO 3
        const NumJaneiro3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-01-01` && val.data.projStartDate <= `${ano3}-01-31` && val.data.projStatus1 !== 'deletado' ){return val}})
    
          const NumFevereiro3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-02-01` && val.data.projStartDate <= `${ano3}-02-29` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumMarco3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-03-01` && val.data.projStartDate <= `${ano3}-03-31` && val.data.projStatus1 !== 'deletado' ){return val}})
      
          const NumAbril3 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-04-01` && val.data.projStartDate <= `${ano3}-04-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumMaio3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-05-01` && val.data.projStartDate <= `${ano3}-05-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJunho3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-06-01` && val.data.projStartDate <= `${ano3}-06-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumJulho3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-07-01` && val.data.projStartDate <= `${ano3}-07-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumAgosto3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-08-01` && val.data.projStartDate <= `${ano3}-08-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumSetrembro3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-09-01` && val.data.projStartDate <= `${ano3}-09-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumOutubro3= todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-10-01` && val.data.projStartDate <= `${ano3}-10-31` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumNovembro3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-11-01` && val.data.projStartDate <= `${ano3}-11-30` && val.data.projStatus1 !== 'deletado' ){return val}})
          
          const NumDezembro3 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano3}-12-01` && val.data.projStartDate <= `${ano3}-12-31` && val.data.projStatus1 !== 'deletado' ){return val}})
        
        const LJaneiro3 = NumJaneiro3.length
        const LFevereiro3 = NumFevereiro3.length
        const LMarco3 = NumMarco3.length
        const LAbril3= NumAbril3.length
        const LMaio3= NumMaio3.length
        const LJunho3= NumJunho3.length
        const LJulho3= NumJulho3.length
        const LAgosto3= NumAgosto3.length
        const LSetembro3= NumSetrembro3.length
        const LOutubro3= NumOutubro3.length
        const LNovembro3= NumNovembro3.length
        const LDezembro3= NumDezembro3.length
    
       
           setProjetData({
               labels: meses,
               datasets:[
                {
                 label:`Quantidade de Projetos - ${ano1}`,
                 data: [LJaneiro, LFevereiro, LMarco, LAbril, LMaio, LJunho, LJulho, LAgosto, LSetembro, LOutubro, LNovembro, LDezembro ],
                 fill: false,
                 borderColor: '#FF5400',
                 backgroundColor: 'rgba(255,84,0,.5)',
               },
               {
                label:`Quantidade de Projetos - ${ano2}`,
                data: [LJaneiro2, LFevereiro2, LMarco2, LAbril2, LMaio2, LJunho2, LJulho2, LAgosto2, LSetembro2, LOutubro2, LNovembro2, LDezembro2 ],
                fill: false,
                borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
              },
              {
                label:`Quantidade de Projetos - ${ano3}`,
                data: [LJaneiro3, LFevereiro3, LMarco3, LAbril3, LMaio3, LJunho3, LJulho3, LAgosto3, LSetembro3, LOutubro3, LNovembro3, LDezembro3 ],
                fill: false,
                borderColor: '#FF9900',
                backgroundColor: 'rgba(255,153,0,.5)',
              },
               
        
              ],
              
              
             })
             setGrafico3(true);
    }
    const handleChart4 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
    
            
        
    
        const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado' ){return val}}).length
       
        

        const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado' ){return val}}).length
       
        
       
           setProjetData({
               labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
               datasets:[
                {
                 label:`Quantidade de Projetos`,
                 data: [projsAno, projsAno2],
                 fill: true,
                 borderColor: '#FF5400',
                 backgroundColor: '#FF5400',
               },
              ],
              
              
             })
             setGrafico4(true);
    }

    const handleChart5 = ()=>{

        setGrafico(false)
        setGrafico2(false)
        setGrafico3(false)
        setGrafico4(false)
        setGrafico5(false)
    
            
        
    
        const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado' ){return val}}).length
       
        

        const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado' ){return val}}).length

        const projsAno3 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado' ){return val}}).length

       
        
       
           setProjetData({
               labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
               datasets:[
                {
                 label:`Quantidade de Projetos`,
                 data: [projsAno, projsAno2, projsAno3],
                 fill: true,
                 borderColor: '#FF5400',
                 backgroundColor: '#FF5400',
               },
              ],
              
              
             })
             setGrafico5(true);
         }
    


  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Quantitativo de Projetos - Período</div>
        <div>
            <p>
                Tipo:
                <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setGrafico2(false); setGrafico3(false); setGrafico4(false);setGrafico5(false); setAno1(''); setAno2(''); setAno3(''); setDe1(''); setDe2('');setDe3(''); setAte1(''); setAte2(''); setAte3('')}}>
                <option value="">Selecione</option>
                <option value="Ano" >Ano</option>
                <option value="Ano2">Dois Anos</option>
                <option value="Ano3">Três Anos</option>
                <option value="Periodo2">Dois Períodos</option>
                <option value="Periodo3">Três Períodos</option>
            </select>
            </p>
        </div>
        {tipo === 'Ano' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select defaultValue={"2024"}  onChange={(e)=>{setAno1(e.target.value); setGrafico(false); setGrafico2(false); setGrafico3(false); setGrafico4(false); setGrafico5(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Ano2' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno1(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno2(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' && ano2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }

        {tipo === 'Ano3' &&
            <div className='tipo'>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno1(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno2(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno3(e.target.value)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {ano1 !== '' && ano2 !== '' && ano3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Periodo2' &&
            <div className='tipo'>
            <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                    
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' &&
                <div><button onClick={handleChart4}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'Periodo3' &&
           <div className='tipo'>
           <div className='selecionar'>
           <p>Selecione o 1° período:</p>
               <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
           </div>
           <div className='selecionar'>
               <p>Selecione o 2° período:</p>
               <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                   
           </div>
           <div className='selecionar'>
               <p>Selecione o 3° período:</p>
               <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value)}} /></p>
               <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value)}} /></p>
                   
           </div>
           {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' &&
               <div><button onClick={handleChart5}>Gerar Gráfico</button></div>
           }
           </div>
        }
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

    {grafico2 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico3 &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico4 &&
        <div className="pageContent">
        <div className="pageChart">
          <BarChart chartData={projectData} />
        </div>
      </div>
      }
      {grafico5 &&
        <div className="pageContent">
        <div className="pageChart">
          <BarChart chartData={projectData} />
        </div>
      </div>
      }
      
      </>     
    }
</div>
  )
}

export default QuantitativoPerioddo