import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function Jurisdicoes6Meses() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [clientesCom, setClientesCom]=useState([])
    const [loading, setLoading]=useState(true);
    const [todos , setTodos]=useState([])
    const [lista, setLista]=useState(false)

    const hoje = new Date()

    const data1 = hoje.toISOString().slice(0, 10)
    const mesesDIminui = -5;
    function addMonths(date, months) {
        const newDate = new Date(date.valueOf());
        const currentMonth = newDate.getMonth();
        const newMonth = currentMonth + months;
        newDate.setMonth(newMonth);
        return newDate;
      }

    const data2A = addMonths(hoje, mesesDIminui);

    const data2 = data2A.toISOString().slice(0, 10)

    useEffect(()=>{
        const q = query(projectsCollectionRef);
        
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
        
        return()=>{change()}
    
     },[]);
  
       useLayoutEffect(()=>{
          setLoading(true)
            setTodos(projetos.concat(ProjetosHistorico))
  
            
            setLoading(false)
          },[projetos])

          const  handleLista = ()=>{
            let projetos6meses = todos.filter((val)=>{if(val.data.projStartDate >= data2 && val.data.projStartDate <= data1 && val.data.projStatus1 !== 'deletado' ){return val}}).map((i)=>i.data.projJurisdictions)
            
            let juriscicoesQtd = projetos6meses.reduce((list, sub) => list.concat(sub), [])
              let projetos6mesesParaData = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' ){return val}})
    
                
            let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
                let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
            let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
    
            let handleUltimaData = (item)=>{
            
                let datas1 = [] 
                let busca = item
                 for (let i = 0; i <projetos6mesesParaData.length;i++){
                    if(projetos6mesesParaData[i].data.projJurisdictions.includes(busca)){
                        datas1.push(projetos6mesesParaData[i])
                    }
                 }
                 let datas = datas1.map((i)=>i.data.projStartDate)
                
                  var dataOrganizada = datas.sort((a,b)=>{
                      if (a < b) {
                        return 1;
                      }
                      if (a > b) {
                        return -1;
                      }
                      return 0;
                    })
    
                     var dataFinal=dataOrganizada.slice(0,1)
                  
                    return(dataFinal)
            }
            let handleUltimaData2 = (item)=>{
                let datas1 = [] 
                let busca = item
                 for (let i = 0; i <todos.length;i++){
                    if(todos[i].data.projJurisdictions.includes(busca)){
                        datas1.push(todos[i])
                    }
                 }
                 let datas = datas1.map((i)=>i.data.projStartDate)
                
                  var dataOrganizada = datas.sort((a,b)=>{
                      if (a < b) {
                        return 1;
                      }
                      if (a > b) {
                        return -1;
                      }
                      return 0;
                    })
    
                     var dataFinal=dataOrganizada.slice(0,1)
                  
                    return(dataFinal)
            }
            let handleUltimaCliente = (item)=>{
                let datas1 = [] 
                let busca = item
                 for (let i = 0; i <todos.length;i++){
                    if(todos[i].data.projJurisdictions.includes(busca)){
                        datas1.push(todos[i])
                    }
                 }
                 
                
                 var dataOrganizada = datas1.sort((a,b)=>{
                    if (a.data.projStartDate < b.data.projStartDate) {
                      return 1;
                    }
                    if (a.data.projStartDate > b.data.projStartDate) {
                      return -1;
                    }
                    return 0;
                  })
                  let data = dataOrganizada.map((i)=>i.data.projClient)
                     var dataFinal=data.slice(0,1)
                  
                    return(dataFinal)
            }
    
    
            var clientes1 = clientesemDuplicados.map((item, index)=>(
              {
                "id": index,
                "name":item,
                "quantidadeTotal": juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length,
                "quantidadeUltimos": juriscicoesQtd.filter((val)=>{if(val === item){return val}}).length,
                "dataUltimo":handleUltimaData(item).toString(),
                "dataUltimo6":handleUltimaData2(item).toString(),
                "clienteUltimo":handleUltimaCliente(item).toString(),
                
            }
          ))
          console.log(clientes1)
    
          var clientes2 = clientes1.filter((val)=>{if(val.quantidadeUltimos === 0 && val.name !=="" ){return val}})
          
          var clientesZeroArrumados = clientes2.sort((a,b)=>{
              if (a.quantidadeTotal < b.quantidadeTotal) {
                return 1;
              }
              if (a.quantidadeTotal > b.quantidadeTotal) {
                return -1;
              }
              return 0;
            })
    
            setClientes(clientesZeroArrumados)
            
            var clientes3 = clientes1.filter((val)=>{if(val.quantidadeUltimos !== 0){return val}})
    
              var clientesArrumados = clientes3.sort((a,b)=>{
                if (a.quantidadeUltimos < b.quantidadeUltimos) {
                  return 1;
                }
                if (a.quantidadeUltimos > b.quantidadeUltimos) {
                  return -1;
                }
                return 0;
              })
    
            setClientesCom(clientesArrumados)
            setLista(true)
           }
          
           return (
            <div>
                <Header />
           {loading &&
              <div className="pageTitle">
              <div>Carregando...</div>
            </div>
           }
           {!loading &&
             <>
             <div className="pageTitle">
               <div>Jurisdições Nos Últimos 6 Meses</div>
               <button onClick={handleLista}>Gerar Lista</button>
             </div>
            { lista &&
            <div className="pageContent collum">
              <div className="contentLine2" style={{width:'100%', margin:'10px auto'}}>Jurisdições Com Projetos</div>
                  <div className="linesClientes" style={{width:'100%'}}>
                          <div className="lines-itemC" style={{fontWeight:800}}><p>Jurisdição</p></div>
                          <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos 6 Meses</p></div>
                          <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos</p></div>
                          <div className="lines-itemC" style={{fontWeight:800}}><p>Data Projeto Mais Recente</p></div>
                          <div className="lines-itemC" style={{fontWeight:800}}><p>Cliente Projeto Mais Recente</p></div>
                      </div>
                  <div style={{marginBottom:'50px'}}>
                      {clientesCom.map((item, index)=>(
                      <div className="linesClientes" style={{width:'100%'}} key={index}>
                          <div className="lines-itemC"><p>{item.name}</p></div>
                          <div className="lines-itemC"><p>{item.quantidadeUltimos}</p></div>
                          <div className="lines-itemC"><p>{item.quantidadeTotal}</p></div>
                          <div className="lines-itemC"><p>{new Date(item.dataUltimo6).toLocaleDateString('pt-br').slice(0,10)}</p></div>
                          <div className="lines-itemC"><p>{item.clienteUltimo}</p></div>
                      </div>
                      ))}        
                </div>
                <div className="contentLine2" style={{width:'100%', margin:'10px auto'}}>Jurisdições Sem Projetos</div>
                <div className="linesClientes" style={{width:'100%'}}>
                        <div className="lines-itemC" style={{fontWeight:800}}><p>Jurisdição</p></div>
                        <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos</p></div>
                        <div className="lines-itemC" style={{fontWeight:800}}><p>Data Projeto Mais Recente</p></div>
                        <div className="lines-itemC" style={{fontWeight:800}}><p>Cliente Projeto Mais Recente</p></div>
                    </div>
                <div style={{marginBottom:'50px'}}>
                    {clientes.map((item, index)=>(
                    <div className="linesClientes" style={{width:'100%'}}key={index}>
                        <div className="lines-itemC"><p>{item.name}</p></div>
                        <div className="lines-itemC"><p>{item.quantidadeTotal}</p></div>
                        <div className="lines-itemC"><p>{new Date(item.dataUltimo).toLocaleDateString('pt-br').slice(0,10)}</p></div>
                        <div className="lines-itemC"><p>{item.clienteUltimo}</p></div>
                    </div>
                    ))}        
                </div>
                
             </div>
             }
          
             
             </>     
           }
            </div>
          )
}

export default Jurisdicoes6Meses