import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function QtdFatHistorico() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      return()=>{change()}
  
   },[]);



 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
    setLoading(false)
  },[projetos])



  const handleChart = ()=>{

    setGrafico(false)
        
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];
   
    //2002 reais
    const projs2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')){return val}})
    const projs20021R = projs2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projs2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)
   
    //2002 dolar
    const projs20021D = projs2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projs2002.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

    //2003 reais
const projs2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003')){return val}})
const projs20031R = projs2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20031RLenght = projs20031R.length
var projs20031RNumeros = [];
for (var r1 = 0; r1 < projs20031RLenght; r1++)
projs20031RNumeros.push(Number(projs20031R[r1]))
const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20032R = projs2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20032RLenght = projs20032R.length
var projs20032RNumeros = [];
for (var r2 = 0; r2 < projs20032RLenght; r2++)
projs20032RNumeros.push(Number(projs20032R[r2]))
const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2003 = (projs2003Reais1+projs2003Reais2)

//2003 dolar
const projs20031D = projs2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20031DLenght = projs20031D.length
var projs20031DNumeros = [];
for (var d1 = 0; d1 < projs20031DLenght; d1++)
projs20031DNumeros.push(Number(projs20031D[d1]))
const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20032D = projs2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20032DLenght = projs20032D.length
var projs20032DNumeros = [];
for (var d2 = 0; d2 < projs20032DLenght; d2++)
projs20032DNumeros.push(Number(projs20032D[d2]))
const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

//2004 reais
const projs2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')){return val}})
const projs20041R = projs2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20041RLenght = projs20041R.length
var projs20041RNumeros = [];
for (var r1 = 0; r1 < projs20041RLenght; r1++)
projs20041RNumeros.push(Number(projs20041R[r1]))
const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20042R = projs2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20042RLenght = projs20042R.length
var projs20042RNumeros = [];
for (var r2 = 0; r2 < projs20042RLenght; r2++)
projs20042RNumeros.push(Number(projs20042R[r2]))
const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2004 = (projs2004Reais1+projs2004Reais2)

//2004 dolar
const projs20041D = projs2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20041DLenght = projs20041D.length
var projs20041DNumeros = [];
for (var d1 = 0; d1 < projs20041DLenght; d1++)
projs20041DNumeros.push(Number(projs20041D[d1]))
const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20042D = projs2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20042DLenght = projs20042D.length
var projs20042DNumeros = [];
for (var d2 = 0; d2 < projs20042DLenght; d2++)
projs20042DNumeros.push(Number(projs20042D[d2]))
const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

//2005 reais
const projs2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')){return val}})
const projs20051R = projs2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20051RLenght = projs20051R.length
var projs20051RNumeros = [];
for (var r1 = 0; r1 < projs20051RLenght; r1++)
projs20051RNumeros.push(Number(projs20051R[r1]))
const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20052R = projs2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20052RLenght = projs20052R.length
var projs20052RNumeros = [];
for (var r2 = 0; r2 < projs20052RLenght; r2++)
projs20052RNumeros.push(Number(projs20052R[r2]))
const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2005 = (projs2005Reais1+projs2005Reais2)

//2005 dolar
const projs20051D = projs2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20051DLenght = projs20051D.length
var projs20051DNumeros = [];
for (var d1 = 0; d1 < projs20051DLenght; d1++)
projs20051DNumeros.push(Number(projs20051D[d1]))
const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20052D = projs2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20052DLenght = projs20052D.length
var projs20052DNumeros = [];
for (var d2 = 0; d2 < projs20052DLenght; d2++)
projs20052DNumeros.push(Number(projs20052D[d2]))
const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

//2006 reais
const projs2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')){return val}})
const projs20061R = projs2006.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20061RLenght = projs20061R.length
var projs20061RNumeros = [];
for (var r1 = 0; r1 < projs20061RLenght; r1++)
projs20061RNumeros.push(Number(projs20061R[r1]))
const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20062R = projs2006.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20062RLenght = projs20062R.length
var projs20062RNumeros = [];
for (var r2 = 0; r2 < projs20062RLenght; r2++)
projs20062RNumeros.push(Number(projs20062R[r2]))
const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2006 = (projs2006Reais1+projs2006Reais2)

//2006 dolar
const projs20061D = projs2006.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20061DLenght = projs20061D.length
var projs20061DNumeros = [];
for (var d1 = 0; d1 < projs20061DLenght; d1++)
projs20061DNumeros.push(Number(projs20061D[d1]))
const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20062D = projs2006.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20062DLenght = projs20062D.length
var projs20062DNumeros = [];
for (var d2 = 0; d2 < projs20062DLenght; d2++)
projs20062DNumeros.push(Number(projs20062D[d2]))
const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)

//2007 reais
const projs2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')){return val}})
const projs20071R = projs2007.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20071RLenght = projs20071R.length
var projs20071RNumeros = [];
for (var r1 = 0; r1 < projs20071RLenght; r1++)
projs20071RNumeros.push(Number(projs20071R[r1]))
const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20072R = projs2007.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20072RLenght = projs20072R.length
var projs20072RNumeros = [];
for (var r2 = 0; r2 < projs20072RLenght; r2++)
projs20072RNumeros.push(Number(projs20072R[r2]))
const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2007 = (projs2007Reais1+projs2007Reais2)

//2007 dolar
const projs20071D = projs2007.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20071DLenght = projs20071D.length
var projs20071DNumeros = [];
for (var d1 = 0; d1 < projs20071DLenght; d1++)
projs20071DNumeros.push(Number(projs20071D[d1]))
const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20072D = projs2007.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20072DLenght = projs20072D.length
var projs20072DNumeros = [];
for (var d2 = 0; d2 < projs20072DLenght; d2++)
projs20072DNumeros.push(Number(projs20072D[d2]))
const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

//2008 reais
const projs2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')){return val}})
const projs20081R = projs2008.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20081RLenght = projs20081R.length
var projs20081RNumeros = [];
for (var r1 = 0; r1 < projs20081RLenght; r1++)
projs20081RNumeros.push(Number(projs20081R[r1]))
const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20082R = projs2008.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20082RLenght = projs20082R.length
var projs20082RNumeros = [];
for (var r2 = 0; r2 < projs20082RLenght; r2++)
projs20082RNumeros.push(Number(projs20082R[r2]))
const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2008 = (projs2008Reais1+projs2008Reais2)

//2008 dolar
const projs20081D = projs2008.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20081DLenght = projs20081D.length
var projs20081DNumeros = [];
for (var d1 = 0; d1 < projs20081DLenght; d1++)
projs20081DNumeros.push(Number(projs20081D[d1]))
const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20082D = projs2008.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20082DLenght = projs20082D.length
var projs20082DNumeros = [];
for (var d2 = 0; d2 < projs20082DLenght; d2++)
projs20082DNumeros.push(Number(projs20082D[d2]))
const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)

//2009 reais
const projs2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')){return val}})
const projs20091R = projs2009.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20091RLenght = projs20091R.length
var projs20091RNumeros = [];
for (var r1 = 0; r1 < projs20091RLenght; r1++)
projs20091RNumeros.push(Number(projs20091R[r1]))
const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20092R = projs2009.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20092RLenght = projs20092R.length
var projs20092RNumeros = [];
for (var r2 = 0; r2 < projs20092RLenght; r2++)
projs20092RNumeros.push(Number(projs20092R[r2]))
const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2009 = (projs2009Reais1+projs2009Reais2)

//2009 dolar
const projs20091D = projs2009.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20091DLenght = projs20091D.length
var projs20091DNumeros = [];
for (var d1 = 0; d1 < projs20091DLenght; d1++)
projs20091DNumeros.push(Number(projs20091D[d1]))
const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20092D = projs2009.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20092DLenght = projs20092D.length
var projs20092DNumeros = [];
for (var d2 = 0; d2 < projs20092DLenght; d2++)
projs20092DNumeros.push(Number(projs20092D[d2]))
const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)

//2010 reais
const projs2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')){return val}})
const projs20101R = projs2010.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20101RLenght = projs20101R.length
var projs20101RNumeros = [];
for (var r1 = 0; r1 < projs20101RLenght; r1++)
projs20101RNumeros.push(Number(projs20101R[r1]))
const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20102R = projs2010.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20102RLenght = projs20102R.length
var projs20102RNumeros = [];
for (var r2 = 0; r2 < projs20102RLenght; r2++)
projs20102RNumeros.push(Number(projs20102R[r2]))
const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2010 = (projs2010Reais1+projs2010Reais2)

//2010 dolar
const projs20101D = projs2010.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20101DLenght = projs20101D.length
var projs20101DNumeros = [];
for (var d1 = 0; d1 < projs20101DLenght; d1++)
projs20101DNumeros.push(Number(projs20101D[d1]))
const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20102D = projs2010.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20102DLenght = projs20102D.length
var projs20102DNumeros = [];
for (var d2 = 0; d2 < projs20102DLenght; d2++)
projs20102DNumeros.push(Number(projs20102D[d2]))
const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

//2011 reais
const projs2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')){return val}})
const projs20111R = projs2011.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20111RLenght = projs20111R.length
var projs20111RNumeros = [];
for (var r1 = 0; r1 < projs20111RLenght; r1++)
projs20111RNumeros.push(Number(projs20111R[r1]))
const projs2011Reais1 = projs20111RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20112R = projs2011.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20112RLenght = projs20112R.length
var projs20112RNumeros = [];
for (var r2 = 0; r2 < projs20112RLenght; r2++)
projs20112RNumeros.push(Number(projs20112R[r2]))
const projs2011Reais2 = projs20112RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2011 = (projs2011Reais1+projs2011Reais2)

//2011 dolar
const projs20111D = projs2011.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20111DLenght = projs20111D.length
var projs20111DNumeros = [];
for (var d1 = 0; d1 < projs20111DLenght; d1++)
projs20111DNumeros.push(Number(projs20111D[d1]))
const projs2011Dolar1 = projs20111DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20112D = projs2011.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20112DLenght = projs20112D.length
var projs20112DNumeros = [];
for (var d2 = 0; d2 < projs20112DLenght; d2++)
projs20112DNumeros.push(Number(projs20112D[d2]))
const projs2011Dolar2 = projs20112DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2011 = (projs2011Dolar1+projs2011Dolar2)

//2012 reais
const projs2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')){return val}})
const projs20121R = projs2012.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20121RLenght = projs20121R.length
var projs20121RNumeros = [];
for (var r1 = 0; r1 < projs20121RLenght; r1++)
projs20121RNumeros.push(Number(projs20121R[r1]))
const projs2012Reais1 = projs20121RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20122R = projs2012.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20122RLenght = projs20122R.length
var projs20122RNumeros = [];
for (var r2 = 0; r2 < projs20122RLenght; r2++)
projs20122RNumeros.push(Number(projs20122R[r2]))
const projs2012Reais2 = projs20122RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2012 = (projs2012Reais1+projs2012Reais2)

//2012 dolar
const projs20121D = projs2012.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20121DLenght = projs20121D.length
var projs20121DNumeros = [];
for (var d1 = 0; d1 < projs20121DLenght; d1++)
projs20121DNumeros.push(Number(projs20121D[d1]))
const projs2012Dolar1 = projs20121DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20122D = projs2012.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20122DLenght = projs20122D.length
var projs20122DNumeros = [];
for (var d2 = 0; d2 < projs20122DLenght; d2++)
projs20122DNumeros.push(Number(projs20122D[d2]))
const projs2012Dolar2 = projs20122DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2012 = (projs2012Dolar1+projs2012Dolar2)

//2013 reais
const projs2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')){return val}})
const projs20131R = projs2013.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20131RLenght = projs20131R.length
var projs20131RNumeros = [];
for (var r1 = 0; r1 < projs20131RLenght; r1++)
projs20131RNumeros.push(Number(projs20131R[r1]))
const projs2013Reais1 = projs20131RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20132R = projs2013.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20132RLenght = projs20132R.length
var projs20132RNumeros = [];
for (var r2 = 0; r2 < projs20132RLenght; r2++)
projs20132RNumeros.push(Number(projs20132R[r2]))
const projs2013Reais2 = projs20132RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2013 = (projs2013Reais1+projs2013Reais2)

//2013 dolar
const projs20131D = projs2013.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20131DLenght = projs20131D.length
var projs20131DNumeros = [];
for (var d1 = 0; d1 < projs20131DLenght; d1++)
projs20131DNumeros.push(Number(projs20131D[d1]))
const projs2013Dolar1 = projs20131DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20132D = projs2013.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20132DLenght = projs20132D.length
var projs20132DNumeros = [];
for (var d2 = 0; d2 < projs20132DLenght; d2++)
projs20132DNumeros.push(Number(projs20132D[d2]))
const projs2013Dolar2 = projs20132DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2013 = (projs2013Dolar1+projs2013Dolar2)

//2014 reais
const projs2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')){return val}})
const projs20141R = projs2014.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20141RLenght = projs20141R.length
var projs20141RNumeros = [];
for (var r1 = 0; r1 < projs20141RLenght; r1++)
projs20141RNumeros.push(Number(projs20141R[r1]))
const projs2014Reais1 = projs20141RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20142R = projs2014.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20142RLenght = projs20142R.length
var projs20142RNumeros = [];
for (var r2 = 0; r2 < projs20142RLenght; r2++)
projs20142RNumeros.push(Number(projs20142R[r2]))
const projs2014Reais2 = projs20142RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2014 = (projs2014Reais1+projs2014Reais2)

//2014 dolar
const projs20141D = projs2014.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20141DLenght = projs20141D.length
var projs20141DNumeros = [];
for (var d1 = 0; d1 < projs20141DLenght; d1++)
projs20141DNumeros.push(Number(projs20141D[d1]))
const projs2014Dolar1 = projs20141DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20142D = projs2014.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20142DLenght = projs20142D.length
var projs20142DNumeros = [];
for (var d2 = 0; d2 < projs20142DLenght; d2++)
projs20142DNumeros.push(Number(projs20142D[d2]))
const projs2014Dolar2 = projs20142DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2014 = (projs2014Dolar1+projs2014Dolar2)


//2015 reais
const projs2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')){return val}})
const projs20151R = projs2015.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20151RLenght = projs20151R.length
var projs20151RNumeros = [];
for (var r1 = 0; r1 < projs20151RLenght; r1++)
projs20151RNumeros.push(Number(projs20151R[r1]))
const projs2015Reais1 = projs20151RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20152R = projs2015.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20152RLenght = projs20152R.length
var projs20152RNumeros = [];
for (var r2 = 0; r2 < projs20152RLenght; r2++)
projs20152RNumeros.push(Number(projs20152R[r2]))
const projs2015Reais2 = projs20152RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2015 = (projs2015Reais1+projs2015Reais2)

//2015 dolar
const projs20151D = projs2015.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20151DLenght = projs20151D.length
var projs20151DNumeros = [];
for (var d1 = 0; d1 < projs20151DLenght; d1++)
projs20151DNumeros.push(Number(projs20151D[d1]))
const projs2015Dolar1 = projs20151DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20152D = projs2015.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20152DLenght = projs20152D.length
var projs20152DNumeros = [];
for (var d2 = 0; d2 < projs20152DLenght; d2++)
projs20152DNumeros.push(Number(projs20152D[d2]))
const projs2015Dolar2 = projs20152DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2015 = (projs2015Dolar1+projs2015Dolar2)

//2016 reais
const projs2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')){return val}})
const projs20161R = projs2016.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20161RLenght = projs20161R.length
var projs20161RNumeros = [];
for (var r1 = 0; r1 < projs20161RLenght; r1++)
projs20161RNumeros.push(Number(projs20161R[r1]))
const projs2016Reais1 = projs20161RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20162R = projs2016.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20162RLenght = projs20162R.length
var projs20162RNumeros = [];
for (var r2 = 0; r2 < projs20162RLenght; r2++)
projs20162RNumeros.push(Number(projs20162R[r2]))
const projs2016Reais2 = projs20162RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2016 = (projs2016Reais1+projs2016Reais2)

//2016 dolar
const projs20161D = projs2016.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20161DLenght = projs20161D.length
var projs20161DNumeros = [];
for (var d1 = 0; d1 < projs20161DLenght; d1++)
projs20161DNumeros.push(Number(projs20161D[d1]))
const projs2016Dolar1 = projs20161DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20162D = projs2016.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20162DLenght = projs20162D.length
var projs20162DNumeros = [];
for (var d2 = 0; d2 < projs20162DLenght; d2++)
projs20162DNumeros.push(Number(projs20162D[d2]))
const projs2016Dolar2 = projs20162DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2016 = (projs2016Dolar1+projs2016Dolar2)

//2017 reais
const projs2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')){return val}})
const projs20171R = projs2017.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20171RLenght = projs20171R.length
var projs20171RNumeros = [];
for (var r1 = 0; r1 < projs20171RLenght; r1++)
projs20171RNumeros.push(Number(projs20171R[r1]))
const projs2017Reais1 = projs20171RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20172R = projs2017.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20172RLenght = projs20172R.length
var projs20172RNumeros = [];
for (var r2 = 0; r2 < projs20172RLenght; r2++)
projs20172RNumeros.push(Number(projs20172R[r2]))
const projs2017Reais2 = projs20172RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2017 = (projs2017Reais1+projs2017Reais2)

//2017 dolar
const projs20171D = projs2017.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20171DLenght = projs20171D.length
var projs20171DNumeros = [];
for (var d1 = 0; d1 < projs20171DLenght; d1++)
projs20171DNumeros.push(Number(projs20171D[d1]))
const projs2017Dolar1 = projs20171DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20172D = projs2017.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20172DLenght = projs20172D.length
var projs20172DNumeros = [];
for (var d2 = 0; d2 < projs20172DLenght; d2++)
projs20172DNumeros.push(Number(projs20172D[d2]))
const projs2017Dolar2 = projs20172DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2017 = (projs2017Dolar1+projs2017Dolar2)

//2018 reais
const projs2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')){return val}})
const projs20181R = projs2018.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20181RLenght = projs20181R.length
var projs20181RNumeros = [];
for (var r1 = 0; r1 < projs20181RLenght; r1++)
projs20181RNumeros.push(Number(projs20181R[r1]))
const projs2018Reais1 = projs20181RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20182R = projs2018.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20182RLenght = projs20182R.length
var projs20182RNumeros = [];
for (var r2 = 0; r2 < projs20182RLenght; r2++)
projs20182RNumeros.push(Number(projs20182R[r2]))
const projs2018Reais2 = projs20182RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2018 = (projs2018Reais1+projs2018Reais2)

//2018 dolar
const projs20181D = projs2018.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20181DLenght = projs20181D.length
var projs20181DNumeros = [];
for (var d1 = 0; d1 < projs20181DLenght; d1++)
projs20181DNumeros.push(Number(projs20181D[d1]))
const projs2018Dolar1 = projs20181DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20182D = projs2018.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20182DLenght = projs20182D.length
var projs20182DNumeros = [];
for (var d2 = 0; d2 < projs20182DLenght; d2++)
projs20182DNumeros.push(Number(projs20182D[d2]))
const projs2018Dolar2 = projs20182DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2018 = (projs2018Dolar1+projs2018Dolar2)

//2019 reais
const projs2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')){return val}})
const projs20191R = projs2019.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20191RLenght = projs20191R.length
var projs20191RNumeros = [];
for (var r1 = 0; r1 < projs20191RLenght; r1++)
projs20191RNumeros.push(Number(projs20191R[r1]))
const projs2019Reais1 = projs20191RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20192R = projs2019.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20192RLenght = projs20192R.length
var projs20192RNumeros = [];
for (var r2 = 0; r2 < projs20192RLenght; r2++)
projs20192RNumeros.push(Number(projs20192R[r2]))
const projs2019Reais2 = projs20192RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2019 = (projs2019Reais1+projs2019Reais2)

//2019 dolar
const projs20191D = projs2019.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20191DLenght = projs20191D.length
var projs20191DNumeros = [];
for (var d1 = 0; d1 < projs20191DLenght; d1++)
projs20191DNumeros.push(Number(projs20191D[d1]))
const projs2019Dolar1 = projs20191DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20192D = projs2019.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20192DLenght = projs20192D.length
var projs20192DNumeros = [];
for (var d2 = 0; d2 < projs20192DLenght; d2++)
projs20192DNumeros.push(Number(projs20192D[d2]))
const projs2019Dolar2 = projs20192DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2019 = (projs2019Dolar1+projs2019Dolar2)

//2020 reais
const projs2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')){return val}})
const projs20201R = projs2020.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20201RLenght = projs20201R.length
var projs20201RNumeros = [];
for (var r1 = 0; r1 < projs20201RLenght; r1++)
projs20201RNumeros.push(Number(projs20201R[r1]))
const projs2020Reais1 = projs20201RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20202R = projs2020.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20202RLenght = projs20202R.length
var projs20202RNumeros = [];
for (var r2 = 0; r2 < projs20202RLenght; r2++)
projs20202RNumeros.push(Number(projs20202R[r2]))
const projs2020Reais2 = projs20202RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2020 = (projs2020Reais1+projs2020Reais2)

//2020 dolar
const projs20201D = projs2020.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20201DLenght = projs20201D.length
var projs20201DNumeros = [];
for (var d1 = 0; d1 < projs20201DLenght; d1++)
projs20201DNumeros.push(Number(projs20201D[d1]))
const projs2020Dolar1 = projs20201DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20202D = projs2020.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20202DLenght = projs20202D.length
var projs20202DNumeros = [];
for (var d2 = 0; d2 < projs20202DLenght; d2++)
projs20202DNumeros.push(Number(projs20202D[d2]))
const projs2020Dolar2 = projs20202DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2020 = (projs2020Dolar1+projs2020Dolar2)

//2021 reais
const projs2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')){return val}})
const projs20211R = projs2021.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20211RLenght = projs20211R.length
var projs20211RNumeros = [];
for (var r1 = 0; r1 < projs20211RLenght; r1++)
projs20211RNumeros.push(Number(projs20211R[r1]))
const projs2021Reais1 = projs20211RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20212R = projs2021.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20212RLenght = projs20212R.length
var projs20212RNumeros = [];
for (var r2 = 0; r2 < projs20212RLenght; r2++)
projs20212RNumeros.push(Number(projs20212R[r2]))
const projs2021Reais2 = projs20212RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2021 = (projs2021Reais1+projs2021Reais2)

//2021 dolar
const projs20211D = projs2021.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20211DLenght = projs20211D.length
var projs20211DNumeros = [];
for (var d1 = 0; d1 < projs20211DLenght; d1++)
projs20211DNumeros.push(Number(projs20211D[d1]))
const projs2021Dolar1 = projs20211DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20212D = projs2021.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20212DLenght = projs20212D.length
var projs20212DNumeros = [];
for (var d2 = 0; d2 < projs20212DLenght; d2++)
projs20212DNumeros.push(Number(projs20212D[d2]))
const projs2021Dolar2 = projs20212DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2021 = (projs2021Dolar1+projs2021Dolar2)

//2022 reais
const projs2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')){return val}})
const projs20221R = projs2022.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20221RLenght = projs20221R.length
var projs20221RNumeros = [];
for (var r1 = 0; r1 < projs20221RLenght; r1++)
projs20221RNumeros.push(Number(projs20221R[r1]))
const projs2022Reais1 = projs20221RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20222R = projs2022.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20222RLenght = projs20222R.length
var projs20222RNumeros = [];
for (var r2 = 0; r2 < projs20222RLenght; r2++)
projs20222RNumeros.push(Number(projs20222R[r2]))
const projs2022Reais2 = projs20222RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2022 = (projs2022Reais1+projs2022Reais2)

//2022 dolar
const projs20221D = projs2022.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20221DLenght = projs20221D.length
var projs20221DNumeros = [];
for (var d1 = 0; d1 < projs20221DLenght; d1++)
projs20221DNumeros.push(Number(projs20221D[d1]))
const projs2022Dolar1 = projs20221DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20222D = projs2022.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20222DLenght = projs20222D.length
var projs20222DNumeros = [];
for (var d2 = 0; d2 < projs20222DLenght; d2++)
projs20222DNumeros.push(Number(projs20222D[d2]))
const projs2022Dolar2 = projs20222DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2022 = (projs2022Dolar1+projs2022Dolar2)

//2023 reais
const projs2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')){return val}})
const projs20231R = projs2023.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20231RLenght = projs20231R.length
var projs20231RNumeros = [];
for (var r1 = 0; r1 < projs20231RLenght; r1++)
projs20231RNumeros.push(Number(projs20231R[r1]))
const projs2023Reais1 = projs20231RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20232R = projs2023.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20232RLenght = projs20232R.length
var projs20232RNumeros = [];
for (var r2 = 0; r2 < projs20232RLenght; r2++)
projs20232RNumeros.push(Number(projs20232R[r2]))
const projs2023Reais2 = projs20232RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2023 = (projs2023Reais1+projs2023Reais2)

//2023 dolar
const projs20231D = projs2023.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20231DLenght = projs20231D.length
var projs20231DNumeros = [];
for (var d1 = 0; d1 < projs20231DLenght; d1++)
projs20231DNumeros.push(Number(projs20231D[d1]))
const projs2023Dolar1 = projs20231DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20232D = projs2023.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20232DLenght = projs20232D.length
var projs20232DNumeros = [];
for (var d2 = 0; d2 < projs20232DLenght; d2++)
projs20232DNumeros.push(Number(projs20232D[d2]))
const projs2023Dolar2 = projs20232DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2023 = (projs2023Dolar1+projs2023Dolar2)


//2024 reais
const projs2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado'){return val}})
const projs20241R = projs2024.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20241RLenght = projs20241R.length
var projs20241RNumeros = [];
for (var r1 = 0; r1 < projs20241RLenght; r1++)
projs20241RNumeros.push(Number(projs20241R[r1]))
const projs2024Reais1 = projs20241RNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20242R = projs2024.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20242RLenght = projs20242R.length
var projs20242RNumeros = [];
for (var r2 = 0; r2 < projs20242RLenght; r2++)
projs20242RNumeros.push(Number(projs20242R[r2]))
const projs2024Reais2 = projs20242RNumeros.reduce((acc, curr) => acc + curr, 0)

const Reais2024 = (projs2024Reais1+projs2024Reais2)

//2024 dolar
const projs20241D = projs2024.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
var projs20241DLenght = projs20241D.length
var projs20241DNumeros = [];
for (var d1 = 0; d1 < projs20241DLenght; d1++)
projs20241DNumeros.push(Number(projs20241D[d1]))
const projs2024Dolar1 = projs20241DNumeros.reduce((acc, curr) => acc + curr, 0)
    
const projs20242D = projs2024.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
var projs20242DLenght = projs20242D.length
var projs20242DNumeros = [];
for (var d2 = 0; d2 < projs20242DLenght; d2++)
projs20242DNumeros.push(Number(projs20242D[d2]))
const projs2024Dolar2 = projs20242DNumeros.reduce((acc, curr) => acc + curr, 0)

const Dolar2024 = (projs2024Dolar1+projs2024Dolar2)

//quantitativos
    const L2002 = projs2002.length
    const L2003 = projs2003.length
    const L2004 = projs2004.length
    const L2005 = projs2005.length
    const L2006 = projs2006.length
    const L2007 = projs2007.length
    const L2008 = projs2008.length
    const L2009 = projs2009.length
    const L2010 = projs2010.length
    const L2011 = projs2011.length
    const L2012 = projs2012.length
    const L2013 = projs2013.length
    const L2014 = projs2014.length
    const L2015 = projs2015.length
    const L2016 = projs2016.length
    const L2017 = projs2017.length
    const L2018 = projs2018.length
    const L2019 = projs2019.length
    const L2020 = projs2020.length
    const L2021 = projs2021.length
    const L2022 = projs2022.length
    const L2023 = projs2023.length
    const L2024 = projs2024.length


       setProjetData({
           labels: anos,
           datasets:[
            {
                label:'Quantidade de Projetos',
                data: [L2002, L2003, L2004, L2005, L2006, L2007, L2008, L2009, L2010, L2011,L2012, L2013,L2014, L2015, L2016, L2017, L2018, L2019, L2020, L2021, L2022, L2023, L2024],
                fill: true,
                borderColor: '#FF5400',
                backgroundColor: 'rgba(255,84,0,.5)',
              },
            {
                label:'Dolar',
                data: [Dolar2002, Dolar2003, Dolar2004, Dolar2005, Dolar2006, Dolar2007, Dolar2008, Dolar2009, Dolar2010, Dolar2011, Dolar2012, Dolar2013, Dolar2014, Dolar2015, Dolar2016, Dolar2017, Dolar2018, Dolar2019, Dolar2020, Dolar2021, Dolar2022, Dolar2023, Dolar2024],
                fill: true,
                borderColor: '#315F30',
                backgroundColor: 'rgba(49,95,48,.5)',
              },
            {
             label:'Reais',
             data: [Reais2002, Reais2003, Reais2004, Reais2005, Reais2006 , Reais2007, Reais2008, Reais2009, Reais2010, Reais2011, Reais2012 , Reais2013, Reais2014, Reais2015, Reais2016, Reais2017, Reais2018, Reais2019, Reais2020, Reais2021, Reais2022, Reais2023, Reais2024],
             fill: true,
             borderColor: '#FF9900',
             backgroundColor: 'rgba(255,153,0,.5)',
           }
           
    
          ]
         })
         setGrafico(true);
     }
  return (
    <div>
    <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Histórico de Quantitativo e Faturamento de Projetos</div>
       <div><button onClick={handleChart}>Gerar Gráfico</button></div>
     </div>
     {grafico &&
       <div className="pageContent">
       <div className="pageChart">
         <LineChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
</div>
  )
}

export default QtdFatHistorico