import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'
import BarChart from '../../components/Charts/BarChart';

function MaioresJurisciçõesFatAno() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
  
    const [grafico, setGrafico]=useState(false)
   
  
    const [tipo, setTipo]=useState('Ano')
  
    const [ano1, setAno1]=useState('2024')
    const [ano2, setAno2]=useState('')
    const [ano3, setAno3]=useState('')
  
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ]
  
    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
       
        return()=>{change()}
    
     },[]);
  
     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))
          setLoading(false)
        },[projetos])
  
    const handleChart =()=>{
      setGrafico(false)
              let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projJurisdictions)
                let juriscicoesNomes = clientesAndamento.reduce((list, sub) => list.concat(sub), [])
              let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });
            
              var dolar = (nome)=>{
                  const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var dolar1L = dolar1.length
                  var dolar1N = [];
                  for (var d1 = 0; d1 < dolar1L; d1++)
                  dolar1N.push(Number(dolar1[d1]))
                  const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(nome)&& val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var dolar2L = dolar2.length
                  var dolar2N = [];
                  for (var d2 = 0; d2 < dolar2L; d2++)
                  dolar2N.push(Number(dolar2[d2]))
                  const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
                  const dolarTotal = (clienteDolar1+clientedolar2)
                  return(dolarTotal)
              }
  
              var reais = (nome)=>{
                  const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var Reais1L = Reais1.length
                  var Reais1N = [];
                  for (var d1 = 0; d1 < Reais1L; d1++)
                  Reais1N.push(Number(Reais1[d1]))
                  const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var Reais2L = Reais2.length
                  var Reais2N = [];
                  for (var d2 = 0; d2 < Reais2L; d2++)
                  Reais2N.push(Number(Reais2[d2]))
                  const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
                  const ReaisTotal = (clienteReais1+clienteReais2)
                  return(ReaisTotal)
              }
              
              var clientes1 = clientesemDuplicados.map((item, index)=>(
                  {
                    "id": index,
                    "name":item,
                    "quantidade":juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length,
                      "dolares":dolar(item),
  
                    "reais":reais(item)
                }
              ))
  
              var clientes2 = clientes1.sort((a,b)=>{
                  if (a.dolares < b.dolares) {
                    return 1;
                  }
                  if (a.dolares > b.dolares) {
                    return -1;
                  }
                  return 0;
                })
  
              var clientes3 = clientes2.slice(0,15)
              
            
            
  
              setProjetData({
                  labels: clientes3.map((i)=>i.name),
                  datasets:[
                   {
                       label:`Reais - ${ano1}`,
                       data:clientes3.map((item)=>item.reais),
                       fill: true,
                       borderColor: '#FF9900',
                      backgroundColor:'#FF9900',
                     },
  
                     {
                      label:`Dólares - ${ano1}`,
                      data: clientes3.map((item)=>item.dolares),
                      borderColor: '#315F30',
                      backgroundColor:'#315F30',
                    },
  
                    {
                      label:`Projetos - ${ano1}`,
                      data: clientes3.map((item)=>item.quantidade),
                      fill: true,
                      borderColor: '#FF5400',
                      backgroundColor: '#11D5EB',
                    },
                  
                  
           
                 ]
                })
  
              setGrafico(true)
    }
  
    const handleChart2 =()=>{
      setGrafico(false)
              let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projJurisdictions)
              let clientesAndamento2 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2)){return val}}).map((i)=>i.data.projJurisdictions)
              let todosClientes = clientesAndamento.concat(clientesAndamento2)
              let juriscicoesNomes = todosClientes.reduce((list, sub) => list.concat(sub), [])
            let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
            
            
              var dolar = (nome)=>{
                  const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome)  && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var dolar1L = dolar1.length
                  var dolar1N = [];
                  for (var d1 = 0; d1 < dolar1L; d1++)
                  dolar1N.push(Number(dolar1[d1]))
                  const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var dolar2L = dolar2.length
                  var dolar2N = [];
                  for (var d2 = 0; d2 < dolar2L; d2++)
                  dolar2N.push(Number(dolar2[d2]))
                  const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
                  const dolarTotal = (clienteDolar1+clientedolar2)
                  return(dolarTotal)
              }
  
              var reais = (nome)=>{
                  const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var Reais1L = Reais1.length
                  var Reais1N = [];
                  for (var d1 = 0; d1 < Reais1L; d1++)
                  Reais1N.push(Number(Reais1[d1]))
                  const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var Reais2L = Reais2.length
                  var Reais2N = [];
                  for (var d2 = 0; d2 < Reais2L; d2++)
                  Reais2N.push(Number(Reais2[d2]))
                  const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
                  const ReaisTotal = (clienteReais1+clienteReais2)
                  return(ReaisTotal)
              }
              var dolar2 = (nome)=>{
                const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolar1L = dolar1.length
                var dolar1N = [];
                for (var d1 = 0; d1 < dolar1L; d1++)
                dolar1N.push(Number(dolar1[d1]))
                const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var dolar2L = dolar2.length
                var dolar2N = [];
                for (var d2 = 0; d2 < dolar2L; d2++)
                dolar2N.push(Number(dolar2[d2]))
                const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
                const dolarTotal = (clienteDolar1+clientedolar2)
                return(dolarTotal)
            }
  
            var reais2 = (nome)=>{
                const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var Reais1L = Reais1.length
                var Reais1N = [];
                for (var d1 = 0; d1 < Reais1L; d1++)
                Reais1N.push(Number(Reais1[d1]))
                const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var Reais2L = Reais2.length
                var Reais2N = [];
                for (var d2 = 0; d2 < Reais2L; d2++)
                Reais2N.push(Number(Reais2[d2]))
                const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
                const ReaisTotal = (clienteReais1+clienteReais2)
                return(ReaisTotal)
            }
  
            
              
              var clientes1 = clientesemDuplicados.map((item, index)=>(
                  {
                    "id": index,
                    "name":item,
                    "quantidade":juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length,
                    "dolares":dolar(item),
                    "reais":reais(item),
                    "dolares2":dolar2(item),
                    "reais2":reais2(item)
                }
              ))
  
              var clientes2 = clientes1.sort((a,b)=>{
                  if ((a.dolares+a.dolares2) < (b.dolares+b.dolares2)) {
                    return 1;
                  }
                  if ((a.dolares+a.dolares2) > (b.dolares+b.dolares2)) {
                    return -1;
                  }
                  return 0;
                })
  
              var clientes3 = clientes2.slice(0,15)
              
            
            
  
              setProjetData({
                  labels: clientes3.map((i)=>i.name),
                  datasets:[
                   {
                       label:`Reais - ${ano1}`,
                       data:clientes3.map((item)=>item.reais),
                       fill: true,
                       borderColor: '#FF9900',
                      backgroundColor:'#FF9900',
                     },
                     {
                      label:`Reais - ${ano2}`,
                      data:clientes3.map((item)=>item.reais2),
                      fill: true,
                      borderColor: '#FF876C',
                     backgroundColor:'#FF876C',
                    },
  
  
                     {
                      label:`Dólar - ${ano1}`,
                      data: clientes3.map((item)=>item.dolares),
                      borderColor: '#315F30',
                      backgroundColor:'#315F30',
                    },
                 
                    {
                     label:`Dólar - ${ano2}`,
                     data: clientes3.map((item)=>item.dolares2),
                     borderColor: '#9EAD42',
                     backgroundColor:'#9EAD42',
                   },
  
                    {
                      label:`Projetos - ${ano1} ${ano2}`,
                      data: clientes3.map((item)=>item.quantidade),
                      fill: true,
                      borderColor: '#FF5400',
                      backgroundColor: '#11D5EB',
                    },
                  
                  
           
                 ]
                })
  
              setGrafico(true)
    }
  
    const handleChart3 =()=>{
      setGrafico(false)
              let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1)){return val}}).map((i)=>i.data.projJurisdictions)
              let clientesAndamento2 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2)){return val}}).map((i)=>i.data.projJurisdictions)
              let clientesAndamento3 =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano3)){return val}}).map((i)=>i.data.projJurisdictions)
              let todosClientes = clientesAndamento.concat(clientesAndamento2, clientesAndamento3)
              let juriscicoesNomes = todosClientes.reduce((list, sub) => list.concat(sub), [])
              let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });
            
              var dolar = (nome)=>{
                  const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var dolar1L = dolar1.length
                  var dolar1N = [];
                  for (var d1 = 0; d1 < dolar1L; d1++)
                  dolar1N.push(Number(dolar1[d1]))
                  const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var dolar2L = dolar2.length
                  var dolar2N = [];
                  for (var d2 = 0; d2 < dolar2L; d2++)
                  dolar2N.push(Number(dolar2[d2]))
                  const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
                  const dolarTotal = (clienteDolar1+clientedolar2)
                  return(dolarTotal)
              }
  
              var reais = (nome)=>{
                  const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                  var Reais1L = Reais1.length
                  var Reais1N = [];
                  for (var d1 = 0; d1 < Reais1L; d1++)
                  Reais1N.push(Number(Reais1[d1]))
                  const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                  
                  const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano1) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                  var Reais2L = Reais2.length
                  var Reais2N = [];
                  for (var d2 = 0; d2 < Reais2L; d2++)
                  Reais2N.push(Number(Reais2[d2]))
                  const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
                  const ReaisTotal = (clienteReais1+clienteReais2)
                  return(ReaisTotal)
              }
              var dolar2 = (nome)=>{
                const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolar1L = dolar1.length
                var dolar1N = [];
                for (var d1 = 0; d1 < dolar1L; d1++)
                dolar1N.push(Number(dolar1[d1]))
                const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var dolar2L = dolar2.length
                var dolar2N = [];
                for (var d2 = 0; d2 < dolar2L; d2++)
                dolar2N.push(Number(dolar2[d2]))
                const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
                const dolarTotal = (clienteDolar1+clientedolar2)
                return(dolarTotal)
            }
  
            var reais2 = (nome)=>{
                const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var Reais1L = Reais1.length
                var Reais1N = [];
                for (var d1 = 0; d1 < Reais1L; d1++)
                Reais1N.push(Number(Reais1[d1]))
                const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano2) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var Reais2L = Reais2.length
                var Reais2N = [];
                for (var d2 = 0; d2 < Reais2L; d2++)
                Reais2N.push(Number(Reais2[d2]))
                const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
                const ReaisTotal = (clienteReais1+clienteReais2)
                return(ReaisTotal)
            }
            var dolar3 = (nome)=>{
              const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano3) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
              var dolar1L = dolar1.length
              var dolar1N = [];
              for (var d1 = 0; d1 < dolar1L; d1++)
              dolar1N.push(Number(dolar1[d1]))
              const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
              
              const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano3) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
              var dolar2L = dolar2.length
              var dolar2N = [];
              for (var d2 = 0; d2 < dolar2L; d2++)
              dolar2N.push(Number(dolar2[d2]))
              const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)
  
              const dolarTotal = (clienteDolar1+clientedolar2)
              return(dolarTotal)
          }
  
          var reais3 = (nome)=>{
              const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano3) && val.data.projJurisdictions.includes(nome) && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
              var Reais1L = Reais1.length
              var Reais1N = [];
              for (var d1 = 0; d1 < Reais1L; d1++)
              Reais1N.push(Number(Reais1[d1]))
              const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
              
              const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'  && val.data.projStartDate.includes(ano3) && val.data.projJurisdictions.includes(nome) && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
              var Reais2L = Reais2.length
              var Reais2N = [];
              for (var d2 = 0; d2 < Reais2L; d2++)
              Reais2N.push(Number(Reais2[d2]))
              const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)
  
              const ReaisTotal = (clienteReais1+clienteReais2)
              return(ReaisTotal)
          }
            
              
              var clientes1 = clientesemDuplicados.map((item, index)=>(
                  {
                    "id": index,
                    "name":item,
                    "quantidade":juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length,
                    "dolares":dolar(item),
                    "reais":reais(item),
                    "dolares2":dolar2(item),
                    "reais2":reais2(item),
                    "dolares3":dolar3(item),
                    "reais3":reais3(item)
                }
              ))
  
              var clientes2 = clientes1.sort((a,b)=>{
                  if ((a.dolares+a.dolares2+a.dolares3) < (b.dolares+b.dolares2+b.dolares3)) {
                    return 1;
                  }
                  if ((a.dolares+a.dolares2+a.dolares3) > (b.dolares+b.dolares2+b.dolares3)) {
                    return -1;
                  }
                  return 0;
                })
  
              var clientes3 = clientes2.slice(0,15)
              
            
            
  
              setProjetData({
                  labels: clientes3.map((i)=>i.name),
                  datasets:[
                   {
                       label:`Reais - ${ano1}`,
                       data:clientes3.map((item)=>item.reais),
                       fill: true,
                       borderColor: '#FF9900',
                      backgroundColor:'#FF9900',
                     },
                     {
                      label:`Reais - ${ano2}`,
                      data:clientes3.map((item)=>item.reais2),
                      fill: true,
                      borderColor: '#FF876C',
                     backgroundColor:'#FF876C',
                    },
                    {
                      label:`Reais - ${ano3}`,
                      data:clientes3.map((item)=>item.reais3),
                      fill: true,
                      borderColor: '#FCD08F',
                     backgroundColor:'#FCD08F',
                    },
  
  
  
                     {
                      label:`Dólar - ${ano1}`,
                      data: clientes3.map((item)=>item.dolares),
                      borderColor: '#315F30',
                      backgroundColor:'#315F30',
                    },
                 
                    {
                     label:`Dólar - ${ano2}`,
                     data: clientes3.map((item)=>item.dolares2),
                     borderColor: '#9EAD42',
                     backgroundColor:'#9EAD42',
                   },
                   {
                    label:`Dólar - ${ano3}`,
                    data: clientes3.map((item)=>item.dolares3),
                    borderColor: '#B2B890',
                    backgroundColor:'#B2B890',
                  },
  
                    {
                      label:`Projetos - ${ano1} ${ano2} ${ano3}`,
                      data: clientes3.map((item)=>item.quantidade),
                      fill: true,
                      borderColor: '#FF5400',
                      backgroundColor: '#11D5EB',
                    },
                  
                  
           
                 ]
                })
  
              setGrafico(true)
    }
    return (
      <div>
      <Header />
     {loading &&
        <div className="pageTitle">
        <div>Carregando...</div>
      </div>
     }
     {!loading &&
       <>
       <div className="pageTitle">
         <div>Maiores Jurisdições Ano -  Faturamento</div>
         <div className='subtitle'>15 Maiores (soma de faturamento em dólares)</div>
         <div>
              <p>
                  Tipo:
                  <select defaultValue={'Ano'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setAno1(''); setAno2(''); setAno3('');}}>
                  <option value="">Selecione</option>
                  <option value="Ano">Ano</option>
                  <option value="Ano2">Dois Anos</option>
                  <option value="Ano3">Três Anos</option>
              </select>
              </p>
          </div>
         
          {tipo === 'Ano' &&
              <div className='tipo'>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select defaultValue={'2024'} onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  {ano1 !== '' &&
                      <div><button onClick={handleChart}>Gerar Gráfico</button></div>
                  }
          </div>
          }
          {tipo === 'Ano2' &&
              <div className='tipo'>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select onChange={(e)=>{setAno2(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  {ano1 !== '' && ano2 !== '' &&
                      <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
                  }
          </div>
          }
           {tipo === 'Ano3' &&
              <div className='tipo'>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select onChange={(e)=>{setAno1(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select onChange={(e)=>{setAno2(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  <div className='selecionar'>
                      <p>Selecione um ano:</p>
                      <select onChange={(e)=>{setAno3(e.target.value); setGrafico(false)}}>
                          <option value="">Selecione</option>
                          {anos.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
  
                          ))}
                          
                      </select>
                  </div>
                  {ano1 !== '' && ano2 !== '' && ano3 !== '' &&
                      <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
                  }
          </div>
          }
           
       </div>
       {grafico &&
         <div className="pageContent">
         <div className="pageChart">
           <BarChart chartData={projectData} />
         </div>
       </div>
       }
       
       </>     
     }
  </div>
    )
}

export default MaioresJurisciçõesFatAno