import React from 'react'
import {Pie} from 'react-chartjs-2'
import {Chart as Chartjs} from 'chart.js/auto'

function BarChart({chartData}) {
  return (
    <Pie data={chartData} height={'30%'}/>
  )
}

export default BarChart