import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { RouteList } from './routes/Routes';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <RouteList />
    </BrowserRouter>

    </div>
  );
}

export default App;
