import React,{useState} from 'react'
import {useNavigate } from 'react-router-dom'
import {custosCollectionRef} from '../../services/fireref';
import { addDoc} from "firebase/firestore"
import Header from '../../components/Header/Header'
import '../pages.css'

function CustoNovo() {
  const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const [ano, setAno]=useState('');
  const [pessoalReais, setPessoalReais]=useState(0)
  const [impostosReais, setImpostosReais]=useState(0)
  const [fornecedoresReais, setFornecdoresReais]=useState(0)
  const [date, setDate]=useState('')

  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const HandleCustos =async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( custosCollectionRef, {
      ano,
      pessoalReais,
      impostosReais,
      fornecedoresReais,
      date
      }
    );


    
    setUploading(false);
    navigate('/custos')
  }

  return (
    <div>
         <Header />
         

          <div className="pageTitle">
            <div>Adicionar Custos</div>
          </div>
          <div className="pageContent collum">
            <div className="custoUp">
              <form className='custoForm' onSubmit={HandleCustos}>
                <div className='custoFormLine'>
                  <div className='custoFormLine-text'>Ano:</div> 
                  <input type="text" onChange={(e)=>{setAno(e.target.value)}} required />
                </div>
                <div className='custoFormLine'>
                  <div className='custoFormLine-text'>Custo Pessoal Reais:</div> 
                  <input type="text" onChange={(e)=>{setPessoalReais(Number(e.target.value))}} required/>
                </div>
                <div className='custoFormLine'>
                  <div className='custoFormLine-text'>Custo Fornecedores Reais:</div> 
                  <input type="text" onChange={(e)=>{setFornecdoresReais(Number(e.target.value))}} required/>
                </div>
                <div className='custoFormLine'>
                  <div className='custoFormLine-text'>Impostos Reais:</div> 
                  <input type="text" onChange={(e)=>{setImpostosReais(Number(e.target.value))}} required />
                </div>
                <div className='custoFormLine'>
                  <div className='custoFormLine-text'>Valores Atulaizados Até</div> 
                    <select style={{width:50+"%"}} onChange={(e)=>{setDate(e.target.value)}} required>
                          {meses.map((item, index)=>(
                              <option value={item} key={index}>{item}</option>
                          ))}
                    </select>
                </div>
                <div className='custoFormLine'>
                  <input type="submit" value="Adicionar Custos" />
                </div>
                  
              </form>
            </div>
          </div>

       
    </div>
  )
}

export default CustoNovo