
import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'
import BarChart from '../../components/Charts/BarChart';

function MaioresPaisesFatHistorico() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    
    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
       
        return()=>{change()}
    
     },[]);

     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))
          setLoading(false)
        },[projetos])
        const handleChart = ()=>{
            setGrafico(false)
            let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
            let clientesemDuplicados = clientesAndamento.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
            var dolar = (nome)=>{
                const dolar1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry === nome && val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var dolar1L = dolar1.length
                var dolar1N = [];
                for (var d1 = 0; d1 < dolar1L; d1++)
                dolar1N.push(Number(dolar1[d1]))
                const clienteDolar1 = dolar1N.reduce((acc, curr) => acc + curr, 0)
                
                const dolar2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry === nome && val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var dolar2L = dolar2.length
                var dolar2N = [];
                for (var d2 = 0; d2 < dolar2L; d2++)
                dolar2N.push(Number(dolar2[d2]))
                const clientedolar2 = dolar2N.reduce((acc, curr) => acc + curr, 0)

                const dolarTotal = (clienteDolar1+clientedolar2)
                return(dolarTotal)
            }

            var reais = (nome)=>{
                const Reais1 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry === nome && val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
                var Reais1L = Reais1.length
                var Reais1N = [];
                for (var d1 = 0; d1 < Reais1L; d1++)
                Reais1N.push(Number(Reais1[d1]))
                const clienteReais1 = Reais1N.reduce((acc, curr) => acc + curr, 0)
                
                const Reais2 = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry === nome && val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
                var Reais2L = Reais2.length
                var Reais2N = [];
                for (var d2 = 0; d2 < Reais2L; d2++)
                Reais2N.push(Number(Reais2[d2]))
                const clienteReais2 = Reais2N.reduce((acc, curr) => acc + curr, 0)

                const ReaisTotal = (clienteReais1+clienteReais2)
                return(ReaisTotal)
            }
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade":clientesAndamento.filter((val)=>{if(val === item){return val}}).length,
                    "dolares":dolar(item),

                  "reais":reais(item)
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.dolares < b.dolares) {
                  return 1;
                }
                if (a.dolares > b.dolares) {
                  return -1;
                }
                return 0;
              })

            var clientes3 = clientes2.slice(0,10)
            
          
          

            setProjetData({
                labels: clientes3.map((i)=>i.name),
                datasets:[
                 {
                     label:'Faturamaneto Reais',
                     data:clientes3.map((item)=>item.reais),
                     fill: true,
                     borderColor: '#FF9900',
                    backgroundColor:'#FF9900',
                   },

                   {
                    label:'Faturamento Dólar',
                    data: clientes3.map((item)=>item.dolares),
                    borderColor: '#315F30',
                    backgroundColor:'#315F30',
                  },

                  {
                    label:'Quantidade de Projetos',
                    data: clientes3.map((item)=>item.quantidade),
                    fill: true,
                    borderColor: '#FF5400',
                    backgroundColor: '#11D5EB',
                  },
                
                
         
               ]
              })

            setGrafico(true)
        }
  return (
    <div>
         <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Maiores Países Histórico - Faturamento</div>
       <div className='subtitle'>Maiores 10</div>
       <div><button onClick={handleChart}>Gerar Gráfico</button></div>
     </div>
     {grafico &&
       <div className="pageContent">
       <div className="pageChart">
         <BarChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
    </div>
  )
}

export default MaioresPaisesFatHistorico