import React from 'react'
import {UserAuth} from '../../services/AuthContext'
import {Link, useNavigate} from 'react-router-dom'
import './Header.css'
import Logo from '../../media/logo_preta.webp'

function Header() {
    const navigate = useNavigate();
    const{logout} = UserAuth();
    const handleLogout = async()=>{
        try{
            await logout();
            navigate('/login')
        }catch(e){
            console.log(e.message)
        }
    }

  return (
    <div className="nav">
      <div className="nav-container">
        <div className="nav-container-logo">
          <img src={Logo} alt="logo" width={'100%'} height={'auto'} />
        </div>
          <div className="navbar">
              
              <div className="dropdown">
              <button className="dropbtn">Visão Geral</button>
                <div className="dropdown-content">
                  <Link to='/'>Atual</Link>
                  <Link to='/historico'>Histórico</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn">Projetos</button>
                <div className="dropdown-content">
                  <Link to='/projetoquatroanosdataatual'>Qnt. Fat. 5 Últimos Anos Data Atual</Link>
                  <Link to='/projetoquatroanosmesatual'>Qnt. Fat. 5 Últimos Anos Mês Atual</Link>
                  <Link to='/projetoquatroanos'>Qnt. Fat. 5 Últimos Anos Completo</Link>
                  <Link to='/projetohistorico'>Quantitativo Histórico</Link>
                  <Link to='/projetofaturamentohistorico'>Faturamento Histórico</Link>
                  <Link to='/qtdxfathistorico'>Qnt. x Fat. Histórico</Link>
                  <Link to='/quantidadeperiodo'>Quantitativo Período</Link>
                  <Link to='/faturamentoperiodo'>Faturamento Período</Link>
                  <Link to='/qtdxfatperiodo'>Qnt. x Fat. Período</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn">Clientes</button>
                <div className="dropdown-content">
                <Link to='/clientesuseismeses'>Clientes Últimos 6 meses</Link>
                <Link to='/clientesquatroanosdataatual'>Qnt. Fat. 5 Últimos Anos Data Atual</Link>
                <Link to='/clientesquatroanos'>Qnt. Fat. 5 Últimos Anos Completo</Link>
                <Link to='/maioreclientes'>Maiores Clientes Quantitativo Hist.</Link>
                <Link to='/maioreclientesfat'>Maiores Clientes Faturamento Hist.</Link>
                <Link to='/maioreclientesano'>Maiores Clientes Quantitativo Ano</Link>
                <Link to='/clientesanofaturamento'>Maiores Clientes Faturamento Ano</Link>
                <Link to='/clientesporjurisdicao'>Clientes Por Jurisdição</Link>
                <Link to='/clienteshistoricoquantidade'>Cliente(s) Quantitativo Histórico</Link>
                <Link to='/clientesfaturamentohistorico'>Cliente(s) Faturamento Histórico</Link>
                <Link to='/clientesanoquantidade'>Cliente(s) Quantitativo em Ano</Link>
                <Link to='/clientesfaturamentoano'>Cliente(s) Faturamento em Ano</Link>
                <Link to='/clientesperiodoquantidade'>Cliente(s) Quantitativo em Período</Link>
                <Link to='/clientesfaturamentoperiodo'>Cliente(s) Faturamento em Período</Link>  
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn">Países Contratação</button>
                <div className="dropdown-content">
                <Link to='/paisesseismeses'>Paises Últimos 6 meses</Link>
                <Link to='/maiorepaises'>Maiores Países Quantitativo Hist.</Link>
                <Link to='/maiorepaisesfat'>Maiores Países Faturamento Hist.</Link>
                <Link to='/maiorepaisesano'>Maiores Países Quantitativo Ano</Link>
                <Link to='/maiorepaisesfaturamentoano'>Maiores Países Faturamento Ano</Link>
                <Link to='/paisesporjurisdicao'>Países Por Jurisdição</Link>
                <Link to='/paiseshistoricoquantidade'>País(es) Quantitativo Histórico</Link>
                <Link to='/paisesfaturamentohistorico'>País(es) Faturamento Histórico</Link>
                <Link to='/paisesanoquantidade'>País(es) Quantitativo em Ano</Link>
                <Link to='/paisesfaturamentoano'>País(es) Faturamento em Ano</Link>
                <Link to='/paisesperiodoquantidade'>País(es) Quantitativo em Período</Link>
                <Link to='/paisesperiodofaturamento'>País(es) Faturamento em Período</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn">Jurisdições</button>
                <div className="dropdown-content">
                <Link to='/jurisdicoesseismeses'>Jurisdições Últimos 6 meses</Link>
                <Link to='/jurisdicoesporpais'>Jurisdições Por País</Link>
                <Link to='/jurisdicoesporcliente'>Jurisdições Por Cliente</Link>
                <Link to='/maioresjurisdicoes'>Maiores Jurisdições Quantitativo Hist.</Link>
                <Link to='/maioresjurisdicoesfat'>Maiores Jurisdições Faturamento Hist.</Link>
                <Link to='/maioresjurisdicoesano'>Maiores Jurisdições Quantitativo Ano</Link>
                <Link to='/maiorejurisdicoesfaturamentoano'>Maiores Jurisdições Faturamento Ano</Link>
                <Link to='/jurisdicoeshistoricoquantidade'>Jurisdições Quantitativo Histórico</Link>
                <Link to='/jurisdicoesfaturamentohistorico'>Jurisdições Faturamento Histórico</Link>
                <Link to='/jurisdicoesanoquantidade'>Jurisdições Quantitativo em Ano</Link>
                <Link to='/jurisdicoesanofaturamento'>Jurisdições Faturamento em Ano</Link>
                <Link to='/jurisdicoesperiodoquantidade'>Jurisdições Quantitativo em Período</Link>
                <Link to='/jurisdicoesperiodofaturamento'>Jurisdições Faturamento em Período</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn">Custos</button>
                <div className="dropdown-content">
                  <Link to='/custos'>Editar Custos</Link>
                  <Link to='/novocusto'>Novo Custo</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn" onClick={handleLogout}>Logout</button>
              </div>
              
          </div>
      </div>
  </div>
  )
}

export default Header