import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'
import PieChart from '../../components/Charts/PieChart';


function JurisdicoesPorPais() {
    const [projetos, setProjetos]=useState([]);
    const [paises, setPaises]=useState([]);
    const [pais00, setPais00]=useState('')
    const [paislist, setPaisList]=useState(false)
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    
    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
       
        return()=>{change()}
    
     },[]);

     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))
          let paisesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
            
        let paisesSemDuplicados = paisesFiltrados.filter((v, i, s) => {
          return s.indexOf(v) === i;
        });
      
        let paisesOrdenados = paisesSemDuplicados.sort(function (a, b) {
            return a.localeCompare(b);
          });
          setPaises(paisesOrdenados)
        setLoading(false)
        },[projetos])

      const lista =()=>{
        setTodos(projetos.concat(ProjetosHistorico))
        let paisesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projHiringCountry)
          
      let paisesSemDuplicados = paisesFiltrados.filter((v, i, s) => {
        return s.indexOf(v) === i;
      });
    
      let paisesOrdenados = paisesSemDuplicados.sort(function (a, b) {
          return a.localeCompare(b);
        });
        setPaises(paisesOrdenados)
        setPaisList(true)
      }

        const handleChart = ()=>{
            setGrafico(false)
            let todosPaises =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projHiringCountry.includes(pais00)){return val}}).map((i)=>i.data.projJurisdictions)
            let juriscicoesNomes = todosPaises.reduce((list, sub) => list.concat(sub), [])
            let paisesSemDuplicado = juriscicoesNomes.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
               
            
            var paises1 = paisesSemDuplicado.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade": juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length
          
              }
            ))

            var paises2 = paises1.sort((a,b)=>{
                if (a.quantidade < b.quantidade) {
                  return 1;
                }
                if (a.quantidade > b.quantidade) {
                  return -1;
                }
                return 0;
              })

            
            
          
          

            setProjetData({
                labels: paises2.map((i)=>i.name),
                datasets:[
                 {
                     label:'Quantidade de Projetos',
                     data: paises2.map((item)=>item.quantidade),
                     borderWidth: 0
                   },      
               ]
               
              })

            setGrafico(true)
        }
   return (
    <div>
    <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Jurisdições Por País</div>

       <div className='tipo'>
           
       {
            !paislist &&
            <div><button onClick={lista}>Gerar Lista</button></div>
           }
           
              {paislist  &&
              <div className='selecionar'>
                  <p>Selecione um Pais:</p>
                  <select onChange={(e)=>{setPais00(e.target.value); setGrafico(false)}}>
                      <option value="">Selecione</option>
                      {paises.map((item, index)=>(
                          <option value={item} key={index}>{item}</option>
  
                      ))}
                      
                  </select>
              </div>
              }
              {pais00 !== '' &&
                  <div><button onClick={handleChart}>Gerar Gráfico</button></div>
              }
              </div>

     </div>
     {grafico &&
       <div className="pageContent">
       <div className="pageChart" style={{width:'70%'}}>
         <PieChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
</div>
  )
}

export default JurisdicoesPorPais