import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function JurisdiçõesFaturamentoPeriodo() {
    const [projetos, setProjetos]=useState([]);
  const [clientes, setClientes]=useState([])
  const [loading, setLoading]=useState(true);
  const [projectData, setProjetData]=useState({})
  const [todos , setTodos]=useState([])
  const [grafico, setGrafico]=useState(false)

  const [tipo, setTipo]=useState('um')

 
  const [cliente1, setCliente1]=useState('')
  const [cliente2, setCliente2]=useState('')
  const [cliente3, setCliente3]=useState('')

  const [de1, setDe1]=useState('')
    const [ate1, setAte1]=useState('')
    const [de2, setDe2]=useState('')
    const [ate2, setAte2]=useState('')
    const [de3, setDe3]=useState('')
    const [ate3, setAte3]=useState('')
    const [paislist, setPaisList]=useState(false)

 
  useEffect(()=>{
    const q = query(projectsCollectionRef);
    const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
    setLoading(true)
    const change = onSnapshot(q, snapshot=>{
        setProjetos(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })
    /*const change2 = onSnapshot(r, snapshot=>{
      setClientes(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
    setLoading(false)
  })*/
    return()=>{change();/* change2()*/}

 },[]);

useLayoutEffect(()=>{
setLoading(true)
  setTodos(projetos.concat(ProjetosHistorico))

  let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
    let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
  let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
    return s.indexOf(v) === i;
  });

  let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
    return a.localeCompare(b);
  });
setClientes(paisesOrdenados)
  setLoading(false)
},[projetos])

const lista = ()=>{
  setTodos(projetos.concat(ProjetosHistorico))

  let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
    let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
  let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
    return s.indexOf(v) === i;
  });

  let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
    return a.localeCompare(b);
  });
setClientes(paisesOrdenados)
setPaisList(true)
}

const handleChart =()=>{

  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
 
  

  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})


     //Um reais
     
        const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20021RLenght = projs20021R.length
        var projs20021RNumeros = [];
        for (var r1 = 0; r1 < projs20021RLenght; r1++)
        projs20021RNumeros.push(Number(projs20021R[r1]))
        const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20022RLenght = projs20022R.length
        var projs20022RNumeros = [];
        for (var r2 = 0; r2 < projs20022RLenght; r2++)
        projs20022RNumeros.push(Number(projs20022R[r2]))
        const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002 = (projs2002Reais1+projs2002Reais2)
    
        //Um dolar
        const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20021DLenght = projs20021D.length
        var projs20021DNumeros = [];
        for (var d1 = 0; d1 < projs20021DLenght; d1++)
        projs20021DNumeros.push(Number(projs20021D[d1]))
        const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20022DLenght = projs20022D.length
        var projs20022DNumeros = [];
        for (var d2 = 0; d2 < projs20022DLenght; d2++)
        projs20022DNumeros.push(Number(projs20022D[d2]))
        const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

          //dois reais
    
        const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20031RLenght = projs20031R.length
        var projs20031RNumeros = [];
        for (var r1 = 0; r1 < projs20031RLenght; r1++)
        projs20031RNumeros.push(Number(projs20031R[r1]))
        const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20032RLenght = projs20032R.length
        var projs20032RNumeros = [];
        for (var r2 = 0; r2 < projs20032RLenght; r2++)
        projs20032RNumeros.push(Number(projs20032R[r2]))
        const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003 = (projs2003Reais1+projs2003Reais2)
        
        //dois dolar
        const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20031DLenght = projs20031D.length
        var projs20031DNumeros = [];
        for (var d1 = 0; d1 < projs20031DLenght; d1++)
        projs20031DNumeros.push(Number(projs20031D[d1]))
        const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20032DLenght = projs20032D.length
        var projs20032DNumeros = [];
        for (var d2 = 0; d2 < projs20032DLenght; d2++)
        projs20032DNumeros.push(Number(projs20032D[d2]))
        const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

  setProjetData({
    labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
    datasets:[
     {
      label:`Faturamento Reais - ${cliente1}`,
      data: [Reais2002, Reais2003],
      fill: true,
      borderColor: '#FF9900',
      backgroundColor: '#FF9900',
    },
    {
        label:`Faturamento Dolar - ${cliente1}`,
        data: [Dolar2002, Dolar2003],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: '#42ADA7',
      },
   ],
   
   
  })

  setGrafico(true)
}

const handleChart2 =()=>{
  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno3 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
 
  const projsAno4 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

     //Um reais
     
     const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20021RLenght = projs20021R.length
        var projs20021RNumeros = [];
        for (var r1 = 0; r1 < projs20021RLenght; r1++)
        projs20021RNumeros.push(Number(projs20021R[r1]))
        const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20022RLenght = projs20022R.length
        var projs20022RNumeros = [];
        for (var r2 = 0; r2 < projs20022RLenght; r2++)
        projs20022RNumeros.push(Number(projs20022R[r2]))
        const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002 = (projs2002Reais1+projs2002Reais2)
    
        //Um dolar
        const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20021DLenght = projs20021D.length
        var projs20021DNumeros = [];
        for (var d1 = 0; d1 < projs20021DLenght; d1++)
        projs20021DNumeros.push(Number(projs20021D[d1]))
        const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20022DLenght = projs20022D.length
        var projs20022DNumeros = [];
        for (var d2 = 0; d2 < projs20022DLenght; d2++)
        projs20022DNumeros.push(Number(projs20022D[d2]))
        const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

          //dois reais
    
        const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20031RLenght = projs20031R.length
        var projs20031RNumeros = [];
        for (var r1 = 0; r1 < projs20031RLenght; r1++)
        projs20031RNumeros.push(Number(projs20031R[r1]))
        const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20032RLenght = projs20032R.length
        var projs20032RNumeros = [];
        for (var r2 = 0; r2 < projs20032RLenght; r2++)
        projs20032RNumeros.push(Number(projs20032R[r2]))
        const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003 = (projs2003Reais1+projs2003Reais2)
        
        //dois dolar
        const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20031DLenght = projs20031D.length
        var projs20031DNumeros = [];
        for (var d1 = 0; d1 < projs20031DLenght; d1++)
        projs20031DNumeros.push(Number(projs20031D[d1]))
        const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20032DLenght = projs20032D.length
        var projs20032DNumeros = [];
        for (var d2 = 0; d2 < projs20032DLenght; d2++)
        projs20032DNumeros.push(Number(projs20032D[d2]))
        const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

        //tre reais
    
        const projs20041R = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20041RLenght = projs20041R.length
        var projs20041RNumeros = [];
        for (var r1 = 0; r1 < projs20041RLenght; r1++)
        projs20041RNumeros.push(Number(projs20041R[r1]))
        const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20042R = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20042RLenght = projs20042R.length
        var projs20042RNumeros = [];
        for (var r2 = 0; r2 < projs20042RLenght; r2++)
        projs20042RNumeros.push(Number(projs20042R[r2]))
        const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004 = (projs2004Reais1+projs2004Reais2)
        
        //tres dolar
        const projs20041D = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20041DLenght = projs20041D.length
        var projs20041DNumeros = [];
        for (var d1 = 0; d1 < projs20041DLenght; d1++)
        projs20041DNumeros.push(Number(projs20041D[d1]))
        const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20042D = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20042DLenght = projs20042D.length
        var projs20042DNumeros = [];
        for (var d2 = 0; d2 < projs20042DLenght; d2++)
        projs20042DNumeros.push(Number(projs20042D[d2]))
        const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)
    
        //quatro reais
        
        const projs20051R = projsAno4.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20051RLenght = projs20051R.length
        var projs20051RNumeros = [];
        for (var r1 = 0; r1 < projs20051RLenght; r1++)
        projs20051RNumeros.push(Number(projs20051R[r1]))
        const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20052R = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20052RLenght = projs20052R.length
        var projs20052RNumeros = [];
        for (var r2 = 0; r2 < projs20052RLenght; r2++)
        projs20052RNumeros.push(Number(projs20052R[r2]))
        const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005 = (projs2005Reais1+projs2005Reais2)
        
        //quatro dolar
        const projs20051D = projsAno4.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20051DLenght = projs20051D.length
        var projs20051DNumeros = [];
        for (var d1 = 0; d1 < projs20051DLenght; d1++)
        projs20051DNumeros.push(Number(projs20051D[d1]))
        const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20052D = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20052DLenght = projs20052D.length
        var projs20052DNumeros = [];
        for (var d2 = 0; d2 < projs20052DLenght; d2++)
        projs20052DNumeros.push(Number(projs20052D[d2]))
        const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)


  setProjetData({
    labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
    datasets:[
        {
         label:`Faturamento Reais - ${cliente1}`,
         data: [Reais2002, Reais2003],
         fill: true,
         borderColor: '#FF9900',
         backgroundColor: '#FF9900',
       },
          {
           label:`Faturamento Dolar - ${cliente1}`,
           data: [Dolar2002, Dolar2003],
           fill: true,
           borderColor: '#FF876C',
           backgroundColor: '#FF876C',
         },
       {
        label:`Faturamento Reais - ${cliente2}`,
        data: [Reais2004, Reais2005],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: '#42ADA7',
      },
    
         {
            label:`Faturamento Dolar - ${cliente2}`,
            data: [Dolar2004, Dolar2005],
            fill: true,
            borderColor: '#9EAD42',
            backgroundColor: '#9EAD42',
          },
      ],
   
   
  })

  setGrafico(true)
}

const handleChart3 =()=>{
  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
 
  

  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno3 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
 
  

  const projsAno4 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

  const projsAno5 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})
 
  

  const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})


 //Um reais
     
 const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //Um dolar
    const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

      //dois reais

    const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //dois dolar
    const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //tre reais

    const projs20041R = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //tres dolar
    const projs20041D = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //quatro reais
    
    const projs20051R = projsAno4.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //quatro dolar
    const projs20051D = projsAno4.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //cinco reais
  
        const projs20061R = projsAno5.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061RLenght = projs20061R.length
        var projs20061RNumeros = [];
        for (var r1 = 0; r1 < projs20061RLenght; r1++)
        projs20061RNumeros.push(Number(projs20061R[r1]))
        const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062R = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062RLenght = projs20062R.length
        var projs20062RNumeros = [];
        for (var r2 = 0; r2 < projs20062RLenght; r2++)
        projs20062RNumeros.push(Number(projs20062R[r2]))
        const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006 = (projs2006Reais1+projs2006Reais2)
        
        //cinco dolar
        const projs20061D = projsAno5.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061DLenght = projs20061D.length
        var projs20061DNumeros = [];
        for (var d1 = 0; d1 < projs20061DLenght; d1++)
        projs20061DNumeros.push(Number(projs20061D[d1]))
        const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062D = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062DLenght = projs20062D.length
        var projs20062DNumeros = [];
        for (var d2 = 0; d2 < projs20062DLenght; d2++)
        projs20062DNumeros.push(Number(projs20062D[d2]))
        const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)
    
        //Seis reais
        
        const projs20071R = projsAno6.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071RLenght = projs20071R.length
        var projs20071RNumeros = [];
        for (var r1 = 0; r1 < projs20071RLenght; r1++)
        projs20071RNumeros.push(Number(projs20071R[r1]))
        const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072R = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072RLenght = projs20072R.length
        var projs20072RNumeros = [];
        for (var r2 = 0; r2 < projs20072RLenght; r2++)
        projs20072RNumeros.push(Number(projs20072R[r2]))
        const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007 = (projs2007Reais1+projs2007Reais2)
        
        //seis dolar
        const projs20071D = projsAno6.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071DLenght = projs20071D.length
        var projs20071DNumeros = [];
        for (var d1 = 0; d1 < projs20071DLenght; d1++)
        projs20071DNumeros.push(Number(projs20071D[d1]))
        const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072D = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072DLenght = projs20072D.length
        var projs20072DNumeros = [];
        for (var d2 = 0; d2 < projs20072DLenght; d2++)
        projs20072DNumeros.push(Number(projs20072D[d2]))
        const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)


setProjetData({
labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
datasets:[
    {
     label:`Faturamento Reais - ${cliente1}`,
     data: [Reais2002, Reais2003],
     fill: true,
     borderColor: '#FF9900',
     backgroundColor: '#FF9900',
   },
      {
       label:`Faturamento Dolar - ${cliente1}`,
       data: [Dolar2002, Dolar2003],
       fill: true,
       borderColor: '#FF876C',
       backgroundColor: '#FF876C',
     },
   {
    label:`Faturamento Reais - ${cliente2}`,
    data: [Reais2004, Reais2005],
    fill: true,
    borderColor: '#42ADA7',
    backgroundColor: '#42ADA7',
  },

     {
        label:`Faturamento Dolar - ${cliente2}`,
        data: [Dolar2004, Dolar2005],
        fill: true,
        borderColor: '#9EAD42',
        backgroundColor: '#9EAD42',
      },
      {
        label:`Faturamento Reais - ${cliente3}`,
        data: [Reais2006, Reais2007],
        fill: true,
        borderColor: '#C9E4E7',
        backgroundColor: '#C9E4E7',
      },
    
         {
            label:`Faturamento Dolar - ${cliente3}`,
            data: [Dolar2006, Dolar2007],
            fill: true,
            borderColor: '#0DA1B1',
            backgroundColor: '#0DA1B1',
          },
  ],


})

  setGrafico(true)
}

const handleChart4 =()=>{

  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
 
  

  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  
 //Um reais
     
 const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //Um dolar
    const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

      //dois reais

    const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //dois dolar
    const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //tre reais

    const projs20041R = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //tres dolar
    const projs20041D = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

  setProjetData({
    labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
    datasets:[
        {
            label:`Faturamento Reais - ${cliente1}`,
            data: [Reais2002, Reais2003, Reais2004],
            fill: true,
            borderColor: '#FF9900',
            backgroundColor: '#FF9900',
          },
          {
              label:`Faturamento Dolar - ${cliente1}`,
              data: [Dolar2002, Dolar2003,Dolar2004],
              fill: true,
              borderColor: '#42ADA7',
              backgroundColor: '#42ADA7',
            },
   ],
   
   
  })

  setGrafico(true)
}

const handleChart5 =()=>{

  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
 
  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno4 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
 
  const projsAno5 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

  const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

    //Um reais
     
 const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //Um dolar
    const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

      //dois reais

    const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //dois dolar
    const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //tre reais

    const projs20041R = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //tres dolar
    const projs20041D = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //quatro reais
    
    const projs20051R = projsAno4.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //quatro dolar
    const projs20051D = projsAno4.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //cinco reais
  
        const projs20061R = projsAno5.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061RLenght = projs20061R.length
        var projs20061RNumeros = [];
        for (var r1 = 0; r1 < projs20061RLenght; r1++)
        projs20061RNumeros.push(Number(projs20061R[r1]))
        const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062R = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062RLenght = projs20062R.length
        var projs20062RNumeros = [];
        for (var r2 = 0; r2 < projs20062RLenght; r2++)
        projs20062RNumeros.push(Number(projs20062R[r2]))
        const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006 = (projs2006Reais1+projs2006Reais2)
        
        //cinco dolar
        const projs20061D = projsAno5.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061DLenght = projs20061D.length
        var projs20061DNumeros = [];
        for (var d1 = 0; d1 < projs20061DLenght; d1++)
        projs20061DNumeros.push(Number(projs20061D[d1]))
        const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062D = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062DLenght = projs20062D.length
        var projs20062DNumeros = [];
        for (var d2 = 0; d2 < projs20062DLenght; d2++)
        projs20062DNumeros.push(Number(projs20062D[d2]))
        const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)
    
        //Seis reais
        
        const projs20071R = projsAno6.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071RLenght = projs20071R.length
        var projs20071RNumeros = [];
        for (var r1 = 0; r1 < projs20071RLenght; r1++)
        projs20071RNumeros.push(Number(projs20071R[r1]))
        const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072R = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072RLenght = projs20072R.length
        var projs20072RNumeros = [];
        for (var r2 = 0; r2 < projs20072RLenght; r2++)
        projs20072RNumeros.push(Number(projs20072R[r2]))
        const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007 = (projs2007Reais1+projs2007Reais2)
        
        //seis dolar
        const projs20071D = projsAno6.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071DLenght = projs20071D.length
        var projs20071DNumeros = [];
        for (var d1 = 0; d1 < projs20071DLenght; d1++)
        projs20071DNumeros.push(Number(projs20071D[d1]))
        const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072D = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072DLenght = projs20072D.length
        var projs20072DNumeros = [];
        for (var d2 = 0; d2 < projs20072DLenght; d2++)
        projs20072DNumeros.push(Number(projs20072D[d2]))
        const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

  setProjetData({
    labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
    datasets:[
        {
            label:`Faturamento Reais - ${cliente1}`,
            data: [Reais2002, Reais2003, Reais2004],
            fill: true,
            borderColor: '#FF9900',
            backgroundColor: '#FF9900',
          },
             {
              label:`Faturamento Dolar - ${cliente1}`,
              data: [Dolar2002, Dolar2003, Dolar2004],
              fill: true,
              borderColor: '#FF876C',
              backgroundColor: '#FF876C',
            },
          {
           label:`Faturamento Reais - ${cliente2}`,
           data: [Reais2005, Reais2006, Reais2007],
           fill: true,
           borderColor: '#42ADA7',
           backgroundColor: '#42ADA7',
         },
       
            {
               label:`Faturamento Dolar - ${cliente2}`,
               data: [Dolar2005, Dolar2006, Dolar2007],
               fill: true,
               borderColor: '#9EAD42',
               backgroundColor: '#9EAD42',
             },
   ],
   
   
  })

  setGrafico(true)
}

const handleChart6 =()=>{

  setGrafico(false)
  const projsAno = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})
 
  const projsAno2 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno3 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente1)  ){return val}})

  const projsAno4 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})
 
  const projsAno5 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

  const projsAno6 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente2)  ){return val}})

  const projsAno7 = todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de1) && new Date(val.data.projStartDate) <= new Date(ate1) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})
 
  const projsAno8 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de2) && new Date(val.data.projStartDate) <= new Date(ate2) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})

  const projsAno9 =  todos.filter((val)=>{if(new Date(val.data.projStartDate) >= new Date(de3) && new Date(val.data.projStartDate) <= new Date(ate3) && val.data.projStatus1 !== 'deletado'&& val.data.projJurisdictions.includes(cliente3)  ){return val}})

     //Um reais
     
 const projs20021R = projsAno.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projsAno.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //Um dolar
    const projs20021D = projsAno.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projsAno.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

      //dois reais

    const projs20031R = projsAno2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //dois dolar
    const projs20031D = projsAno2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projsAno2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //tre reais

    const projs20041R = projsAno3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //tres dolar
    const projs20041D = projsAno3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projsAno3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //quatro reais
    
    const projs20051R = projsAno4.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //quatro dolar
    const projs20051D = projsAno4.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projsAno4.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //cinco reais
  
        const projs20061R = projsAno5.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061RLenght = projs20061R.length
        var projs20061RNumeros = [];
        for (var r1 = 0; r1 < projs20061RLenght; r1++)
        projs20061RNumeros.push(Number(projs20061R[r1]))
        const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062R = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062RLenght = projs20062R.length
        var projs20062RNumeros = [];
        for (var r2 = 0; r2 < projs20062RLenght; r2++)
        projs20062RNumeros.push(Number(projs20062R[r2]))
        const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006 = (projs2006Reais1+projs2006Reais2)
        
        //cinco dolar
        const projs20061D = projsAno5.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20061DLenght = projs20061D.length
        var projs20061DNumeros = [];
        for (var d1 = 0; d1 < projs20061DLenght; d1++)
        projs20061DNumeros.push(Number(projs20061D[d1]))
        const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20062D = projsAno5.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20062DLenght = projs20062D.length
        var projs20062DNumeros = [];
        for (var d2 = 0; d2 < projs20062DLenght; d2++)
        projs20062DNumeros.push(Number(projs20062D[d2]))
        const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)
    
        //Seis reais
        
        const projs20071R = projsAno6.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071RLenght = projs20071R.length
        var projs20071RNumeros = [];
        for (var r1 = 0; r1 < projs20071RLenght; r1++)
        projs20071RNumeros.push(Number(projs20071R[r1]))
        const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072R = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072RLenght = projs20072R.length
        var projs20072RNumeros = [];
        for (var r2 = 0; r2 < projs20072RLenght; r2++)
        projs20072RNumeros.push(Number(projs20072R[r2]))
        const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007 = (projs2007Reais1+projs2007Reais2)
        
        //seis dolar
        const projs20071D = projsAno6.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20071DLenght = projs20071D.length
        var projs20071DNumeros = [];
        for (var d1 = 0; d1 < projs20071DLenght; d1++)
        projs20071DNumeros.push(Number(projs20071D[d1]))
        const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20072D = projsAno6.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20072DLenght = projs20072D.length
        var projs20072DNumeros = [];
        for (var d2 = 0; d2 < projs20072DLenght; d2++)
        projs20072DNumeros.push(Number(projs20072D[d2]))
        const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

        //sete reais
    
        const projs20081R = projsAno7.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20081RLenght = projs20081R.length
        var projs20081RNumeros = [];
        for (var r1 = 0; r1 < projs20081RLenght; r1++)
        projs20081RNumeros.push(Number(projs20081R[r1]))
        const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20082R = projsAno7.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20082RLenght = projs20082R.length
        var projs20082RNumeros = [];
        for (var r2 = 0; r2 < projs20082RLenght; r2++)
        projs20082RNumeros.push(Number(projs20082R[r2]))
        const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008 = (projs2008Reais1+projs2008Reais2)
        
        //sete dolar
        const projs20081D = projsAno7.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20081DLenght = projs20081D.length
        var projs20081DNumeros = [];
        for (var d1 = 0; d1 < projs20081DLenght; d1++)
        projs20081DNumeros.push(Number(projs20081D[d1]))
        const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20082D = projsAno7.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20082DLenght = projs20082D.length
        var projs20082DNumeros = [];
        for (var d2 = 0; d2 < projs20082DLenght; d2++)
        projs20082DNumeros.push(Number(projs20082D[d2]))
        const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)
    
        //oito reais
        
        const projs20091R = projsAno8.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20091RLenght = projs20091R.length
        var projs20091RNumeros = [];
        for (var r1 = 0; r1 < projs20091RLenght; r1++)
        projs20091RNumeros.push(Number(projs20091R[r1]))
        const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20092R = projsAno8.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20092RLenght = projs20092R.length
        var projs20092RNumeros = [];
        for (var r2 = 0; r2 < projs20092RLenght; r2++)
        projs20092RNumeros.push(Number(projs20092R[r2]))
        const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009 = (projs2009Reais1+projs2009Reais2)
        
        //oito dolar
        const projs20091D = projsAno8.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20091DLenght = projs20091D.length
        var projs20091DNumeros = [];
        for (var d1 = 0; d1 < projs20091DLenght; d1++)
        projs20091DNumeros.push(Number(projs20091D[d1]))
        const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20092D = projsAno8.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20092DLenght = projs20092D.length
        var projs20092DNumeros = [];
        for (var d2 = 0; d2 < projs20092DLenght; d2++)
        projs20092DNumeros.push(Number(projs20092D[d2]))
        const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)
    
        //nove reais
    
        const projs20101R = projsAno9.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20101RLenght = projs20101R.length
        var projs20101RNumeros = [];
        for (var r1 = 0; r1 < projs20101RLenght; r1++)
        projs20101RNumeros.push(Number(projs20101R[r1]))
        const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20102R = projsAno9.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20102RLenght = projs20102R.length
        var projs20102RNumeros = [];
        for (var r2 = 0; r2 < projs20102RLenght; r2++)
        projs20102RNumeros.push(Number(projs20102R[r2]))
        const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010 = (projs2010Reais1+projs2010Reais2)
        
        //nove dolar
        const projs20101D = projsAno9.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs20101DLenght = projs20101D.length
        var projs20101DNumeros = [];
        for (var d1 = 0; d1 < projs20101DLenght; d1++)
        projs20101DNumeros.push(Number(projs20101D[d1]))
        const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs20102D = projsAno9.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs20102DLenght = projs20102D.length
        var projs20102DNumeros = [];
        for (var d2 = 0; d2 < projs20102DLenght; d2++)
        projs20102DNumeros.push(Number(projs20102D[d2]))
        const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

  setProjetData({
    labels: [`Período 1 - de ${new Date(de1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 2 - de ${new Date(de2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`, `Período 3 - de ${new Date(de3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} até  ${new Date(ate3).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`],
    datasets:[
        {
            label:`Faturamento Reais - ${cliente1}`,
            data: [Reais2002, Reais2003, Reais2004],
            fill: true,
            borderColor: '#FF9900',
            backgroundColor: '#FF9900',
          },
             {
              label:`Faturamento Dolar - ${cliente1}`,
              data: [Dolar2002, Dolar2003, Dolar2004],
              fill: true,
              borderColor: '#FF876C',
              backgroundColor: '#FF876C',
            },
          {
           label:`Faturamento Reais - ${cliente2}`,
           data: [Reais2005, Reais2006, Reais2007],
           fill: true,
           borderColor: '#42ADA7',
           backgroundColor: '#42ADA7',
         },
       
            {
               label:`Faturamento Dolar - ${cliente2}`,
               data: [Dolar2005, Dolar2006, Dolar2007],
               fill: true,
               borderColor: '#9EAD42',
               backgroundColor: '#9EAD42',
             },

             {
                label:`Faturamento Reais - ${cliente3}`,
                data: [Reais2008, Reais2009, Reais2010],
                fill: true,
                borderColor: '#C9E4E7',
                backgroundColor: '#C9E4E7',
              },
            
                 {
                    label:`Faturamento Dolar - ${cliente3}`,
                    data: [Dolar2008, Dolar2009, Dolar2010],
                    fill: true,
                    borderColor: '#0DA1B1',
                    backgroundColor: '#0DA1B1',
                  },
   ],
   
   
  })

  setGrafico(true)
}
  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Jurisdições - Faturamento em Período</div>
        {
            !paislist &&
            <div><button onClick={lista}>Gerar Lista</button></div>
           }
           
            {paislist  &&
            <>
        <div>
            <p>
                Tipo:
                <select defaultValue={'um'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                <option value="">Selecione</option>
                <option value="um">Uma Jurisdição - Dois Períodos</option>
                <option value="dois">Duas Jurisdições - Dois Períodos</option>
                <option value="tres">Três Jurisdições - Dois Períodos</option>
                <option value="umt">Uma Jurisdição - Três Períodos</option>
                <option value="doist">Duas Jurisdições - Três Períodos</option>
                <option value="trest">Três Jurisdições - Três Períodos</option>
            </select>
            </p>
        </div>
        {tipo === 'um' &&
            <div className='tipo'>
               <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'dois' &&
            <div className='tipo'>
               <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
               
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'tres' &&
            <>
            <div className='tipo'>
              <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione um Cliente:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            
            </>
        }
           {tipo === 'umt' &&
            <div className='tipo'>
               <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione o 3° período:</p>
                <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' &&
                <div><button onClick={handleChart4}>Gerar Gráfico</button></div>
            }
            </div>
        }
         {tipo === 'doist' &&
            <div className='tipo'>
               <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione o 3° período:</p>
                <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart5}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'trest' &&
          <>
            <div className='tipo'>
               <div className='selecionar'>
            <p>Selecione o 1° período:</p>
                <p>de: <input type="date" value={de1} onChange={(e)=>{setDe1(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate1} onChange={(e)=>{setAte1(e.target.value); setGrafico(false)}} /></p>
            </div>
            <div className='selecionar'>
                <p>Selecione o 2° período:</p>
                <p>de: <input type="date" value={de2} onChange={(e)=>{setDe2(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate2} onChange={(e)=>{setAte2(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione o 3° período:</p>
                <p>de: <input type="date" value={de3} onChange={(e)=>{setDe3(e.target.value); setGrafico(false)}} /></p>
                <p>até: <input type="date" value={ate3} onChange={(e)=>{setAte3(e.target.value); setGrafico(false)}} /></p>
                    
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            </div>
            <div>
            {de1 !== '' && ate1 !== '' && de2 !== '' && ate2 !== '' && de3 !== '' && ate3 !== '' && cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart6}>Gerar Gráfico</button></div>
            }
            </div>
            </>
        }
       </>}
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <BarChart chartData={projectData} />
        </div>
      </div>
      }

      </>     
    }
</div>
  )
}

export default JurisdiçõesFaturamentoPeriodo