import React,{useState, useEffect, useLayoutEffect} from 'react'
import {custosCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy, deleteDoc, doc, updateDoc} from "firebase/firestore"
import Header from '../../components/Header/Header'
import { db } from '../../libs/firebase';
import '../pages.css'

function Custos() {
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    const [custos, setCustos]=useState([])
    const [loading, setLoading]=useState(true);
    const [openEdit, setOpenEdit]=useState(false)
    const [id, setId]=useState('')
    const [ano, setAno]=useState('');
    const [pessoalReais, setPessoalReais]=useState(0)
    const [impostosReais, setImpostosReais]=useState(0)
    const [fornecedoresReais, setFornecdoresReais]=useState(0)
    const [date, setDate]=useState('')
    const [uploading, setUploading] = useState(false);
    const [updated, setUpdated]=useState(false);
    const [erros, setErros]=useState(false);
    const [erro, setErro]=useState('');

    useEffect(()=>{
        const q = query(custosCollectionRef, orderBy('ano', 'desc'));
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setCustos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
        return()=>{change()}
    
     },[]);

     const handleDelete =async(e, id)=>{
        e.preventDefault()
        
        await deleteDoc(doc(db, 'custos', id));
    }

    const handleClose=(e)=>{
        e.preventDefault()
        setId('');
        setAno('');
        setPessoalReais(0)
        setImpostosReais(0)
        setDate('')
        setFornecdoresReais(0)
        setOpenEdit(false)
    }

    const handleEdit = (e, id, ano, pessoalR, impostoR, forneceorR, date)=>{
        e.preventDefault();
        setId(id);
        setAno(ano);
        setPessoalReais(pessoalR)
        setImpostosReais(impostoR)
        setFornecdoresReais(forneceorR)
        setDate(date)
        setOpenEdit(true)
    }

    const handleUpdateCustos = async (e, id)=>{
        e.preventDefault();
        setUploading(true);
        const docRef = doc(db, "custos", id);
        const data ={
            ano,
            date,
            fornecedoresReais,
            pessoalReais,
            impostosReais
        };
        setUploading(false);
        await updateDoc(docRef, data, { merge:true })
        .then(docRef => { setUpdated(true) })
        .catch(error => { setErros(true); setErro(error); })
       
        setUpdated(false);
        setId('');
        setAno('');
        setPessoalReais(0)
        setImpostosReais(0)
        setOpenEdit(false)
        
      }

  return (
    <div>
         <Header />
         {loading &&
           <div className="pageTitle">
           <div>Carregando...</div>
         </div>
        }
        {!loading &&
          <>
          <div className="pageTitle">
            <div>Edição de Custos</div>
          </div>
          <div className="pageContent collum">
            {custos.map((item, index)=>(
             <div className="custosLine" key={index}>
                <div className='custoContent0'>
                    <span>Ano:</span>
                    <p>{item.data.ano}</p>
                </div>
                <div className='custoContent'>
                    <span>Pessoal Reais:</span>
                    <p>RS$ {(item.data.pessoalReais).toLocaleString('pt-br')}</p>
                </div>
                <div className='custoContent'>
                    <span>Fornecedores Reais:</span>
                    <p>RS$ {(item.data.fornecedoresReais).toLocaleString('pt-br')}</p>
                </div>
                <div className='custoContent'>
                    <span>Impostos Reais:</span>
                    <p>RS$ {(item.data.impostosReais).toLocaleString('pt-br')}</p>
                </div>
                <div className='custoContent'>
                    <span>Atualizado Até:</span>
                    <p>{item.data.date}</p>
                </div>
                <div className='custoContentB'>
                    <button onClick={(e)=>{handleEdit(e, item.id, item.data.ano, item.data.pessoalReais,item.data.impostosReais, item.data.fornecedoresReais, item.data.date )}}>Editar</button>
                </div>
                <div className='custoContentB'>
                    <button onClick={(e)=>{handleDelete(e, item.id)}}>Apagar</button>
                </div>
                
             </div>
            ))}
          </div>
          </>
        }
        {openEdit &&
            <div className="popUp">
                <div className="popContainer">
                    <form className='custoForm' onSubmit={(e)=>{handleUpdateCustos(e, id)}}>
                            <div className='custoFormLine'>
                                <div className='custoFormLine-text'>Ano:</div> 
                                <input type="text" onChange={(e)=>{setAno(e.target.value)}} defaultValue={ano} required />
                            </div>
                            <div className='custoFormLine'>
                                <div className='custoFormLine-text'>Custo Pessoal Reais:</div> 
                                <input type="text" onChange={(e)=>{setPessoalReais(Number(e.target.value))}} defaultValue={pessoalReais}required/>
                            </div>
                            <div className='custoFormLine'>
                                <div className='custoFormLine-text'>Custo Fornecedores Reais:</div> 
                                <input type="text" onChange={(e)=>{setFornecdoresReais(Number(e.target.value))}} defaultValue={fornecedoresReais}required/>
                            </div>
                            <div className='custoFormLine'>
                                <div className='custoFormLine-text'>Impostos Reais:</div> 
                                <input type="text" onChange={(e)=>{setImpostosReais(Number(e.target.value))}}defaultValue={impostosReais} required />
                            </div>
                            <div className='custoFormLine'>
                                <div className='custoFormLine-text'>Atualizado até o mês de:</div> 
                                <select defaultValue={date} onChange={(e)=>{setDate(e.target.value)}}>
                                    {meses.map((item, index)=>(
                                        <option value={item} key={index}>{item}</option>
                                    ))}
                                </select>
                            </div>
                                <div className='custoFormLine'>
                                <input type="submit" value="Atualizar Custos" />
                            </div>
                            <button onClick={(e)=>{handleClose(e)}}>FECHAR</button>
                            {uploading &&
                                <span>uploading...</span>
                            }
                            {updated &&
                                <span>O projeto foi atualizado com sucesso!</span>
                            }
                            {erros &&
                                <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
                            }
                        
                    </form>
                </div>

                
            </div>
        }
    </div>
  )
}

export default Custos