import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'
import BarChart from '../../components/Charts/BarChart';

function MaioresClientes() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
    
    useEffect(()=>{
        const q = query(projectsCollectionRef);
        setLoading(true)
        const change = onSnapshot(q, snapshot=>{
            setProjetos(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
          setLoading(false)
        })
       
        return()=>{change()}
    
     },[]);

     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))
          setLoading(false)
        },[projetos])

        const handleChart = ()=>{
            setGrafico(false)
            let clientesAndamento =  todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)
            let clientesemDuplicados = clientesAndamento.filter((v, i, s) => {
              return s.indexOf(v) === i;
            });
          
               
            
            var clientes1 = clientesemDuplicados.map((item, index)=>(
                {
                  "id": index,
                  "name":item,
                  "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length
          
              }
            ))

            var clientes2 = clientes1.sort((a,b)=>{
                if (a.quantidade < b.quantidade) {
                  return 1;
                }
                if (a.quantidade > b.quantidade) {
                  return -1;
                }
                return 0;
              })

            var clientes3 = clientes2.filter((val)=>{if(val.quantidade>20){return val}})
            
          
          

            setProjetData({
                labels: clientes3.map((i)=>i.name),
                datasets:[
                 {
                     label:'Quantidade de Projetos',
                     data: clientes3.map((item)=>item.quantidade),
                     fill: true,
                     borderColor: '#FF5400',
                     backgroundColor: '#11D5EB',
                   },
                
                
         
               ]
              })

            setGrafico(true)
        }
   return (
    <div>
    <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Maiores Clientes Histórico -  Quantidade de Projetos</div>
       <div className='subtitle'>Todos com mais de 20 Projetos</div>
       <div><button onClick={handleChart}>Gerar Gráfico</button></div>
     </div>
     {grafico &&
       <div className="pageContent">
       <div className="pageChart">
         <BarChart chartData={projectData} />
       </div>
     </div>
     }
     
     </>     
   }
</div>
  )
}

export default MaioresClientes