import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function Clientes6Meses() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [clientesDB, setClientesDB]=useState([])
    const [clientesCom, setClientesCom]=useState([])
    const [loading, setLoading]=useState(true);
    const [todos , setTodos]=useState([])
    const [lista, setLista]=useState(false)


    const hoje = new Date()

    const data1 = hoje.toISOString().slice(0, 10)
    const mesesDIminui = -5;
    function addMonths(date, months) {
        const newDate = new Date(date.valueOf());
        const currentMonth = newDate.getMonth();
        const newMonth = currentMonth + months;
        newDate.setMonth(newMonth);
        return newDate;
      }

    const data2A = addMonths(hoje, mesesDIminui);

    const data2 = data2A.toISOString().slice(0, 10)



    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      const change2 = onSnapshot(r, snapshot=>{
        setClientesDB(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })
      return()=>{change();change2()}
  
   },[]);

     useLayoutEffect(()=>{
        setLoading(true)
          setTodos(projetos.concat(ProjetosHistorico))

          
          setLoading(false)
        },[projetos])

       const  handleLista = ()=>{
        let projetos6meses = todos.filter((val)=>{if(val.data.projStartDate >= data2 && val.data.projStartDate <= data1 && val.data.projStatus1 !== 'deletado' ){return val}}).map((i)=>i.data.projClient)

          let projetos6mesesParaData = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado' ){return val}})

    
        let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projClient)

        let clientesemDuplicados = clientesFiltrados.filter((v, i, s) => {
          return s.indexOf(v) === i;
        });

        let handleUltimaData = (item)=>{
          let datas = projetos6mesesParaData.filter((val)=>{if(val.data.projClient === item ){return val}}).map((i)=>i.data.projStartDate)
              var dataOrganizada = datas.sort((a,b)=>{
                  if (a < b) {
                    return 1;
                  }
                  if (a > b) {
                    return -1;
                  }
                  return 0;
                })

                 var dataFinal=dataOrganizada.slice(0,1)
              
                return(dataFinal)
        }
        let handleUltimaData2 = (item)=>{
          let datas = todos.filter((val)=>{if(val.data.projClient === item ){return val}}).map((i)=>i.data.projStartDate)
              var dataOrganizada = datas.sort((a,b)=>{
                  if (a < b) {
                    return 1;
                  }
                  if (a > b) {
                    return -1;
                  }
                  return 0;
                })

                 var dataFinal=dataOrganizada.slice(0,1)
              
                return(dataFinal)
        }

        let handleStatus = (item)=>{
          let status = clientesDB.filter((val)=>{if(val.data.clientName === item){return val}}).map((item)=>item.data.clientStatus)
          return(status.toString())
        }

        var clientes1 = clientesemDuplicados.map((item, index)=>(
          {
            "id": index,
            "name":item,
            "quantidadeTotal": clientesFiltrados.filter((val)=>{if(val === item){return val}}).length,
            "quantidadeUltimos": projetos6meses.filter((val)=>{if(val === item){return val}}).length,
            "dataUltimo":handleUltimaData(item).toString(),
            "dataUltimo6":handleUltimaData2(item).toString(),
            "status": handleStatus(item)
        }
      ))
      console.log(clientes1)

      var clientes2 = clientes1.filter((val)=>{if(val.quantidadeUltimos === 0 && val.status === "Ativo"){return val}})
      
      var clientesZeroArrumados = clientes2.sort((a,b)=>{
          if (a.quantidadeTotal < b.quantidadeTotal) {
            return 1;
          }
          if (a.quantidadeTotal > b.quantidadeTotal) {
            return -1;
          }
          return 0;
        })

        setClientes(clientesZeroArrumados)
        
        var clientes3 = clientes1.filter((val)=>{if(val.quantidadeUltimos !== 0){return val}})

          var clientesArrumados = clientes3.sort((a,b)=>{
            if (a.quantidadeUltimos < b.quantidadeUltimos) {
              return 1;
            }
            if (a.quantidadeUltimos > b.quantidadeUltimos) {
              return -1;
            }
            return 0;
          })

        setClientesCom(clientesArrumados)
        setLista(true)
       }
      
  return (
    <div>
        <Header />
   {loading &&
      <div className="pageTitle">
      <div>Carregando...</div>
    </div>
   }
   {!loading &&
     <>
     <div className="pageTitle">
       <div>Clientes Nos Últimos 6 Meses</div>
       <button onClick={handleLista}>Gerar Lista</button>
     </div>
    { lista &&
    <div className="pageContent collum">
         <div className="contentLine2" style={{width:'70%', margin:'10px auto'}}>Clientes Com Projetos</div>
          <div className="linesClientes">
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Nome Cliente</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos 6 Meses</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos</p></div>
                  <div className="lines-itemC" style={{fontWeight:800}}><p>Data Projeto Mais Recente</p></div>
              </div>
          <div style={{marginBottom:'50px'}}>
              {clientesCom.map((item, index)=>(
              <div className="linesClientes" key={index}>
                  <div className="lines-itemC"><p>{item.name}</p></div>
                  <div className="lines-itemC"><p>{item.quantidadeUltimos}</p></div>
                  <div className="lines-itemC"><p>{item.quantidadeTotal}</p></div>
                  <div className="lines-itemC"><p>{new Date(item.dataUltimo6).toLocaleDateString('pt-br').slice(0,10)}</p></div>
              </div>
              ))}        
        </div>
        <div className="contentLine2" style={{width:'70%', margin:'10px auto'}}>Clientes Sem Projetos</div>
        <div className="linesClientes">
                <div className="lines-itemC" style={{fontWeight:800}}><p>Nome Cliente</p></div>
                <div className="lines-itemC" style={{fontWeight:800}}><p>Quantidade de Projetos</p></div>
                <div className="lines-itemC" style={{fontWeight:800}}><p>Data Projeto Mais Recente</p></div>
            </div>
        <div style={{marginBottom:'50px'}}>
            {clientes.map((item, index)=>(
            <div className="linesClientes" key={index}>
                <div className="lines-itemC"><p>{item.name}</p></div>
                <div className="lines-itemC"><p>{item.quantidadeTotal}</p></div>
                <div className="lines-itemC"><p>{new Date(item.dataUltimo).toLocaleDateString('pt-br').slice(0,10)}</p></div>
            </div>
            ))}        
        </div>
       
     </div>
     }
  
     
     </>     
   }
    </div>
  )
}

export default Clientes6Meses