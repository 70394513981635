import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, custosCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, where} from "firebase/firestore"
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function GeralHistorico() {
    const [projetos, setProjetos]=useState([]);
    const [custos, setCustos]=useState([]);
    const [custosAtuais, setCustosAtuais]=useState([])
    const [loading, setLoading]=useState(true);
    const [anoAtual, setAnoAtual]=useState('')
    const [mesAtual, setMesAtual]=useState('')
    const [mesDia, setMesDia]=useState('')
    const [dolarAno, setDolarAno]=useState(0)
    const [reaisAno, setReaisAno]=useState(0)
    const [dolarMes, setDolarMes]=useState(0)
    const [reaisMes, setReaisMes]=useState(0)
    
    const [reaisFaturado, setReaisFaturado]=useState(0)
    const [dolarFaturado, setDolarFaturado]=useState(0)
    const [grafico, setGrafico]=useState(false)
    const [mesAtualTxt, setMesAtualTxt] = useState('')

    const [todos, setTodos]=useState([])
    const [mesBalanco, setMesdalanco]=useState('')
    const [mesBtexto, setMesBtexto]=useState('')
    

    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];

    const meses =[
      {
        texto:'Janeiro',
        numero:'01',
        dia: '31'
      },
      {
        texto:'Fevereiro',
        numero:'02',
        dia: '29'
      },
      {
        texto:'Março',
        numero:'03',
        dia: '31'
      },
      {
        texto:'Abril',
        numero:'04',
        dia: '30'
      },
      {
        texto:'Maio',
        numero:'05',
        dia: '31'
      },
      {
        texto:'Junho',
        numero:'06',
        dia: '30'
      },
      {
        texto:'Julho',
        numero:'07',
        dia: '31'
      },
      {
        texto:'Agosto',
        numero:'08',
        dia: '31'
      },
      {
        texto:'Setembro',
        numero:'09',
        dia: '30'
      },
      {
        texto:'Outubro',
        numero:'10',
        dia: '31'
      },
      {
        texto:'Novembro',
        numero:'11',
        dia: '30'
      },
      {
        texto:'Dezembro',
        numero:'12',
        dia: '31'
      },
      ]

   

    const HandleMes = (e)=>{
        setMesAtual(e.target.value)
        var select = document.querySelector('#selectmes');
        var option = select.children[select.selectedIndex];
        var texto = option.textContent;
        var ultimoDia = option.dia;
        setMesAtualTxt(texto)
        setMesDia(ultimoDia)
    }
  
    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      
      return()=>{change()}
  
   },[]);

   useEffect(()=>{

    const r = query(custosCollectionRef);
    
    const change2 = onSnapshot(r, snapshot=>{
      setCustos(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
    setLoading(false)
  })
    return()=>{change2()}

 },[]);

  
   useLayoutEffect(()=>{
    setLoading(true)

    setTodos(projetos.concat(ProjetosHistorico))

    setLoading(false)
  
  
  },[projetos])


  const HandleAnoMes =()=>{
    setGrafico(false)
  
    //ANO
    let projetosAno = todos.filter((val)=>{if(new Date(val.data.projStartDate).getFullYear() === anoAtual && val.data.projStatus1 !== 'deletado' ){return val}})
    let reaislist = projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  length = reaislist.length;
    var reaisNumeros = [];
    for (var i = 0; i < length; i++)
    reaisNumeros.push(Number(reaislist[i]))
  
    let reaislist2 = projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  
    var  length2 = reaislist2.length;
    var reaisNumeros2 = [];
    for (var i2 = 0; i2 < length2; i2++)
    reaisNumeros2.push(Number(reaislist2[i2]))
  
    let reaisTotalAno = (reaisNumeros.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2.reduce((acc, curr) => acc + curr, 0))
  
    let dolarlist =projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  lengthD = dolarlist.length;
    var dolarNumeros = [];
    for (var a = 0; a < lengthD; a++)
    dolarNumeros.push(Number(dolarlist[a]))
  
    let dolarlist2 =projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  
    var  lengthD2 = dolarlist2.length;
    var dolarNumeros2 = [];
    for (var a2 = 0; a2 < lengthD2; a2++)
    dolarNumeros2.push(Number(dolarlist2[a2]))
    let dolarTotalAno = (dolarNumeros.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2.reduce((acc, curr) => acc + curr, 0))
  
  
  
    setReaisAno(reaisTotalAno)
    setDolarAno(dolarTotalAno)
    
   
  
    //MES

    
    
  
    let projetosMes = todos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-${mesAtual}-01` && val.data.projStartDate <= `${anoAtual}-${mesAtual}-${mesDia}` && val.data.projStatus1 !== 'deletado' ){return val}})
  
    let reaislistMes = projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  lengthRM = reaislistMes.length;
    var reaisNumerosMes = [];
    for (var i3 = 0; i3 < lengthRM; i3++)
    reaisNumerosMes.push(Number(reaislistMes[i3]))
  
    let reaislist2Mes = projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var  length2Mes = reaislist2Mes.length;
    var reaisNumeros2Mes = [];
    for (var i4 = 0; i4 < length2Mes; i4++)
    reaisNumeros2Mes.push(Number(reaislist2Mes[i4]))
  
    let reaisTotalMes = (reaisNumerosMes.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2Mes.reduce((acc, curr) => acc + curr, 0))
  
    let dolarlistMes =projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  lengthDMes = dolarlistMes.length;
    var dolarNumerosMes = [];
    for (var a3 = 0; a3 < lengthDMes; a3++)
    dolarNumerosMes.push(Number(dolarlistMes[a3]))
  
    let dolarlist2Mes =projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  
    var  lengthD2Mes = dolarlist2Mes.length;
    var dolarNumeros2Mes = [];
    for (var a4 = 0; a4 < lengthD2Mes; a4++)
    dolarNumeros2Mes.push(Number(dolarlist2Mes[a4]))
    let dolarTotalMes = (dolarNumerosMes.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2Mes.reduce((acc, curr) => acc + curr, 0))
  
    setReaisMes(reaisTotalMes)
    setDolarMes(dolarTotalMes)
  
   
  
    setReaisFaturado(reaisNumeros.reduce((acc, curr) => acc + curr, 0))
    setDolarFaturado(dolarNumeros.reduce((acc, curr) => acc + curr, 0))

    var CAtuais= custos.filter((val)=>{if(val.data.ano.includes(`${anoAtual}`)){return val}})

    setCustosAtuais(CAtuais)

    //BALANÇO

    let periodo = custos.filter((val)=>{if(val.data.ano.includes(`${anoAtual}`)){return val}}).map((i)=>(i.data.date))
    let periodoMesB = periodo[0]
    setMesBtexto(periodoMesB)
   
   setMesdalanco( ()=>{
    if(periodoMesB === 'Janeiro'){
      setMesdalanco('01')
    }
    if(periodoMesB === 'Fevereiro'){
      setMesdalanco('02')
    }
    if(periodoMesB === 'Março'){
      setMesdalanco('03')
    }
    if(periodoMesB === 'Abril'){
      setMesdalanco('04')
    }
    if(periodoMesB === 'Maio'){
      setMesdalanco('05')
    }
    if(periodoMesB === 'Junho'){
      setMesdalanco('06')
    }
    if(periodoMesB === 'Julho'){
      setMesdalanco('07')
    }
    if(periodoMesB === 'Agosto'){
      setMesdalanco('08')
    }
    if(periodoMesB === 'Setembro'){
      setMesdalanco('09')
    }
    if(periodoMesB === 'Outubro'){
      setMesdalanco('10')
    }
    if(periodoMesB === 'Novembro'){
      setMesdalanco('11')
    }
    if(periodoMesB === 'Dezembro'){
      setMesdalanco('12')
    }
  }
   )
   let projetosPeriodo = todos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-01-01` && val.data.projStartDate <= `${anoAtual}-${mesBalanco}-31` && val.data.projStatus1 !== 'deletado' ){return val}})

    let reaislistaB = projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var  lengthP = reaislistaB.length;
      var reaisNumerosP = [];
      for (var i = 0; i < lengthP; i++)
      reaisNumerosP.push(Number(reaislistaB[i]))
    
      let reaislista2 = projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    
      var  length2P = reaislista2.length;
      var reaisNumeros2P = [];
      for (var i2 = 0; i2 < length2P; i2++)
      reaisNumeros2P.push(Number(reaislista2[i2]))
    
      let reaisTotalAnoP = (reaisNumerosP.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2P.reduce((acc, curr) => acc + curr, 0))
    
      let dolarlistP =projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var  lengthDP = dolarlistP.length;
      var dolarNumerosP = [];
      for (var a = 0; a < lengthDP; a++)
      dolarNumerosP.push(Number(dolarlistP[a]))
    
      let dolarlistP2 =projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    
      var  lengthDP2 = dolarlistP2.length;
      var dolarNumerosP2 = [];
      for (var a2 = 0; a2 < lengthDP2; a2++)
      dolarNumerosP2.push(Number(dolarlistP2[a2]))
      let dolarTotalAnoP = (dolarNumerosP.reduce((acc, curr) => acc + curr, 0)+dolarNumerosP2.reduce((acc, curr) => acc + curr, 0))

  setReaisFaturado(reaisTotalAnoP)
  setDolarFaturado(dolarTotalAnoP)

  
    setGrafico(true)
  
  }

  useEffect(()=>{
     //BALANÇO

     let periodo = custos.filter((val)=>{if(val.data.ano.includes(`${anoAtual}`)){return val}}).map((i)=>(i.data.date))
      let periodoMesB = periodo[0]
      setMesBtexto(periodoMesB)
     
     setMesdalanco( ()=>{
      if(periodoMesB === 'Janeiro'){
        setMesdalanco('01')
      }
      if(periodoMesB === 'Fevereiro'){
        setMesdalanco('02')
      }
      if(periodoMesB === 'Março'){
        setMesdalanco('03')
      }
      if(periodoMesB === 'Abril'){
        setMesdalanco('04')
      }
      if(periodoMesB === 'Maio'){
        setMesdalanco('05')
      }
      if(periodoMesB === 'Junho'){
        setMesdalanco('06')
      }
      if(periodoMesB === 'Julho'){
        setMesdalanco('07')
      }
      if(periodoMesB === 'Agosto'){
        setMesdalanco('08')
      }
      if(periodoMesB === 'Setembro'){
        setMesdalanco('09')
      }
      if(periodoMesB === 'Outubro'){
        setMesdalanco('10')
      }
      if(periodoMesB === 'Novembro'){
        setMesdalanco('11')
      }
      if(periodoMesB === 'Dezembro'){
        setMesdalanco('12')
      }
    }
     )
     let projetosPeriodo = todos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-01-01` && val.data.projStartDate <= `${anoAtual}-${mesBalanco}-31` && val.data.projStatus1 !== 'deletado' ){return val}})
  
      let reaislistaB = projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var  lengthP = reaislistaB.length;
        var reaisNumerosP = [];
        for (var i = 0; i < lengthP; i++)
        reaisNumerosP.push(Number(reaislistaB[i]))
      
        let reaislista2 = projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      
        var  length2P = reaislista2.length;
        var reaisNumeros2P = [];
        for (var i2 = 0; i2 < length2P; i2++)
        reaisNumeros2P.push(Number(reaislista2[i2]))
      
        let reaisTotalAnoP = (reaisNumerosP.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2P.reduce((acc, curr) => acc + curr, 0))
      
        let dolarlistP =projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var  lengthDP = dolarlistP.length;
        var dolarNumerosP = [];
        for (var a = 0; a < lengthDP; a++)
        dolarNumerosP.push(Number(dolarlistP[a]))
      
        let dolarlistP2 =projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      
        var  lengthDP2 = dolarlistP2.length;
        var dolarNumerosP2 = [];
        for (var a2 = 0; a2 < lengthDP2; a2++)
        dolarNumerosP2.push(Number(dolarlistP2[a2]))
        let dolarTotalAnoP = (dolarNumerosP.reduce((acc, curr) => acc + curr, 0)+dolarNumerosP2.reduce((acc, curr) => acc + curr, 0))
  
    setReaisFaturado(reaisTotalAnoP)
    setDolarFaturado(dolarTotalAnoP)
  },[anoAtual])

  return (
    <div>
      <div>
         <Header />
        {loading &&
           <div className="pageTitle">
           <div>Carregando...</div>
         </div>
        }
        {!loading &&
          <>
          <div className="pageTitle">
            <div>Visão Geral Histórico</div>
            
           
                <div className='selecionar'>
                    <p>Selecione um ano:</p>
                    <select onChange={(e)=>{setAnoAtual(Number(e.target.value));  setGrafico(false);}}>
                        <option value="">Selecione</option>
                        {anos.map((item, index)=>(
                            <option value={item} key={index}>{item}</option>

                        ))}
                        
                    </select>

                    <p>Selecione um mês:</p>
                    <select onChange={(e)=>{HandleMes(e);  setGrafico(false)}} id='selectmes'>
                        <option value="">Selecione</option>
                        {meses.map((item, index)=>(
                            <option value={item.numero} dia={item.dia} key={index}>{item.texto}</option>

                        ))}
                    </select>
                    {anoAtual !== '' && mesAtual !== '' &&
                        <button onClick={HandleAnoMes} style={{marginTop:'20px'}} >Gerar Visão Geral</button>
                    }
                </div>
           
            
            
          </div>
        
        {grafico &&
          
          <div className="pageContent collum">
            <div className="contentLine2">Projetos - {mesAtualTxt} / {anoAtual}</div>
            <div className="contentLine">
                <div className="bloco">
                    <div className="linesTitulo">Ano Todo - {anoAtual}</div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Projetos Vendidos:</p></div>
                      <div className="lines-itemR">{todos.filter((val)=>{if(val.data.projStartDate.includes(anoAtual) && val.data.projStatus1 !== 'deletado' ){return val}}).length}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores USD:</p></div>
                      <div className="lines-itemR">US$ {dolarAno.toLocaleString('pt-BR')}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores BRL:</p></div>
                      <div className="lines-itemR">RS$ {reaisAno.toLocaleString('pt-BR')}</div>
                    </div>
                   
                  </div>

                  <div className="bloco">
                    <div className="linesTitulo">Mês de {mesAtualTxt}</div>
                        <div className="lines">
                        <div className="lines-itemL"><p>Projetos Vendidos:</p></div>
                        <div className="lines-itemR">{todos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-${mesAtual}-01` && val.data.projStartDate <= `${anoAtual}-${mesAtual.toString()}-${mesDia}` && val.data.projStatus1 !== 'deletado' ){return val}}).length}</div>
                        </div>
                        <div className="lines">
                        <div className="lines-itemL"><p>Valores USD:</p></div>
                        <div className="lines-itemR">US$ {dolarMes.toLocaleString('pt-br')}</div>
                        </div>
                        <div className="lines">
                        <div className="lines-itemL"><p>Valores BRL:</p></div>
                        <div className="lines-itemR">RS$ {reaisMes.toLocaleString('pt-br')}</div>
                        </div>
                    
                  </div>

              </div>

              <div className="contentLine2">Balanço {anoAtual} de Janeiro até {mesBtexto}</div>
              <div className="contentLine">
              <div className="bloco">
                    <div className="linesTitulo">Entradas</div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Fatrumento Dólares:</p></div>
                        <div className="lines-itemR">US$ {dolarFaturado.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Fatrumento Reais:</p></div>
                        <div className="lines-itemR">RS$ {reaisFaturado.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="linesTitulo">Saidas</div>
                    {custosAtuais.map((item, index)=>(
                    <div className="bloco" key={index}>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Pessoal Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.pessoalReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Impostos Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.impostosReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Fornecedores Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.fornecedoresReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Total de Gastos Reais:</p></div>
                        <div className="lines-itemR">RS$ {(item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais).toLocaleString('pt-br')}</div>
                    </div>

                    <div className="lines">
                        <div className="lines-itemL"><p>Atualizados Até o Mês:</p></div>
                        <div className="lines-itemR">{item.data.date}</div>
                    </div>
                    </div>
                    ))}

                  </div>

                  <div className="bloco">
                  <div className="linesTitulo">Cálculos</div>
                  {custosAtuais.map((item, index)=>(
                  <div className="bloco" key={index}>
                  
                    <div className="lines">
                        <div className="lines-itemL"><p>Lucro Reais:</p></div>
                        <div className="lines-itemR">RS$ {(reaisFaturado-(item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais)).toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Lucro Reais % :</p></div>
                        <div className="lines-itemR">{Math.round(100-((item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais)/reaisFaturado)*100)} %</div>
                    </div>
                    </div>
                    ))}

                  </div>

              </div>
          </div>
             
        }
          </> }
    </div>
    </div>
   
  )
}

export default GeralHistorico