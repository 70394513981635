import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, orderBy} from "firebase/firestore"
import LineChart from '../../components/Charts/LineChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function JurisdicoesAnoFaturamento() {
    const [projetos, setProjetos]=useState([]);
    const [clientes, setClientes]=useState([])
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)
  
    const [tipo, setTipo]=useState('um')
  
    const [ano, setAno]=useState('2024')
    const [cliente1, setCliente1]=useState('')
    const [cliente2, setCliente2]=useState('')
    const [cliente3, setCliente3]=useState('')
    const [paislist, setPaisList]=useState(false)

    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      const r = query(clientsCollectionRef, orderBy('clientName', 'asc')) 
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      /*const change2 = onSnapshot(r, snapshot=>{
        setClientes(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })*/
      return()=>{change();/* change2()*/}
  
   },[]);
  
  useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
        let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
    let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setLoading(false)
  },[projetos])

  const lista =()=>{
    setTodos(projetos.concat(ProjetosHistorico))
  
    let clientesFiltrados = todos.filter((val)=>{if(val.data.projStatus1 !== 'deletado'){return val}}).map((i)=>i.data.projJurisdictions)
        let juriscicoesNomes = clientesFiltrados.reduce((list, sub) => list.concat(sub), [])
    let clientesemDuplicados = juriscicoesNomes.filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  
    let paisesOrdenados = clientesemDuplicados.sort(function (a, b) {
        return a.localeCompare(b);
      });
    setClientes(paisesOrdenados)
    setPaisList(true)
  }
  
  const handleChart =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)
    
    
  
    setProjetData({
      labels:meses ,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: true,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: true,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  
  const handleChart2 =()=>{
    setGrafico(false)
    const meses =['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  
    const NumJaneiro = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
  
    const NumFevereiro =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})

    const NumMarco = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})

    const NumAbril =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumMaio= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumJunho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumJulho= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumAgosto= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumSetembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumOutubro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumNovembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    
    const NumDezembro= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})

    // janeiro
    const pReaisJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJaneiroL = pReaisJaneiro.length
      var pReaisJaneiroNumeros = [];
      for (var r1 = 0; r1 < pReaisJaneiroL; r1++)
      pReaisJaneiroNumeros.push(Number(pReaisJaneiro[r1]))
      const pReaisJaneiroTotal1 = pReaisJaneiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJaneiroL2 = pReaisJaneiro2.length
      var pReaisJaneiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJaneiroL2; r2++)
      pReaisJaneiroNumeros2.push(Number(pReaisJaneiro2[r2]))
      const pReaisJaneiroTotal2 = pReaisJaneiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJaneiro = (pReaisJaneiroTotal1+pReaisJaneiroTotal2)
  
      const pDolarJaneiro = NumJaneiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJaneiroL = pDolarJaneiro.length
      var pDolarJanrioNumeros = [];
      for (var d1 = 0; d1 < pDolarJaneiroL; d1++)
      pDolarJanrioNumeros.push(Number(pDolarJaneiro[d1]))
      const pDolarJaneiroTotal1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJaneiro2 = NumJaneiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJaneiroL2 = pDolarJaneiro2.length
      var pDolarJanrioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJaneiroL2; d2++)
      pDolarJanrioNumeros2.push(Number(pDolarJaneiro2[d2]))
      const pDolarJaneiroTotal2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJaneiro = (pDolarJaneiroTotal1+pDolarJaneiroTotal2)
  
  
  
      //fevereiro  
      const pReaisFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisFevereiroL = pReaisFevereiro.length
      var pReaisFevereiroNumeros = [];
      for (var r1 = 0; r1 < pReaisFevereiroL; r1++)
      pReaisFevereiroNumeros.push(Number(pReaisFevereiro[r1]))
      const pReaisFevereiroTotal1 = pReaisFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisFevereiroL2 = pReaisFevereiro2.length
      var pReaisFevereiroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisFevereiroL2; r2++)
      pReaisFevereiroNumeros2.push(Number(pReaisFevereiro2[r2]))
      const pReaisFevereiroTotal2 = pReaisFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisFevereiro = (pReaisFevereiroTotal1+pReaisFevereiroTotal2)
  
      const pDolarFevereiro = NumFevereiro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarFevereiroL = pDolarFevereiro.length
      var pDolarFevereiroNumeros = [];
      for (var d1 = 0; d1 < pDolarFevereiroL; d1++)
      pDolarFevereiroNumeros.push(Number(pDolarFevereiro[d1]))
      const pDolarFevereiroTotal1 = pDolarFevereiroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarFevereiro2 = NumFevereiro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarFevereiroL2 = pDolarFevereiro2.length
      var pDolarFevereiroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarFevereiroL2; d2++)
      pDolarFevereiroNumeros2.push(Number(pDolarFevereiro2[d2]))
      const pDolarFevereiroTotal2 = pDolarFevereiroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarFevereiro = (pDolarFevereiroTotal1+pDolarFevereiroTotal2)
      
      //Marco
      const pReaisMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMarcoL = pReaisMarco.length
      var pReaisMarcoNumeros = [];
      for (var r1 = 0; r1 < pReaisMarcoL; r1++)
      pReaisMarcoNumeros.push(Number(pReaisMarco[r1]))
      const pReaisMarcoTotal1 = pReaisMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMarcoL2 = pReaisMarco2.length
      var pReaisMarcoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMarcoL2; r2++)
      pReaisMarcoNumeros2.push(Number(pReaisMarco2[r2]))
      const pReaisMarcoTotal2 = pReaisMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMarco = (pReaisMarcoTotal1+pReaisMarcoTotal2)
  
      const pDolarMarco = NumMarco.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMarcoL = pDolarMarco.length
      var pDolarMarcoNumeros = [];
      for (var d1 = 0; d1 < pDolarMarcoL; d1++)
      pDolarMarcoNumeros.push(Number(pDolarMarco[d1]))
      const pDolarMarcoTotal1 = pDolarMarcoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMarco2 = NumMarco.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMarcoL2 = pDolarMarco2.length
      var pDolarMarcoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMarcoL2; d2++)
      pDolarMarcoNumeros2.push(Number(pDolarMarco2[d2]))
      const pDolarMarcoTotal2 = pDolarMarcoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMarco = (pDolarMarcoTotal1+pDolarMarcoTotal2)
  
      ///Abril
      const pReaisAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAbrilL = pReaisAbril.length
      var pReaisAbrilNumeros = [];
      for (var r1 = 0; r1 < pReaisAbrilL; r1++)
      pReaisAbrilNumeros.push(Number(pReaisAbril[r1]))
      const pReaisAbrilTotal1 = pReaisAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAbrilL2 = pReaisAbril2.length
      var pReaisAbrilNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAbrilL2; r2++)
      pReaisAbrilNumeros2.push(Number(pReaisAbril2[r2]))
      const pReaisAbrilTotal2 = pReaisAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAbril = (pReaisAbrilTotal1+pReaisAbrilTotal2)
  
      const pDolarAbril = NumAbril.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAbrilL = pDolarAbril.length
      var pDolarAbrilNumeros = [];
      for (var d1 = 0; d1 < pDolarAbrilL; d1++)
      pDolarAbrilNumeros.push(Number(pDolarAbril[d1]))
      const pDolarAbrilTotal1 = pDolarAbrilNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAbril2 = NumAbril.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAbrilL2 = pDolarAbril2.length
      var pDolarAbrilNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAbrilL2; d2++)
      pDolarAbrilNumeros2.push(Number(pDolarAbril2[d2]))
      const pDolarAbrilTotal2 = pDolarAbrilNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAbril = (pDolarAbrilTotal1+pDolarAbrilTotal2)
      //Maio
      const pReaisMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisMaioL = pReaisMaio.length
      var pReaisMaioNumeros = [];
      for (var r1 = 0; r1 < pReaisMaioL; r1++)
      pReaisMaioNumeros.push(Number(pReaisMaio[r1]))
      const pReaisMaioTotal1 = pReaisMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisMaioL2 = pReaisMaio2.length
      var pReaisMaioNumeros2 = [];
      for (var r2 = 0; r2 < pReaisMaioL2; r2++)
      pReaisMaioNumeros2.push(Number(pReaisMaio2[r2]))
      const pReaisMaioTotal2 = pReaisMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisMaio = (pReaisMaioTotal1+pReaisMaioTotal2)
  
      const pDolarMaio = NumMaio.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarMaioL = pDolarMaio.length
      var pDolarMaioNumeros = [];
      for (var d1 = 0; d1 < pDolarMaioL; d1++)
      pDolarMaioNumeros.push(Number(pDolarMaio[d1]))
      const pDolarMaioTotal1 = pDolarMaioNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarMaio2 = NumMaio.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarMaioL2 = pDolarMaio2.length
      var pDolarMaioNumeros2 = [];
      for (var d2 = 0; d2 < pDolarMaioL2; d2++)
      pDolarMaioNumeros2.push(Number(pDolarMaio2[d2]))
      const pDolarMaioTotal2 = pDolarMaioNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarMaio = (pDolarMaioTotal1+pDolarMaioTotal2)
  
      //Junho
      const pReaisJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJunhoL = pReaisJunho.length
      var pReaisJunhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJunhoL; r1++)
      pReaisJunhoNumeros.push(Number(pReaisJunho[r1]))
      const pReaisJunhoTotal1 = pReaisJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJunhoL2 = pReaisJunho2.length
      var pReaisJunhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJunhoL2; r2++)
      pReaisJunhoNumeros2.push(Number(pReaisJunho2[r2]))
      const pReaisJunhoTotal2 = pReaisJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJunho = (pReaisJunhoTotal1+pReaisJunhoTotal2)
  
      const pDolarJunho = NumJunho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJunhoL = pDolarJunho.length
      var pDolarJunhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJunhoL; d1++)
      pDolarJunhoNumeros.push(Number(pDolarJunho[d1]))
      const pDolarJunhoTotal1 = pDolarJunhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJunho2 = NumJunho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJunhoL2 = pDolarJunho2.length
      var pDolarJunhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJunhoL2; d2++)
      pDolarJunhoNumeros2.push(Number(pDolarJunho2[d2]))
      const pDolarJunhoTotal2 = pDolarJunhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJunho = (pDolarJunhoTotal1+pDolarJunhoTotal2)
      
      //Julho
      const pReaisJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisJulhoL = pReaisJulho.length
      var pReaisJulhoNumeros = [];
      for (var r1 = 0; r1 < pReaisJulhoL; r1++)
      pReaisJulhoNumeros.push(Number(pReaisJulho[r1]))
      const pReaisJulhoTotal1 = pReaisJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisJulhoL2 = pReaisJulho2.length
      var pReaisJulhoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisJulhoL2; r2++)
      pReaisJulhoNumeros2.push(Number(pReaisJulho2[r2]))
      const pReaisJulhoTotal2 = pReaisJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisJulho = (pReaisJulhoTotal1+pReaisJulhoTotal2)
  
      const pDolarJulho = NumJulho.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarJulhoL = pDolarJulho.length
      var pDolaroJulhoNumeros = [];
      for (var d1 = 0; d1 < pDolarJulhoL; d1++)
      pDolaroJulhoNumeros.push(Number(pDolarJulho[d1]))
      const pDolarJulhoTotal1 = pDolaroJulhoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarJulho2 = NumJulho.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarJulhoL2 = pDolarJulho2.length
      var pDolaroJulhoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarJulhoL2; d2++)
      pDolaroJulhoNumeros2.push(Number(pDolarJulho2[d2]))
      const pDolarJulhoTotal2 = pDolaroJulhoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarJulho = (pDolarJulhoTotal1+pDolarJulhoTotal2)
  
      //Agosto
      const pReaisAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisAgostoL = pReaisAgosto.length
      var pReaisAgostoNumeros = [];
      for (var r1 = 0; r1 < pReaisAgostoL; r1++)
      pReaisAgostoNumeros.push(Number(pReaisAgosto[r1]))
      const pReaisAgostoTotal1 = pReaisAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisAgostoL2 = pReaisAgosto2.length
      var pReaisAgostoNumeros2 = [];
      for (var r2 = 0; r2 < pReaisAgostoL2; r2++)
      pReaisAgostoNumeros2.push(Number(pReaisAgosto2[r2]))
      const pReaisAgostoTotal2 = pReaisAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisAgosto = (pReaisAgostoTotal1+pReaisAgostoTotal2)
  
      const pDolarAgosto = NumAgosto.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarAgostoL = pDolarAgosto.length
      var pDolarAgostoNumeros = [];
      for (var d1 = 0; d1 < pDolarAgostoL; d1++)
      pDolarAgostoNumeros.push(Number(pDolarAgosto[d1]))
      const pDolarAgostoTotal1 = pDolarAgostoNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarAgosto2 = NumAgosto.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarAgostoL2 = pDolarAgosto2.length
      var pDolarAgostoNumeros2 = [];
      for (var d2 = 0; d2 < pDolarAgostoL2; d2++)
      pDolarAgostoNumeros2.push(Number(pDolarAgosto2[d2]))
      const pDolarAgostoTotal2 = pDolarAgostoNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarAgosto = (pDolarAgostoTotal1+pDolarAgostoTotal2)
      
      //Setembro
      const pReaisSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisSetembroL = pReaisSetembro.length
      var pReaisSetembroNumeros = [];
      for (var r1 = 0; r1 < pReaisSetembroL; r1++)
      pReaisSetembroNumeros.push(Number(pReaisSetembro[r1]))
      const pReaisSetembroTotal1 = pReaisSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisSetembroL2 = pReaisSetembro2.length
      var pReaisSetembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisSetembroL2; r2++)
      pReaisSetembroNumeros2.push(Number(pReaisSetembro2[r2]))
      const pReaisSetembroTotal2 = pReaisSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisSetembro = (pReaisSetembroTotal1+pReaisSetembroTotal2)
      
  
      const pDolarSetembro = NumSetembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarSetembroL = pDolarSetembro.length
      var pDolarSetembroNumeros = [];
      for (var d1 = 0; d1 < pDolarSetembroL; d1++)
      pDolarSetembroNumeros.push(Number(pDolarSetembro[d1]))
      const pDolarSetembroTotal1 = pDolarSetembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarSetembro2 = NumSetembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarSetembroL2 = pDolarSetembro2.length
      var pDolarSetembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarSetembroL2; d2++)
      pDolarSetembroNumeros2.push(Number(pDolarSetembro2[d2]))
      const pDolarSetembroTotal2 = pDolarSetembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarSetembro = (pDolarSetembroTotal1+pDolarSetembroTotal2)
      
      //Outubro
      const pReaisOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisOutubroL = pReaisOutubro.length
      var pReaisOutubroNumeros = [];
      for (var r1 = 0; r1 < pReaisOutubroL; r1++)
      pReaisOutubroNumeros.push(Number(pReaisOutubro[r1]))
      const pReaisOutubroTotal1 = pReaisOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisOutubroL2 = pReaisOutubro2.length
      var pReaisOutubroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisOutubroL2; r2++)
      pReaisOutubroNumeros2.push(Number(pReaisOutubro2[r2]))
      const pReaisOutubroTotal2 = pReaisOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisOutubro = (pReaisOutubroTotal1+pReaisOutubroTotal2)
  
      const pDolarOutubro = NumOutubro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarOutubroL = pDolarOutubro.length
      var pDolarOutubroNumeros = [];
      for (var d1 = 0; d1 < pDolarOutubroL; d1++)
      pDolarOutubroNumeros.push(Number(pDolarOutubro[d1]))
      const pDolarOutubroTotal1 = pDolarOutubroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarOutubro2 = NumOutubro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarOutubroL2 = pDolarOutubro2.length
      var pDolarOutubroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarOutubroL2; d2++)
      pDolarOutubroNumeros2.push(Number(pDolarOutubro2[d2]))
      const pDolarOutubroTotal2 = pDolarOutubroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarOutubro = (pDolarOutubroTotal1+pDolarOutubroTotal2)
  
      //Novembro
      const pReaisNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisNovembroL = pReaisNovembro.length
      var pReaisNovembroNumeros = [];
      for (var r1 = 0; r1 < pReaisNovembroL; r1++)
      pReaisNovembroNumeros.push(Number(pReaisNovembro[r1]))
      const pReaisNovembroTotal1 = pReaisNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisNovembroL2 = pReaisNovembro2.length
      var pReaisNovembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisNovembroL2; r2++)
      pReaisNovembroNumeros2.push(Number(pReaisNovembro2[r2]))
      const pReaisNovembroTotal2 = pReaisNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisNovembro = (pReaisNovembroTotal1+pReaisNovembroTotal2)
  
      const pDolarNovembro = NumNovembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarNovembroL = pDolarNovembro.length
      var pDolarNovembroNumeros = [];
      for (var d1 = 0; d1 < pDolarNovembroL; d1++)
      pDolarNovembroNumeros.push(Number(pDolarNovembro[d1]))
      const pDolarNovembroTotal1 = pDolarNovembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarNovembro2 = NumNovembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarNovembroL2 = pDolarNovembro2.length
      var pDolarNovembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarNovembroL2; d2++)
      pDolarNovembroNumeros2.push(Number(pDolarNovembro2[d2]))
      const pDolarNovembroTotal2 = pDolarNovembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarNovembro = (pDolarNovembroTotal1+pDolarNovembroTotal2)
    
  
      //Dezembro
      const pReaisDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pReaisDezembroL = pReaisDezembro.length
      var pReaisDezembroNumeros = [];
      for (var r1 = 0; r1 < pReaisDezembroL; r1++)
      pReaisDezembroNumeros.push(Number(pReaisDezembro[r1]))
      const pReaisDezembroTotal1 = pReaisDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pReaisDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pReaisDezembroL2 = pReaisDezembro2.length
      var pReaisDezembroNumeros2 = [];
      for (var r2 = 0; r2 < pReaisDezembroL2; r2++)
      pReaisDezembroNumeros2.push(Number(pReaisDezembro2[r2]))
      const pReaisDezembroTotal2 = pReaisDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const ReaisDezembro = (pReaisDezembroTotal1+pReaisDezembroTotal2)
  
      const pDolarDezembro = NumDezembro.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var pDolarDezembroL = pDolarDezembro.length
      var pDolarDezembroNumeros = [];
      for (var d1 = 0; d1 < pDolarDezembroL; d1++)
      pDolarDezembroNumeros.push(Number(pDolarDezembro[d1]))
      const pDolarDezembroTotal1 = pDolarDezembroNumeros.reduce((acc, curr) => acc + curr, 0)
          
      const pDolarDezembro2 = NumDezembro.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
      var pDolarDezembroL2 = pDolarDezembro2.length
      var pDolarDezembroNumeros2 = [];
      for (var d2 = 0; d2 < pDolarDezembroL2; d2++)
      pDolarDezembroNumeros2.push(Number(pDolarDezembro2[d2]))
      const pDolarDezembroTotal2 = pDolarDezembroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
      const DolarDezembro = (pDolarDezembroTotal1+pDolarDezembroTotal2)

      const NumJaneiroCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-01-01` && val.data.projStartDate <= `${ano}-01-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
  
        const NumFevereiroCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-02-01` && val.data.projStartDate <= `${ano}-02-29` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
    
        const NumMarcoCl2 = todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-03-01` && val.data.projStartDate <= `${ano}-03-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
    
        const NumAbrilCl2 =todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-04-01` && val.data.projStartDate <= `${ano}-04-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumMaioCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-05-01` && val.data.projStartDate <= `${ano}-05-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumJunhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-06-01` && val.data.projStartDate <= `${ano}-06-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumJulhoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-07-01` && val.data.projStartDate <= `${ano}-07-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumAgostoCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-08-01` && val.data.projStartDate <= `${ano}-08-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumSetembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-09-01` && val.data.projStartDate <= `${ano}-09-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumOutubroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-10-01` && val.data.projStartDate <= `${ano}-10-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumNovembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-11-01` && val.data.projStartDate <= `${ano}-11-30` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        
        const NumDezembroCl2= todos.filter((val)=>{if(val.data.projStartDate >= `${ano}-12-01` && val.data.projStartDate <= `${ano}-12-31` && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
    
        // JaneiroCl2
        const pReaisJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJaneiroCl2L = pReaisJaneiroCl2.length
          var pReaisJaneiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJaneiroCl2L; r1++)
          pReaisJaneiroCl2Numeros.push(Number(pReaisJaneiroCl2[r1]))
          const pReaisJaneiroCl2Total1 = pReaisJaneiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJaneiroCl2L2 = pReaisJaneiroCl22.length
          var pReaisJaneiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJaneiroCl2L2; r2++)
          pReaisJaneiroCl2Numeros2.push(Number(pReaisJaneiroCl22[r2]))
          const pReaisJaneiroCl2Total2 = pReaisJaneiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJaneiroCl2 = (pReaisJaneiroCl2Total1+pReaisJaneiroCl2Total2)
      
          const pDolarJaneiroCl2 = NumJaneiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJaneiroCl2L = pDolarJaneiroCl2.length
          var pDolarJanrioNumeros = [];
          for (var d1 = 0; d1 < pDolarJaneiroCl2L; d1++)
          pDolarJanrioNumeros.push(Number(pDolarJaneiroCl2[d1]))
          const pDolarJaneiroCl2Total1 = pDolarJanrioNumeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJaneiroCl22 = NumJaneiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJaneiroCl2L2 = pDolarJaneiroCl22.length
          var pDolarJanrioNumeros2 = [];
          for (var d2 = 0; d2 < pDolarJaneiroCl2L2; d2++)
          pDolarJanrioNumeros2.push(Number(pDolarJaneiroCl22[d2]))
          const pDolarJaneiroCl2Total2 = pDolarJanrioNumeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJaneiroCl2 = (pDolarJaneiroCl2Total1+pDolarJaneiroCl2Total2)
      
      
      
          //FevereiroCl2  
          const pReaisFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisFevereiroCl2L = pReaisFevereiroCl2.length
          var pReaisFevereiroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisFevereiroCl2L; r1++)
          pReaisFevereiroCl2Numeros.push(Number(pReaisFevereiroCl2[r1]))
          const pReaisFevereiroCl2Total1 = pReaisFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisFevereiroCl2L2 = pReaisFevereiroCl22.length
          var pReaisFevereiroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisFevereiroCl2L2; r2++)
          pReaisFevereiroCl2Numeros2.push(Number(pReaisFevereiroCl22[r2]))
          const pReaisFevereiroCl2Total2 = pReaisFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisFevereiroCl2 = (pReaisFevereiroCl2Total1+pReaisFevereiroCl2Total2)
      
          const pDolarFevereiroCl2 = NumFevereiroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarFevereiroCl2L = pDolarFevereiroCl2.length
          var pDolarFevereiroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarFevereiroCl2L; d1++)
          pDolarFevereiroCl2Numeros.push(Number(pDolarFevereiroCl2[d1]))
          const pDolarFevereiroCl2Total1 = pDolarFevereiroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarFevereiroCl22 = NumFevereiroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarFevereiroCl2L2 = pDolarFevereiroCl22.length
          var pDolarFevereiroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarFevereiroCl2L2; d2++)
          pDolarFevereiroCl2Numeros2.push(Number(pDolarFevereiroCl22[d2]))
          const pDolarFevereiroCl2Total2 = pDolarFevereiroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarFevereiroCl2 = (pDolarFevereiroCl2Total1+pDolarFevereiroCl2Total2)
          
          //MarcoCl2
          const pReaisMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMarcoCl2L = pReaisMarcoCl2.length
          var pReaisMarcoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMarcoCl2L; r1++)
          pReaisMarcoCl2Numeros.push(Number(pReaisMarcoCl2[r1]))
          const pReaisMarcoCl2Total1 = pReaisMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMarcoCl2L2 = pReaisMarcoCl22.length
          var pReaisMarcoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMarcoCl2L2; r2++)
          pReaisMarcoCl2Numeros2.push(Number(pReaisMarcoCl22[r2]))
          const pReaisMarcoCl2Total2 = pReaisMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMarcoCl2 = (pReaisMarcoCl2Total1+pReaisMarcoCl2Total2)
      
          const pDolarMarcoCl2 = NumMarcoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMarcoCl2L = pDolarMarcoCl2.length
          var pDolarMarcoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMarcoCl2L; d1++)
          pDolarMarcoCl2Numeros.push(Number(pDolarMarcoCl2[d1]))
          const pDolarMarcoCl2Total1 = pDolarMarcoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMarcoCl22 = NumMarcoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMarcoCl2L2 = pDolarMarcoCl22.length
          var pDolarMarcoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMarcoCl2L2; d2++)
          pDolarMarcoCl2Numeros2.push(Number(pDolarMarcoCl22[d2]))
          const pDolarMarcoCl2Total2 = pDolarMarcoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMarcoCl2 = (pDolarMarcoCl2Total1+pDolarMarcoCl2Total2)
      
          ///AbrilCl2
          const pReaisAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAbrilCl2L = pReaisAbrilCl2.length
          var pReaisAbrilCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAbrilCl2L; r1++)
          pReaisAbrilCl2Numeros.push(Number(pReaisAbrilCl2[r1]))
          const pReaisAbrilCl2Total1 = pReaisAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAbrilCl2L2 = pReaisAbrilCl22.length
          var pReaisAbrilCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAbrilCl2L2; r2++)
          pReaisAbrilCl2Numeros2.push(Number(pReaisAbrilCl22[r2]))
          const pReaisAbrilCl2Total2 = pReaisAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAbrilCl2 = (pReaisAbrilCl2Total1+pReaisAbrilCl2Total2)
      
          const pDolarAbrilCl2 = NumAbrilCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAbrilCl2L = pDolarAbrilCl2.length
          var pDolarAbrilCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAbrilCl2L; d1++)
          pDolarAbrilCl2Numeros.push(Number(pDolarAbrilCl2[d1]))
          const pDolarAbrilCl2Total1 = pDolarAbrilCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAbrilCl22 = NumAbrilCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAbrilCl2L2 = pDolarAbrilCl22.length
          var pDolarAbrilCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAbrilCl2L2; d2++)
          pDolarAbrilCl2Numeros2.push(Number(pDolarAbrilCl22[d2]))
          const pDolarAbrilCl2Total2 = pDolarAbrilCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAbrilCl2 = (pDolarAbrilCl2Total1+pDolarAbrilCl2Total2)
          //MaioCl2
          const pReaisMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisMaioCl2L = pReaisMaioCl2.length
          var pReaisMaioCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisMaioCl2L; r1++)
          pReaisMaioCl2Numeros.push(Number(pReaisMaioCl2[r1]))
          const pReaisMaioCl2Total1 = pReaisMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisMaioCl2L2 = pReaisMaioCl22.length
          var pReaisMaioCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisMaioCl2L2; r2++)
          pReaisMaioCl2Numeros2.push(Number(pReaisMaioCl22[r2]))
          const pReaisMaioCl2Total2 = pReaisMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisMaioCl2 = (pReaisMaioCl2Total1+pReaisMaioCl2Total2)
      
          const pDolarMaioCl2 = NumMaioCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarMaioCl2L = pDolarMaioCl2.length
          var pDolarMaioCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarMaioCl2L; d1++)
          pDolarMaioCl2Numeros.push(Number(pDolarMaioCl2[d1]))
          const pDolarMaioCl2Total1 = pDolarMaioCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarMaioCl22 = NumMaioCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarMaioCl2L2 = pDolarMaioCl22.length
          var pDolarMaioCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarMaioCl2L2; d2++)
          pDolarMaioCl2Numeros2.push(Number(pDolarMaioCl22[d2]))
          const pDolarMaioCl2Total2 = pDolarMaioCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarMaioCl2 = (pDolarMaioCl2Total1+pDolarMaioCl2Total2)
      
          //JunhoCl2
          const pReaisJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJunhoCl2L = pReaisJunhoCl2.length
          var pReaisJunhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJunhoCl2L; r1++)
          pReaisJunhoCl2Numeros.push(Number(pReaisJunhoCl2[r1]))
          const pReaisJunhoCl2Total1 = pReaisJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJunhoCl2L2 = pReaisJunhoCl22.length
          var pReaisJunhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJunhoCl2L2; r2++)
          pReaisJunhoCl2Numeros2.push(Number(pReaisJunhoCl22[r2]))
          const pReaisJunhoCl2Total2 = pReaisJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJunhoCl2 = (pReaisJunhoCl2Total1+pReaisJunhoCl2Total2)
      
          const pDolarJunhoCl2 = NumJunhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJunhoCl2L = pDolarJunhoCl2.length
          var pDolarJunhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJunhoCl2L; d1++)
          pDolarJunhoCl2Numeros.push(Number(pDolarJunhoCl2[d1]))
          const pDolarJunhoCl2Total1 = pDolarJunhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJunhoCl22 = NumJunhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJunhoCl2L2 = pDolarJunhoCl22.length
          var pDolarJunhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJunhoCl2L2; d2++)
          pDolarJunhoCl2Numeros2.push(Number(pDolarJunhoCl22[d2]))
          const pDolarJunhoCl2Total2 = pDolarJunhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJunhoCl2 = (pDolarJunhoCl2Total1+pDolarJunhoCl2Total2)
          
          //JulhoCl2
          const pReaisJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisJulhoCl2L = pReaisJulhoCl2.length
          var pReaisJulhoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisJulhoCl2L; r1++)
          pReaisJulhoCl2Numeros.push(Number(pReaisJulhoCl2[r1]))
          const pReaisJulhoCl2Total1 = pReaisJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisJulhoCl2L2 = pReaisJulhoCl22.length
          var pReaisJulhoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisJulhoCl2L2; r2++)
          pReaisJulhoCl2Numeros2.push(Number(pReaisJulhoCl22[r2]))
          const pReaisJulhoCl2Total2 = pReaisJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisJulhoCl2 = (pReaisJulhoCl2Total1+pReaisJulhoCl2Total2)
      
          const pDolarJulhoCl2 = NumJulhoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarJulhoCl2L = pDolarJulhoCl2.length
          var pDolaroJulhoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarJulhoCl2L; d1++)
          pDolaroJulhoCl2Numeros.push(Number(pDolarJulhoCl2[d1]))
          const pDolarJulhoCl2Total1 = pDolaroJulhoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarJulhoCl22 = NumJulhoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarJulhoCl2L2 = pDolarJulhoCl22.length
          var pDolaroJulhoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarJulhoCl2L2; d2++)
          pDolaroJulhoCl2Numeros2.push(Number(pDolarJulhoCl22[d2]))
          const pDolarJulhoCl2Total2 = pDolaroJulhoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarJulhoCl2 = (pDolarJulhoCl2Total1+pDolarJulhoCl2Total2)
      
          //AgostoCl2
          const pReaisAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisAgostoCl2L = pReaisAgostoCl2.length
          var pReaisAgostoCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisAgostoCl2L; r1++)
          pReaisAgostoCl2Numeros.push(Number(pReaisAgostoCl2[r1]))
          const pReaisAgostoCl2Total1 = pReaisAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisAgostoCl2L2 = pReaisAgostoCl22.length
          var pReaisAgostoCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisAgostoCl2L2; r2++)
          pReaisAgostoCl2Numeros2.push(Number(pReaisAgostoCl22[r2]))
          const pReaisAgostoCl2Total2 = pReaisAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisAgostoCl2 = (pReaisAgostoCl2Total1+pReaisAgostoCl2Total2)
      
          const pDolarAgostoCl2 = NumAgostoCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarAgostoCl2L = pDolarAgostoCl2.length
          var pDolarAgostoCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarAgostoCl2L; d1++)
          pDolarAgostoCl2Numeros.push(Number(pDolarAgostoCl2[d1]))
          const pDolarAgostoCl2Total1 = pDolarAgostoCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarAgostoCl22 = NumAgostoCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarAgostoCl2L2 = pDolarAgostoCl22.length
          var pDolarAgostoCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarAgostoCl2L2; d2++)
          pDolarAgostoCl2Numeros2.push(Number(pDolarAgostoCl22[d2]))
          const pDolarAgostoCl2Total2 = pDolarAgostoCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarAgostoCl2 = (pDolarAgostoCl2Total1+pDolarAgostoCl2Total2)
          
          //SetembroCl2
          const pReaisSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisSetembroCl2L = pReaisSetembroCl2.length
          var pReaisSetembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisSetembroCl2L; r1++)
          pReaisSetembroCl2Numeros.push(Number(pReaisSetembroCl2[r1]))
          const pReaisSetembroCl2Total1 = pReaisSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisSetembroCl2L2 = pReaisSetembroCl22.length
          var pReaisSetembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisSetembroCl2L2; r2++)
          pReaisSetembroCl2Numeros2.push(Number(pReaisSetembroCl22[r2]))
          const pReaisSetembroCl2Total2 = pReaisSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisSetembroCl2 = (pReaisSetembroCl2Total1+pReaisSetembroCl2Total2)
          
      
          const pDolarSetembroCl2 = NumSetembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarSetembroCl2L = pDolarSetembroCl2.length
          var pDolarSetembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarSetembroCl2L; d1++)
          pDolarSetembroCl2Numeros.push(Number(pDolarSetembroCl2[d1]))
          const pDolarSetembroCl2Total1 = pDolarSetembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarSetembroCl22 = NumSetembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarSetembroCl2L2 = pDolarSetembroCl22.length
          var pDolarSetembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarSetembroCl2L2; d2++)
          pDolarSetembroCl2Numeros2.push(Number(pDolarSetembroCl22[d2]))
          const pDolarSetembroCl2Total2 = pDolarSetembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarSetembroCl2 = (pDolarSetembroCl2Total1+pDolarSetembroCl2Total2)
          
          //OutubroCl2
          const pReaisOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisOutubroCl2L = pReaisOutubroCl2.length
          var pReaisOutubroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisOutubroCl2L; r1++)
          pReaisOutubroCl2Numeros.push(Number(pReaisOutubroCl2[r1]))
          const pReaisOutubroCl2Total1 = pReaisOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisOutubroCl2L2 = pReaisOutubroCl22.length
          var pReaisOutubroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisOutubroCl2L2; r2++)
          pReaisOutubroCl2Numeros2.push(Number(pReaisOutubroCl22[r2]))
          const pReaisOutubroCl2Total2 = pReaisOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisOutubroCl2 = (pReaisOutubroCl2Total1+pReaisOutubroCl2Total2)
      
          const pDolarOutubroCl2 = NumOutubroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarOutubroCl2L = pDolarOutubroCl2.length
          var pDolarOutubroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarOutubroCl2L; d1++)
          pDolarOutubroCl2Numeros.push(Number(pDolarOutubroCl2[d1]))
          const pDolarOutubroCl2Total1 = pDolarOutubroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarOutubroCl22 = NumOutubroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarOutubroCl2L2 = pDolarOutubroCl22.length
          var pDolarOutubroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarOutubroCl2L2; d2++)
          pDolarOutubroCl2Numeros2.push(Number(pDolarOutubroCl22[d2]))
          const pDolarOutubroCl2Total2 = pDolarOutubroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarOutubroCl2 = (pDolarOutubroCl2Total1+pDolarOutubroCl2Total2)
      
          //NovembroCl2
          const pReaisNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisNovembroCl2L = pReaisNovembroCl2.length
          var pReaisNovembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisNovembroCl2L; r1++)
          pReaisNovembroCl2Numeros.push(Number(pReaisNovembroCl2[r1]))
          const pReaisNovembroCl2Total1 = pReaisNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisNovembroCl2L2 = pReaisNovembroCl22.length
          var pReaisNovembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisNovembroCl2L2; r2++)
          pReaisNovembroCl2Numeros2.push(Number(pReaisNovembroCl22[r2]))
          const pReaisNovembroCl2Total2 = pReaisNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisNovembroCl2 = (pReaisNovembroCl2Total1+pReaisNovembroCl2Total2)
      
          const pDolarNovembroCl2 = NumNovembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarNovembroCl2L = pDolarNovembroCl2.length
          var pDolarNovembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarNovembroCl2L; d1++)
          pDolarNovembroCl2Numeros.push(Number(pDolarNovembroCl2[d1]))
          const pDolarNovembroCl2Total1 = pDolarNovembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarNovembroCl22 = NumNovembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarNovembroCl2L2 = pDolarNovembroCl22.length
          var pDolarNovembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarNovembroCl2L2; d2++)
          pDolarNovembroCl2Numeros2.push(Number(pDolarNovembroCl22[d2]))
          const pDolarNovembroCl2Total2 = pDolarNovembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarNovembroCl2 = (pDolarNovembroCl2Total1+pDolarNovembroCl2Total2)
        
      
          //DezembroCl2
          const pReaisDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pReaisDezembroCl2L = pReaisDezembroCl2.length
          var pReaisDezembroCl2Numeros = [];
          for (var r1 = 0; r1 < pReaisDezembroCl2L; r1++)
          pReaisDezembroCl2Numeros.push(Number(pReaisDezembroCl2[r1]))
          const pReaisDezembroCl2Total1 = pReaisDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pReaisDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pReaisDezembroCl2L2 = pReaisDezembroCl22.length
          var pReaisDezembroCl2Numeros2 = [];
          for (var r2 = 0; r2 < pReaisDezembroCl2L2; r2++)
          pReaisDezembroCl2Numeros2.push(Number(pReaisDezembroCl22[r2]))
          const pReaisDezembroCl2Total2 = pReaisDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const ReaisDezembroCl2 = (pReaisDezembroCl2Total1+pReaisDezembroCl2Total2)
      
          const pDolarDezembroCl2 = NumDezembroCl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
          var pDolarDezembroCl2L = pDolarDezembroCl2.length
          var pDolarDezembroCl2Numeros = [];
          for (var d1 = 0; d1 < pDolarDezembroCl2L; d1++)
          pDolarDezembroCl2Numeros.push(Number(pDolarDezembroCl2[d1]))
          const pDolarDezembroCl2Total1 = pDolarDezembroCl2Numeros.reduce((acc, curr) => acc + curr, 0)
              
          const pDolarDezembroCl22 = NumDezembroCl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
          var pDolarDezembroCl2L2 = pDolarDezembroCl22.length
          var pDolarDezembroCl2Numeros2 = [];
          for (var d2 = 0; d2 < pDolarDezembroCl2L2; d2++)
          pDolarDezembroCl2Numeros2.push(Number(pDolarDezembroCl22[d2]))
          const pDolarDezembroCl2Total2 = pDolarDezembroCl2Numeros2.reduce((acc, curr) => acc + curr, 0)
      
          const DolarDezembroCl2 = (pDolarDezembroCl2Total1+pDolarDezembroCl2Total2)
    
    
  
    setProjetData({
      labels: meses,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [ReaisJaneiro, ReaisFevereiro, ReaisMarco, ReaisAbril, ReaisMaio, ReaisJunho, ReaisJulho, ReaisAgosto, ReaisSetembro, ReaisOutubro, ReaisNovembro, ReaisDezembro ],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data:[DolarJaneiro, DolarFevereiro, DolarMarco, DolarAbril, DolarMaio, DolarJunho, DolarJulho, DolarAgosto, DolarSetembro, DolarOutubro, DolarNovembro, DolarDezembro ],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },{
        label:`Faturamento Reais - ${cliente2}`,
        data: [ReaisJaneiroCl2, ReaisFevereiroCl2, ReaisMarcoCl2, ReaisAbrilCl2, ReaisMaioCl2, ReaisJunhoCl2, ReaisJulhoCl2, ReaisAgostoCl2, ReaisSetembroCl2, ReaisOutubroCl2, ReaisNovembroCl2, ReaisDezembroCl2 ],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data:  [DolarJaneiroCl2, DolarFevereiroCl2, DolarMarcoCl2, DolarAbrilCl2, DolarMaioCl2, DolarJunhoCl2, DolarJulhoCl2, DolarAgostoCl2, DolarSetembroCl2, DolarOutubroCl2, DolarNovembroCl2, DolarDezembroCl2 ],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
   
  }
  
  const handleChart3 =()=>{
    setGrafico(false)
    const anos = ['2002','2003','2004','2005', '2006', '2007', '2008', '2009','2010', '2011', '2012', '2013', '2014','2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024' ];
  
    
    //2002 reais
    const projs2002 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    const projs20021R = projs2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021RLenght = projs20021R.length
    var projs20021RNumeros = [];
    for (var r1 = 0; r1 < projs20021RLenght; r1++)
    projs20021RNumeros.push(Number(projs20021R[r1]))
    const projs2002Reais1 = projs20021RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022R = projs2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022RLenght = projs20022R.length
    var projs20022RNumeros = [];
    for (var r2 = 0; r2 < projs20022RLenght; r2++)
    projs20022RNumeros.push(Number(projs20022R[r2]))
    const projs2002Reais2 = projs20022RNumeros.reduce((acc, curr) => acc + curr, 0)

    const Reais2002 = (projs2002Reais1+projs2002Reais2)

    //2002 dolar
    const projs20021D = projs2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20021DLenght = projs20021D.length
    var projs20021DNumeros = [];
    for (var d1 = 0; d1 < projs20021DLenght; d1++)
    projs20021DNumeros.push(Number(projs20021D[d1]))
    const projs2002Dolar1 = projs20021DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20022D = projs2002.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20022DLenght = projs20022D.length
    var projs20022DNumeros = [];
    for (var d2 = 0; d2 < projs20022DLenght; d2++)
    projs20022DNumeros.push(Number(projs20022D[d2]))
    const projs2002Dolar2 = projs20022DNumeros.reduce((acc, curr) => acc + curr, 0)

    const Dolar2002 = (projs2002Dolar1+projs2002Dolar2)

     //2003 reais
    const projs2003 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    const projs20031R = projs2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031RLenght = projs20031R.length
    var projs20031RNumeros = [];
    for (var r1 = 0; r1 < projs20031RLenght; r1++)
    projs20031RNumeros.push(Number(projs20031R[r1]))
    const projs2003Reais1 = projs20031RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032R = projs2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032RLenght = projs20032R.length
    var projs20032RNumeros = [];
    for (var r2 = 0; r2 < projs20032RLenght; r2++)
    projs20032RNumeros.push(Number(projs20032R[r2]))
    const projs2003Reais2 = projs20032RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2003 = (projs2003Reais1+projs2003Reais2)
    
    //2003 dolar
    const projs20031D = projs2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20031DLenght = projs20031D.length
    var projs20031DNumeros = [];
    for (var d1 = 0; d1 < projs20031DLenght; d1++)
    projs20031DNumeros.push(Number(projs20031D[d1]))
    const projs2003Dolar1 = projs20031DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20032D = projs2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20032DLenght = projs20032D.length
    var projs20032DNumeros = [];
    for (var d2 = 0; d2 < projs20032DLenght; d2++)
    projs20032DNumeros.push(Number(projs20032D[d2]))
    const projs2003Dolar2 = projs20032DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2003 = (projs2003Dolar1+projs2003Dolar2)

    //2004 reais
    const projs2004 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    const projs20041R = projs2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041RLenght = projs20041R.length
    var projs20041RNumeros = [];
    for (var r1 = 0; r1 < projs20041RLenght; r1++)
    projs20041RNumeros.push(Number(projs20041R[r1]))
    const projs2004Reais1 = projs20041RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042R = projs2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042RLenght = projs20042R.length
    var projs20042RNumeros = [];
    for (var r2 = 0; r2 < projs20042RLenght; r2++)
    projs20042RNumeros.push(Number(projs20042R[r2]))
    const projs2004Reais2 = projs20042RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2004 = (projs2004Reais1+projs2004Reais2)
    
    //2004 dolar
    const projs20041D = projs2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20041DLenght = projs20041D.length
    var projs20041DNumeros = [];
    for (var d1 = 0; d1 < projs20041DLenght; d1++)
    projs20041DNumeros.push(Number(projs20041D[d1]))
    const projs2004Dolar1 = projs20041DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20042D = projs2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20042DLenght = projs20042D.length
    var projs20042DNumeros = [];
    for (var d2 = 0; d2 < projs20042DLenght; d2++)
    projs20042DNumeros.push(Number(projs20042D[d2]))
    const projs2004Dolar2 = projs20042DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2004 = (projs2004Dolar1+projs2004Dolar2)

    //2005 reais
    const projs2005 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    const projs20051R = projs2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051RLenght = projs20051R.length
    var projs20051RNumeros = [];
    for (var r1 = 0; r1 < projs20051RLenght; r1++)
    projs20051RNumeros.push(Number(projs20051R[r1]))
    const projs2005Reais1 = projs20051RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052R = projs2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052RLenght = projs20052R.length
    var projs20052RNumeros = [];
    for (var r2 = 0; r2 < projs20052RLenght; r2++)
    projs20052RNumeros.push(Number(projs20052R[r2]))
    const projs2005Reais2 = projs20052RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2005 = (projs2005Reais1+projs2005Reais2)
    
    //2005 dolar
    const projs20051D = projs2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20051DLenght = projs20051D.length
    var projs20051DNumeros = [];
    for (var d1 = 0; d1 < projs20051DLenght; d1++)
    projs20051DNumeros.push(Number(projs20051D[d1]))
    const projs2005Dolar1 = projs20051DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20052D = projs2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20052DLenght = projs20052D.length
    var projs20052DNumeros = [];
    for (var d2 = 0; d2 < projs20052DLenght; d2++)
    projs20052DNumeros.push(Number(projs20052D[d2]))
    const projs2005Dolar2 = projs20052DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2005 = (projs2005Dolar1+projs2005Dolar2)

    //2006 reais
    const projs2006 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1) ){return val}})
    const projs20061R = projs2006.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061RLenght = projs20061R.length
    var projs20061RNumeros = [];
    for (var r1 = 0; r1 < projs20061RLenght; r1++)
    projs20061RNumeros.push(Number(projs20061R[r1]))
    const projs2006Reais1 = projs20061RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062R = projs2006.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062RLenght = projs20062R.length
    var projs20062RNumeros = [];
    for (var r2 = 0; r2 < projs20062RLenght; r2++)
    projs20062RNumeros.push(Number(projs20062R[r2]))
    const projs2006Reais2 = projs20062RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2006 = (projs2006Reais1+projs2006Reais2)
    
    //2006 dolar
    const projs20061D = projs2006.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20061DLenght = projs20061D.length
    var projs20061DNumeros = [];
    for (var d1 = 0; d1 < projs20061DLenght; d1++)
    projs20061DNumeros.push(Number(projs20061D[d1]))
    const projs2006Dolar1 = projs20061DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20062D = projs2006.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20062DLenght = projs20062D.length
    var projs20062DNumeros = [];
    for (var d2 = 0; d2 < projs20062DLenght; d2++)
    projs20062DNumeros.push(Number(projs20062D[d2]))
    const projs2006Dolar2 = projs20062DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2006 = (projs2006Dolar1+projs2006Dolar2)

    //2007 reais
    const projs2007 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20071R = projs2007.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071RLenght = projs20071R.length
    var projs20071RNumeros = [];
    for (var r1 = 0; r1 < projs20071RLenght; r1++)
    projs20071RNumeros.push(Number(projs20071R[r1]))
    const projs2007Reais1 = projs20071RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072R = projs2007.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072RLenght = projs20072R.length
    var projs20072RNumeros = [];
    for (var r2 = 0; r2 < projs20072RLenght; r2++)
    projs20072RNumeros.push(Number(projs20072R[r2]))
    const projs2007Reais2 = projs20072RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2007 = (projs2007Reais1+projs2007Reais2)
    
    //2007 dolar
    const projs20071D = projs2007.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20071DLenght = projs20071D.length
    var projs20071DNumeros = [];
    for (var d1 = 0; d1 < projs20071DLenght; d1++)
    projs20071DNumeros.push(Number(projs20071D[d1]))
    const projs2007Dolar1 = projs20071DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20072D = projs2007.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20072DLenght = projs20072D.length
    var projs20072DNumeros = [];
    for (var d2 = 0; d2 < projs20072DLenght; d2++)
    projs20072DNumeros.push(Number(projs20072D[d2]))
    const projs2007Dolar2 = projs20072DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2007 = (projs2007Dolar1+projs2007Dolar2)

    //2008 reais
    const projs2008 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20081R = projs2008.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081RLenght = projs20081R.length
    var projs20081RNumeros = [];
    for (var r1 = 0; r1 < projs20081RLenght; r1++)
    projs20081RNumeros.push(Number(projs20081R[r1]))
    const projs2008Reais1 = projs20081RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082R = projs2008.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082RLenght = projs20082R.length
    var projs20082RNumeros = [];
    for (var r2 = 0; r2 < projs20082RLenght; r2++)
    projs20082RNumeros.push(Number(projs20082R[r2]))
    const projs2008Reais2 = projs20082RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2008 = (projs2008Reais1+projs2008Reais2)
    
    //2008 dolar
    const projs20081D = projs2008.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20081DLenght = projs20081D.length
    var projs20081DNumeros = [];
    for (var d1 = 0; d1 < projs20081DLenght; d1++)
    projs20081DNumeros.push(Number(projs20081D[d1]))
    const projs2008Dolar1 = projs20081DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20082D = projs2008.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20082DLenght = projs20082D.length
    var projs20082DNumeros = [];
    for (var d2 = 0; d2 < projs20082DLenght; d2++)
    projs20082DNumeros.push(Number(projs20082D[d2]))
    const projs2008Dolar2 = projs20082DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2008 = (projs2008Dolar1+projs2008Dolar2)

    //2009 reais
    const projs2009 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20091R = projs2009.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091RLenght = projs20091R.length
    var projs20091RNumeros = [];
    for (var r1 = 0; r1 < projs20091RLenght; r1++)
    projs20091RNumeros.push(Number(projs20091R[r1]))
    const projs2009Reais1 = projs20091RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092R = projs2009.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092RLenght = projs20092R.length
    var projs20092RNumeros = [];
    for (var r2 = 0; r2 < projs20092RLenght; r2++)
    projs20092RNumeros.push(Number(projs20092R[r2]))
    const projs2009Reais2 = projs20092RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2009 = (projs2009Reais1+projs2009Reais2)
    
    //2009 dolar
    const projs20091D = projs2009.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20091DLenght = projs20091D.length
    var projs20091DNumeros = [];
    for (var d1 = 0; d1 < projs20091DLenght; d1++)
    projs20091DNumeros.push(Number(projs20091D[d1]))
    const projs2009Dolar1 = projs20091DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20092D = projs2009.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20092DLenght = projs20092D.length
    var projs20092DNumeros = [];
    for (var d2 = 0; d2 < projs20092DLenght; d2++)
    projs20092DNumeros.push(Number(projs20092D[d2]))
    const projs2009Dolar2 = projs20092DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2009 = (projs2009Dolar1+projs2009Dolar2)

    //2010 reais
    const projs2010 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20101R = projs2010.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101RLenght = projs20101R.length
    var projs20101RNumeros = [];
    for (var r1 = 0; r1 < projs20101RLenght; r1++)
    projs20101RNumeros.push(Number(projs20101R[r1]))
    const projs2010Reais1 = projs20101RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102R = projs2010.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102RLenght = projs20102R.length
    var projs20102RNumeros = [];
    for (var r2 = 0; r2 < projs20102RLenght; r2++)
    projs20102RNumeros.push(Number(projs20102R[r2]))
    const projs2010Reais2 = projs20102RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2010 = (projs2010Reais1+projs2010Reais2)
    
    //2010 dolar
    const projs20101D = projs2010.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20101DLenght = projs20101D.length
    var projs20101DNumeros = [];
    for (var d1 = 0; d1 < projs20101DLenght; d1++)
    projs20101DNumeros.push(Number(projs20101D[d1]))
    const projs2010Dolar1 = projs20101DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20102D = projs2010.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20102DLenght = projs20102D.length
    var projs20102DNumeros = [];
    for (var d2 = 0; d2 < projs20102DLenght; d2++)
    projs20102DNumeros.push(Number(projs20102D[d2]))
    const projs2010Dolar2 = projs20102DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2010 = (projs2010Dolar1+projs2010Dolar2)

    //2011 reais
    const projs2011 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20111R = projs2011.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111RLenght = projs20111R.length
    var projs20111RNumeros = [];
    for (var r1 = 0; r1 < projs20111RLenght; r1++)
    projs20111RNumeros.push(Number(projs20111R[r1]))
    const projs2011Reais1 = projs20111RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112R = projs2011.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112RLenght = projs20112R.length
    var projs20112RNumeros = [];
    for (var r2 = 0; r2 < projs20112RLenght; r2++)
    projs20112RNumeros.push(Number(projs20112R[r2]))
    const projs2011Reais2 = projs20112RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2011 = (projs2011Reais1+projs2011Reais2)
    
    //2011 dolar
    const projs20111D = projs2011.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20111DLenght = projs20111D.length
    var projs20111DNumeros = [];
    for (var d1 = 0; d1 < projs20111DLenght; d1++)
    projs20111DNumeros.push(Number(projs20111D[d1]))
    const projs2011Dolar1 = projs20111DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20112D = projs2011.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20112DLenght = projs20112D.length
    var projs20112DNumeros = [];
    for (var d2 = 0; d2 < projs20112DLenght; d2++)
    projs20112DNumeros.push(Number(projs20112D[d2]))
    const projs2011Dolar2 = projs20112DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2011 = (projs2011Dolar1+projs2011Dolar2)

    //2012 reais
    const projs2012 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20121R = projs2012.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121RLenght = projs20121R.length
    var projs20121RNumeros = [];
    for (var r1 = 0; r1 < projs20121RLenght; r1++)
    projs20121RNumeros.push(Number(projs20121R[r1]))
    const projs2012Reais1 = projs20121RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122R = projs2012.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122RLenght = projs20122R.length
    var projs20122RNumeros = [];
    for (var r2 = 0; r2 < projs20122RLenght; r2++)
    projs20122RNumeros.push(Number(projs20122R[r2]))
    const projs2012Reais2 = projs20122RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2012 = (projs2012Reais1+projs2012Reais2)
    
    //2012 dolar
    const projs20121D = projs2012.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20121DLenght = projs20121D.length
    var projs20121DNumeros = [];
    for (var d1 = 0; d1 < projs20121DLenght; d1++)
    projs20121DNumeros.push(Number(projs20121D[d1]))
    const projs2012Dolar1 = projs20121DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20122D = projs2012.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20122DLenght = projs20122D.length
    var projs20122DNumeros = [];
    for (var d2 = 0; d2 < projs20122DLenght; d2++)
    projs20122DNumeros.push(Number(projs20122D[d2]))
    const projs2012Dolar2 = projs20122DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2012 = (projs2012Dolar1+projs2012Dolar2)
    
    //2013 reais
    const projs2013 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20131R = projs2013.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131RLenght = projs20131R.length
    var projs20131RNumeros = [];
    for (var r1 = 0; r1 < projs20131RLenght; r1++)
    projs20131RNumeros.push(Number(projs20131R[r1]))
    const projs2013Reais1 = projs20131RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132R = projs2013.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132RLenght = projs20132R.length
    var projs20132RNumeros = [];
    for (var r2 = 0; r2 < projs20132RLenght; r2++)
    projs20132RNumeros.push(Number(projs20132R[r2]))
    const projs2013Reais2 = projs20132RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2013 = (projs2013Reais1+projs2013Reais2)
    
    //2013 dolar
    const projs20131D = projs2013.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20131DLenght = projs20131D.length
    var projs20131DNumeros = [];
    for (var d1 = 0; d1 < projs20131DLenght; d1++)
    projs20131DNumeros.push(Number(projs20131D[d1]))
    const projs2013Dolar1 = projs20131DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20132D = projs2013.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20132DLenght = projs20132D.length
    var projs20132DNumeros = [];
    for (var d2 = 0; d2 < projs20132DLenght; d2++)
    projs20132DNumeros.push(Number(projs20132D[d2]))
    const projs2013Dolar2 = projs20132DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2013 = (projs2013Dolar1+projs2013Dolar2)
    
    //2014 reais
    const projs2014 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20141R = projs2014.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141RLenght = projs20141R.length
    var projs20141RNumeros = [];
    for (var r1 = 0; r1 < projs20141RLenght; r1++)
    projs20141RNumeros.push(Number(projs20141R[r1]))
    const projs2014Reais1 = projs20141RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142R = projs2014.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142RLenght = projs20142R.length
    var projs20142RNumeros = [];
    for (var r2 = 0; r2 < projs20142RLenght; r2++)
    projs20142RNumeros.push(Number(projs20142R[r2]))
    const projs2014Reais2 = projs20142RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2014 = (projs2014Reais1+projs2014Reais2)
    
    //2014 dolar
    const projs20141D = projs2014.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20141DLenght = projs20141D.length
    var projs20141DNumeros = [];
    for (var d1 = 0; d1 < projs20141DLenght; d1++)
    projs20141DNumeros.push(Number(projs20141D[d1]))
    const projs2014Dolar1 = projs20141DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20142D = projs2014.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20142DLenght = projs20142D.length
    var projs20142DNumeros = [];
    for (var d2 = 0; d2 < projs20142DLenght; d2++)
    projs20142DNumeros.push(Number(projs20142D[d2]))
    const projs2014Dolar2 = projs20142DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2014 = (projs2014Dolar1+projs2014Dolar2)
    
    
    //2015 reais
    const projs2015 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20151R = projs2015.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151RLenght = projs20151R.length
    var projs20151RNumeros = [];
    for (var r1 = 0; r1 < projs20151RLenght; r1++)
    projs20151RNumeros.push(Number(projs20151R[r1]))
    const projs2015Reais1 = projs20151RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152R = projs2015.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152RLenght = projs20152R.length
    var projs20152RNumeros = [];
    for (var r2 = 0; r2 < projs20152RLenght; r2++)
    projs20152RNumeros.push(Number(projs20152R[r2]))
    const projs2015Reais2 = projs20152RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2015 = (projs2015Reais1+projs2015Reais2)
    
    //2015 dolar
    const projs20151D = projs2015.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20151DLenght = projs20151D.length
    var projs20151DNumeros = [];
    for (var d1 = 0; d1 < projs20151DLenght; d1++)
    projs20151DNumeros.push(Number(projs20151D[d1]))
    const projs2015Dolar1 = projs20151DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20152D = projs2015.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20152DLenght = projs20152D.length
    var projs20152DNumeros = [];
    for (var d2 = 0; d2 < projs20152DLenght; d2++)
    projs20152DNumeros.push(Number(projs20152D[d2]))
    const projs2015Dolar2 = projs20152DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2015 = (projs2015Dolar1+projs2015Dolar2)
    
    //2016 reais
    const projs2016 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20161R = projs2016.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161RLenght = projs20161R.length
    var projs20161RNumeros = [];
    for (var r1 = 0; r1 < projs20161RLenght; r1++)
    projs20161RNumeros.push(Number(projs20161R[r1]))
    const projs2016Reais1 = projs20161RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162R = projs2016.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162RLenght = projs20162R.length
    var projs20162RNumeros = [];
    for (var r2 = 0; r2 < projs20162RLenght; r2++)
    projs20162RNumeros.push(Number(projs20162R[r2]))
    const projs2016Reais2 = projs20162RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2016 = (projs2016Reais1+projs2016Reais2)
    
    //2016 dolar
    const projs20161D = projs2016.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20161DLenght = projs20161D.length
    var projs20161DNumeros = [];
    for (var d1 = 0; d1 < projs20161DLenght; d1++)
    projs20161DNumeros.push(Number(projs20161D[d1]))
    const projs2016Dolar1 = projs20161DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20162D = projs2016.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20162DLenght = projs20162D.length
    var projs20162DNumeros = [];
    for (var d2 = 0; d2 < projs20162DLenght; d2++)
    projs20162DNumeros.push(Number(projs20162D[d2]))
    const projs2016Dolar2 = projs20162DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2016 = (projs2016Dolar1+projs2016Dolar2)
    
    //2017 reais
    const projs2017 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20171R = projs2017.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171RLenght = projs20171R.length
    var projs20171RNumeros = [];
    for (var r1 = 0; r1 < projs20171RLenght; r1++)
    projs20171RNumeros.push(Number(projs20171R[r1]))
    const projs2017Reais1 = projs20171RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172R = projs2017.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172RLenght = projs20172R.length
    var projs20172RNumeros = [];
    for (var r2 = 0; r2 < projs20172RLenght; r2++)
    projs20172RNumeros.push(Number(projs20172R[r2]))
    const projs2017Reais2 = projs20172RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2017 = (projs2017Reais1+projs2017Reais2)
    
    //2017 dolar
    const projs20171D = projs2017.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20171DLenght = projs20171D.length
    var projs20171DNumeros = [];
    for (var d1 = 0; d1 < projs20171DLenght; d1++)
    projs20171DNumeros.push(Number(projs20171D[d1]))
    const projs2017Dolar1 = projs20171DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20172D = projs2017.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20172DLenght = projs20172D.length
    var projs20172DNumeros = [];
    for (var d2 = 0; d2 < projs20172DLenght; d2++)
    projs20172DNumeros.push(Number(projs20172D[d2]))
    const projs2017Dolar2 = projs20172DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2017 = (projs2017Dolar1+projs2017Dolar2)
    
    //2018 reais
    const projs2018 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20181R = projs2018.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181RLenght = projs20181R.length
    var projs20181RNumeros = [];
    for (var r1 = 0; r1 < projs20181RLenght; r1++)
    projs20181RNumeros.push(Number(projs20181R[r1]))
    const projs2018Reais1 = projs20181RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182R = projs2018.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182RLenght = projs20182R.length
    var projs20182RNumeros = [];
    for (var r2 = 0; r2 < projs20182RLenght; r2++)
    projs20182RNumeros.push(Number(projs20182R[r2]))
    const projs2018Reais2 = projs20182RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2018 = (projs2018Reais1+projs2018Reais2)
    
    //2018 dolar
    const projs20181D = projs2018.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20181DLenght = projs20181D.length
    var projs20181DNumeros = [];
    for (var d1 = 0; d1 < projs20181DLenght; d1++)
    projs20181DNumeros.push(Number(projs20181D[d1]))
    const projs2018Dolar1 = projs20181DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20182D = projs2018.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20182DLenght = projs20182D.length
    var projs20182DNumeros = [];
    for (var d2 = 0; d2 < projs20182DLenght; d2++)
    projs20182DNumeros.push(Number(projs20182D[d2]))
    const projs2018Dolar2 = projs20182DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2018 = (projs2018Dolar1+projs2018Dolar2)
    
    //2019 reais
    const projs2019 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20191R = projs2019.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191RLenght = projs20191R.length
    var projs20191RNumeros = [];
    for (var r1 = 0; r1 < projs20191RLenght; r1++)
    projs20191RNumeros.push(Number(projs20191R[r1]))
    const projs2019Reais1 = projs20191RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192R = projs2019.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192RLenght = projs20192R.length
    var projs20192RNumeros = [];
    for (var r2 = 0; r2 < projs20192RLenght; r2++)
    projs20192RNumeros.push(Number(projs20192R[r2]))
    const projs2019Reais2 = projs20192RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2019 = (projs2019Reais1+projs2019Reais2)
    
    //2019 dolar
    const projs20191D = projs2019.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20191DLenght = projs20191D.length
    var projs20191DNumeros = [];
    for (var d1 = 0; d1 < projs20191DLenght; d1++)
    projs20191DNumeros.push(Number(projs20191D[d1]))
    const projs2019Dolar1 = projs20191DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20192D = projs2019.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20192DLenght = projs20192D.length
    var projs20192DNumeros = [];
    for (var d2 = 0; d2 < projs20192DLenght; d2++)
    projs20192DNumeros.push(Number(projs20192D[d2]))
    const projs2019Dolar2 = projs20192DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2019 = (projs2019Dolar1+projs2019Dolar2)
    
    //2020 reais
    const projs2020 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20201R = projs2020.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201RLenght = projs20201R.length
    var projs20201RNumeros = [];
    for (var r1 = 0; r1 < projs20201RLenght; r1++)
    projs20201RNumeros.push(Number(projs20201R[r1]))
    const projs2020Reais1 = projs20201RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202R = projs2020.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202RLenght = projs20202R.length
    var projs20202RNumeros = [];
    for (var r2 = 0; r2 < projs20202RLenght; r2++)
    projs20202RNumeros.push(Number(projs20202R[r2]))
    const projs2020Reais2 = projs20202RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2020 = (projs2020Reais1+projs2020Reais2)
    
    //2020 dolar
    const projs20201D = projs2020.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20201DLenght = projs20201D.length
    var projs20201DNumeros = [];
    for (var d1 = 0; d1 < projs20201DLenght; d1++)
    projs20201DNumeros.push(Number(projs20201D[d1]))
    const projs2020Dolar1 = projs20201DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20202D = projs2020.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20202DLenght = projs20202D.length
    var projs20202DNumeros = [];
    for (var d2 = 0; d2 < projs20202DLenght; d2++)
    projs20202DNumeros.push(Number(projs20202D[d2]))
    const projs2020Dolar2 = projs20202DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2020 = (projs2020Dolar1+projs2020Dolar2)
    
    //2021 reais
    const projs2021 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20211R = projs2021.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211RLenght = projs20211R.length
    var projs20211RNumeros = [];
    for (var r1 = 0; r1 < projs20211RLenght; r1++)
    projs20211RNumeros.push(Number(projs20211R[r1]))
    const projs2021Reais1 = projs20211RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212R = projs2021.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212RLenght = projs20212R.length
    var projs20212RNumeros = [];
    for (var r2 = 0; r2 < projs20212RLenght; r2++)
    projs20212RNumeros.push(Number(projs20212R[r2]))
    const projs2021Reais2 = projs20212RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2021 = (projs2021Reais1+projs2021Reais2)
    
    //2021 dolar
    const projs20211D = projs2021.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20211DLenght = projs20211D.length
    var projs20211DNumeros = [];
    for (var d1 = 0; d1 < projs20211DLenght; d1++)
    projs20211DNumeros.push(Number(projs20211D[d1]))
    const projs2021Dolar1 = projs20211DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20212D = projs2021.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20212DLenght = projs20212D.length
    var projs20212DNumeros = [];
    for (var d2 = 0; d2 < projs20212DLenght; d2++)
    projs20212DNumeros.push(Number(projs20212D[d2]))
    const projs2021Dolar2 = projs20212DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2021 = (projs2021Dolar1+projs2021Dolar2)
    
    //2022 reais
    const projs2022 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20221R = projs2022.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221RLenght = projs20221R.length
    var projs20221RNumeros = [];
    for (var r1 = 0; r1 < projs20221RLenght; r1++)
    projs20221RNumeros.push(Number(projs20221R[r1]))
    const projs2022Reais1 = projs20221RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222R = projs2022.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222RLenght = projs20222R.length
    var projs20222RNumeros = [];
    for (var r2 = 0; r2 < projs20222RLenght; r2++)
    projs20222RNumeros.push(Number(projs20222R[r2]))
    const projs2022Reais2 = projs20222RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2022 = projs2022Reais1+projs2022Reais2
    
    //2022 dolar
    const projs20221D = projs2022.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20221DLenght = projs20221D.length
    var projs20221DNumeros = [];
    for (var d1 = 0; d1 < projs20221DLenght; d1++)
    projs20221DNumeros.push(Number(projs20221D[d1]))
    const projs2022Dolar1 = projs20221DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20222D = projs2022.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20222DLenght = projs20222D.length
    var projs20222DNumeros = [];
    for (var d2 = 0; d2 < projs20222DLenght; d2++)
    projs20222DNumeros.push(Number(projs20222D[d2]))
    const projs2022Dolar2 = projs20222DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2022 = projs2022Dolar1+projs2022Dolar2
    
    //2023 reais
    const projs2023 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20231R = projs2023.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231RLenght = projs20231R.length
    var projs20231RNumeros = [];
    for (var r1 = 0; r1 < projs20231RLenght; r1++)
    projs20231RNumeros.push(Number(projs20231R[r1]))
    const projs2023Reais1 = projs20231RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232R = projs2023.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232RLenght = projs20232R.length
    var projs20232RNumeros = [];
    for (var r2 = 0; r2 < projs20232RLenght; r2++)
    projs20232RNumeros.push(Number(projs20232R[r2]))
    const projs2023Reais2 = projs20232RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2023 = projs2023Reais1+projs2023Reais2
    
    //2023 dolar
    const projs20231D = projs2023.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20231DLenght = projs20231D.length
    var projs20231DNumeros = [];
    for (var d1 = 0; d1 < projs20231DLenght; d1++)
    projs20231DNumeros.push(Number(projs20231D[d1]))
    const projs2023Dolar1 = projs20231DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20232D = projs2023.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20232DLenght = projs20232D.length
    var projs20232DNumeros = [];
    for (var d2 = 0; d2 < projs20232DLenght; d2++)
    projs20232DNumeros.push(Number(projs20232D[d2]))
    const projs2023Dolar2 = projs20232DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2023 = projs2023Dolar1+projs2023Dolar2
    
    
    //2024 reais
    const projs2024 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente1)){return val}})
    const projs20241R = projs2024.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241RLenght = projs20241R.length
    var projs20241RNumeros = [];
    for (var r1 = 0; r1 < projs20241RLenght; r1++)
    projs20241RNumeros.push(Number(projs20241R[r1]))
    const projs2024Reais1 = projs20241RNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242R = projs2024.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242RLenght = projs20242R.length
    var projs20242RNumeros = [];
    for (var r2 = 0; r2 < projs20242RLenght; r2++)
    projs20242RNumeros.push(Number(projs20242R[r2]))
    const projs2024Reais2 = projs20242RNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Reais2024 = projs2024Reais1+projs2024Reais2
    
    //2024 dolar
    const projs20241D = projs2024.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var projs20241DLenght = projs20241D.length
    var projs20241DNumeros = [];
    for (var d1 = 0; d1 < projs20241DLenght; d1++)
    projs20241DNumeros.push(Number(projs20241D[d1]))
    const projs2024Dolar1 = projs20241DNumeros.reduce((acc, curr) => acc + curr, 0)
        
    const projs20242D = projs2024.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var projs20242DLenght = projs20242D.length
    var projs20242DNumeros = [];
    for (var d2 = 0; d2 < projs20242DLenght; d2++)
    projs20242DNumeros.push(Number(projs20242D[d2]))
    const projs2024Dolar2 = projs20242DNumeros.reduce((acc, curr) => acc + curr, 0)
    
    const Dolar2024 = projs2024Dolar1+projs2024Dolar2

     //2002Cl2 reais
     const projs2002Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
        const projs2002Cl21R = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21RLenght = projs2002Cl21R.length
        var projs2002Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2002Cl21RLenght; r1++)
        projs2002Cl21RNumeros.push(Number(projs2002Cl21R[r1]))
        const projs2002Cl2Reais1 = projs2002Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22R = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22RLenght = projs2002Cl22R.length
        var projs2002Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2002Cl22RLenght; r2++)
        projs2002Cl22RNumeros.push(Number(projs2002Cl22R[r2]))
        const projs2002Cl2Reais2 = projs2002Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002Cl2 = (projs2002Cl2Reais1+projs2002Cl2Reais2)
    
        //2002Cl2 dolar
        const projs2002Cl21D = projs2002Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl21DLenght = projs2002Cl21D.length
        var projs2002Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2002Cl21DLenght; d1++)
        projs2002Cl21DNumeros.push(Number(projs2002Cl21D[d1]))
        const projs2002Cl2Dolar1 = projs2002Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl22D = projs2002Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl22DLenght = projs2002Cl22D.length
        var projs2002Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2002Cl22DLenght; d2++)
        projs2002Cl22DNumeros.push(Number(projs2002Cl22D[d2]))
        const projs2002Cl2Dolar2 = projs2002Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002Cl2 = (projs2002Cl2Dolar1+projs2002Cl2Dolar2)
    
         //2003Cl2 reais
        const projs2003Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
        const projs2003Cl21R = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21RLenght = projs2003Cl21R.length
        var projs2003Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2003Cl21RLenght; r1++)
        projs2003Cl21RNumeros.push(Number(projs2003Cl21R[r1]))
        const projs2003Cl2Reais1 = projs2003Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22R = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22RLenght = projs2003Cl22R.length
        var projs2003Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2003Cl22RLenght; r2++)
        projs2003Cl22RNumeros.push(Number(projs2003Cl22R[r2]))
        const projs2003Cl2Reais2 = projs2003Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003Cl2 = (projs2003Cl2Reais1+projs2003Cl2Reais2)
        
        //2003Cl2 dolar
        const projs2003Cl21D = projs2003Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl21DLenght = projs2003Cl21D.length
        var projs2003Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2003Cl21DLenght; d1++)
        projs2003Cl21DNumeros.push(Number(projs2003Cl21D[d1]))
        const projs2003Cl2Dolar1 = projs2003Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl22D = projs2003Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl22DLenght = projs2003Cl22D.length
        var projs2003Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2003Cl22DLenght; d2++)
        projs2003Cl22DNumeros.push(Number(projs2003Cl22D[d2]))
        const projs2003Cl2Dolar2 = projs2003Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003Cl2 = (projs2003Cl2Dolar1+projs2003Cl2Dolar2)
    
        //2004Cl2 reais
        const projs2004Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
        const projs2004Cl21R = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21RLenght = projs2004Cl21R.length
        var projs2004Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2004Cl21RLenght; r1++)
        projs2004Cl21RNumeros.push(Number(projs2004Cl21R[r1]))
        const projs2004Cl2Reais1 = projs2004Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22R = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22RLenght = projs2004Cl22R.length
        var projs2004Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2004Cl22RLenght; r2++)
        projs2004Cl22RNumeros.push(Number(projs2004Cl22R[r2]))
        const projs2004Cl2Reais2 = projs2004Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004Cl2 = (projs2004Cl2Reais1+projs2004Cl2Reais2)
        
        //2004Cl2 dolar
        const projs2004Cl21D = projs2004Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl21DLenght = projs2004Cl21D.length
        var projs2004Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2004Cl21DLenght; d1++)
        projs2004Cl21DNumeros.push(Number(projs2004Cl21D[d1]))
        const projs2004Cl2Dolar1 = projs2004Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl22D = projs2004Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl22DLenght = projs2004Cl22D.length
        var projs2004Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2004Cl22DLenght; d2++)
        projs2004Cl22DNumeros.push(Number(projs2004Cl22D[d2]))
        const projs2004Cl2Dolar2 = projs2004Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004Cl2 = (projs2004Cl2Dolar1+projs2004Cl2Dolar2)
    
        //2005Cl2 reais
        const projs2005Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
        const projs2005Cl21R = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21RLenght = projs2005Cl21R.length
        var projs2005Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2005Cl21RLenght; r1++)
        projs2005Cl21RNumeros.push(Number(projs2005Cl21R[r1]))
        const projs2005Cl2Reais1 = projs2005Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22R = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22RLenght = projs2005Cl22R.length
        var projs2005Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2005Cl22RLenght; r2++)
        projs2005Cl22RNumeros.push(Number(projs2005Cl22R[r2]))
        const projs2005Cl2Reais2 = projs2005Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005Cl2 = (projs2005Cl2Reais1+projs2005Cl2Reais2)
        
        //2005Cl2 dolar
        const projs2005Cl21D = projs2005Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl21DLenght = projs2005Cl21D.length
        var projs2005Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2005Cl21DLenght; d1++)
        projs2005Cl21DNumeros.push(Number(projs2005Cl21D[d1]))
        const projs2005Cl2Dolar1 = projs2005Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl22D = projs2005Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl22DLenght = projs2005Cl22D.length
        var projs2005Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2005Cl22DLenght; d2++)
        projs2005Cl22DNumeros.push(Number(projs2005Cl22D[d2]))
        const projs2005Cl2Dolar2 = projs2005Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005Cl2 = (projs2005Cl2Dolar1+projs2005Cl2Dolar2)
    
        //2006Cl2 reais
        const projs2006Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2) ){return val}})
        const projs2006Cl21R = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21RLenght = projs2006Cl21R.length
        var projs2006Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2006Cl21RLenght; r1++)
        projs2006Cl21RNumeros.push(Number(projs2006Cl21R[r1]))
        const projs2006Cl2Reais1 = projs2006Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22R = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22RLenght = projs2006Cl22R.length
        var projs2006Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2006Cl22RLenght; r2++)
        projs2006Cl22RNumeros.push(Number(projs2006Cl22R[r2]))
        const projs2006Cl2Reais2 = projs2006Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006Cl2 = (projs2006Cl2Reais1+projs2006Cl2Reais2)
        
        //2006Cl2 dolar
        const projs2006Cl21D = projs2006Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl21DLenght = projs2006Cl21D.length
        var projs2006Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2006Cl21DLenght; d1++)
        projs2006Cl21DNumeros.push(Number(projs2006Cl21D[d1]))
        const projs2006Cl2Dolar1 = projs2006Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl22D = projs2006Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl22DLenght = projs2006Cl22D.length
        var projs2006Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2006Cl22DLenght; d2++)
        projs2006Cl22DNumeros.push(Number(projs2006Cl22D[d2]))
        const projs2006Cl2Dolar2 = projs2006Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006Cl2 = (projs2006Cl2Dolar1+projs2006Cl2Dolar2)
    
        //2007Cl2 reais
        const projs2007Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2007Cl21R = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21RLenght = projs2007Cl21R.length
        var projs2007Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2007Cl21RLenght; r1++)
        projs2007Cl21RNumeros.push(Number(projs2007Cl21R[r1]))
        const projs2007Cl2Reais1 = projs2007Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22R = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22RLenght = projs2007Cl22R.length
        var projs2007Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2007Cl22RLenght; r2++)
        projs2007Cl22RNumeros.push(Number(projs2007Cl22R[r2]))
        const projs2007Cl2Reais2 = projs2007Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007Cl2 = (projs2007Cl2Reais1+projs2007Cl2Reais2)
        
        //2007Cl2 dolar
        const projs2007Cl21D = projs2007Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl21DLenght = projs2007Cl21D.length
        var projs2007Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2007Cl21DLenght; d1++)
        projs2007Cl21DNumeros.push(Number(projs2007Cl21D[d1]))
        const projs2007Cl2Dolar1 = projs2007Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl22D = projs2007Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl22DLenght = projs2007Cl22D.length
        var projs2007Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2007Cl22DLenght; d2++)
        projs2007Cl22DNumeros.push(Number(projs2007Cl22D[d2]))
        const projs2007Cl2Dolar2 = projs2007Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007Cl2 = (projs2007Cl2Dolar1+projs2007Cl2Dolar2)
    
        //2008Cl2 reais
        const projs2008Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2008Cl21R = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21RLenght = projs2008Cl21R.length
        var projs2008Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2008Cl21RLenght; r1++)
        projs2008Cl21RNumeros.push(Number(projs2008Cl21R[r1]))
        const projs2008Cl2Reais1 = projs2008Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22R = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22RLenght = projs2008Cl22R.length
        var projs2008Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2008Cl22RLenght; r2++)
        projs2008Cl22RNumeros.push(Number(projs2008Cl22R[r2]))
        const projs2008Cl2Reais2 = projs2008Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008Cl2 = (projs2008Cl2Reais1+projs2008Cl2Reais2)
        
        //2008Cl2 dolar
        const projs2008Cl21D = projs2008Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl21DLenght = projs2008Cl21D.length
        var projs2008Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2008Cl21DLenght; d1++)
        projs2008Cl21DNumeros.push(Number(projs2008Cl21D[d1]))
        const projs2008Cl2Dolar1 = projs2008Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl22D = projs2008Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl22DLenght = projs2008Cl22D.length
        var projs2008Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2008Cl22DLenght; d2++)
        projs2008Cl22DNumeros.push(Number(projs2008Cl22D[d2]))
        const projs2008Cl2Dolar2 = projs2008Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008Cl2 = (projs2008Cl2Dolar1+projs2008Cl2Dolar2)
    
        //2009Cl2 reais
        const projs2009Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2009Cl21R = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21RLenght = projs2009Cl21R.length
        var projs2009Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2009Cl21RLenght; r1++)
        projs2009Cl21RNumeros.push(Number(projs2009Cl21R[r1]))
        const projs2009Cl2Reais1 = projs2009Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22R = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22RLenght = projs2009Cl22R.length
        var projs2009Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2009Cl22RLenght; r2++)
        projs2009Cl22RNumeros.push(Number(projs2009Cl22R[r2]))
        const projs2009Cl2Reais2 = projs2009Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009Cl2 = (projs2009Cl2Reais1+projs2009Cl2Reais2)
        
        //2009Cl2 dolar
        const projs2009Cl21D = projs2009Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl21DLenght = projs2009Cl21D.length
        var projs2009Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2009Cl21DLenght; d1++)
        projs2009Cl21DNumeros.push(Number(projs2009Cl21D[d1]))
        const projs2009Cl2Dolar1 = projs2009Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl22D = projs2009Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl22DLenght = projs2009Cl22D.length
        var projs2009Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2009Cl22DLenght; d2++)
        projs2009Cl22DNumeros.push(Number(projs2009Cl22D[d2]))
        const projs2009Cl2Dolar2 = projs2009Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009Cl2 = (projs2009Cl2Dolar1+projs2009Cl2Dolar2)
    
        //2010Cl2 reais
        const projs2010Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2010Cl21R = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21RLenght = projs2010Cl21R.length
        var projs2010Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2010Cl21RLenght; r1++)
        projs2010Cl21RNumeros.push(Number(projs2010Cl21R[r1]))
        const projs2010Cl2Reais1 = projs2010Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22R = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22RLenght = projs2010Cl22R.length
        var projs2010Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2010Cl22RLenght; r2++)
        projs2010Cl22RNumeros.push(Number(projs2010Cl22R[r2]))
        const projs2010Cl2Reais2 = projs2010Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010Cl2 = (projs2010Cl2Reais1+projs2010Cl2Reais2)
        
        //2010Cl2 dolar
        const projs2010Cl21D = projs2010Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl21DLenght = projs2010Cl21D.length
        var projs2010Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2010Cl21DLenght; d1++)
        projs2010Cl21DNumeros.push(Number(projs2010Cl21D[d1]))
        const projs2010Cl2Dolar1 = projs2010Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl22D = projs2010Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl22DLenght = projs2010Cl22D.length
        var projs2010Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2010Cl22DLenght; d2++)
        projs2010Cl22DNumeros.push(Number(projs2010Cl22D[d2]))
        const projs2010Cl2Dolar2 = projs2010Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010Cl2 = (projs2010Cl2Dolar1+projs2010Cl2Dolar2)
    
        //2011Cl2 reais
        const projs2011Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2011Cl21R = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21RLenght = projs2011Cl21R.length
        var projs2011Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2011Cl21RLenght; r1++)
        projs2011Cl21RNumeros.push(Number(projs2011Cl21R[r1]))
        const projs2011Cl2Reais1 = projs2011Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22R = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22RLenght = projs2011Cl22R.length
        var projs2011Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2011Cl22RLenght; r2++)
        projs2011Cl22RNumeros.push(Number(projs2011Cl22R[r2]))
        const projs2011Cl2Reais2 = projs2011Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2011Cl2 = (projs2011Cl2Reais1+projs2011Cl2Reais2)
        
        //2011Cl2 dolar
        const projs2011Cl21D = projs2011Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl21DLenght = projs2011Cl21D.length
        var projs2011Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2011Cl21DLenght; d1++)
        projs2011Cl21DNumeros.push(Number(projs2011Cl21D[d1]))
        const projs2011Cl2Dolar1 = projs2011Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl22D = projs2011Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl22DLenght = projs2011Cl22D.length
        var projs2011Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2011Cl22DLenght; d2++)
        projs2011Cl22DNumeros.push(Number(projs2011Cl22D[d2]))
        const projs2011Cl2Dolar2 = projs2011Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2011Cl2 = (projs2011Cl2Dolar1+projs2011Cl2Dolar2)
    
        //2012Cl2 reais
        const projs2012Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2012Cl21R = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21RLenght = projs2012Cl21R.length
        var projs2012Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2012Cl21RLenght; r1++)
        projs2012Cl21RNumeros.push(Number(projs2012Cl21R[r1]))
        const projs2012Cl2Reais1 = projs2012Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22R = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22RLenght = projs2012Cl22R.length
        var projs2012Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2012Cl22RLenght; r2++)
        projs2012Cl22RNumeros.push(Number(projs2012Cl22R[r2]))
        const projs2012Cl2Reais2 = projs2012Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2012Cl2 = (projs2012Cl2Reais1+projs2012Cl2Reais2)
        
        //2012Cl2 dolar
        const projs2012Cl21D = projs2012Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl21DLenght = projs2012Cl21D.length
        var projs2012Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2012Cl21DLenght; d1++)
        projs2012Cl21DNumeros.push(Number(projs2012Cl21D[d1]))
        const projs2012Cl2Dolar1 = projs2012Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl22D = projs2012Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl22DLenght = projs2012Cl22D.length
        var projs2012Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2012Cl22DLenght; d2++)
        projs2012Cl22DNumeros.push(Number(projs2012Cl22D[d2]))
        const projs2012Cl2Dolar2 = projs2012Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2012Cl2 = (projs2012Cl2Dolar1+projs2012Cl2Dolar2)
        
        //2013Cl2 reais
        const projs2013Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2013Cl21R = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21RLenght = projs2013Cl21R.length
        var projs2013Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2013Cl21RLenght; r1++)
        projs2013Cl21RNumeros.push(Number(projs2013Cl21R[r1]))
        const projs2013Cl2Reais1 = projs2013Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22R = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22RLenght = projs2013Cl22R.length
        var projs2013Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2013Cl22RLenght; r2++)
        projs2013Cl22RNumeros.push(Number(projs2013Cl22R[r2]))
        const projs2013Cl2Reais2 = projs2013Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2013Cl2 = (projs2013Cl2Reais1+projs2013Cl2Reais2)
        
        //2013Cl2 dolar
        const projs2013Cl21D = projs2013Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl21DLenght = projs2013Cl21D.length
        var projs2013Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2013Cl21DLenght; d1++)
        projs2013Cl21DNumeros.push(Number(projs2013Cl21D[d1]))
        const projs2013Cl2Dolar1 = projs2013Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl22D = projs2013Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl22DLenght = projs2013Cl22D.length
        var projs2013Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2013Cl22DLenght; d2++)
        projs2013Cl22DNumeros.push(Number(projs2013Cl22D[d2]))
        const projs2013Cl2Dolar2 = projs2013Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2013Cl2 = (projs2013Cl2Dolar1+projs2013Cl2Dolar2)
        
        //2014Cl2 reais
        const projs2014Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2014Cl21R = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21RLenght = projs2014Cl21R.length
        var projs2014Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2014Cl21RLenght; r1++)
        projs2014Cl21RNumeros.push(Number(projs2014Cl21R[r1]))
        const projs2014Cl2Reais1 = projs2014Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22R = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22RLenght = projs2014Cl22R.length
        var projs2014Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2014Cl22RLenght; r2++)
        projs2014Cl22RNumeros.push(Number(projs2014Cl22R[r2]))
        const projs2014Cl2Reais2 = projs2014Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2014Cl2 = (projs2014Cl2Reais1+projs2014Cl2Reais2)
        
        //2014Cl2 dolar
        const projs2014Cl21D = projs2014Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl21DLenght = projs2014Cl21D.length
        var projs2014Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2014Cl21DLenght; d1++)
        projs2014Cl21DNumeros.push(Number(projs2014Cl21D[d1]))
        const projs2014Cl2Dolar1 = projs2014Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl22D = projs2014Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl22DLenght = projs2014Cl22D.length
        var projs2014Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2014Cl22DLenght; d2++)
        projs2014Cl22DNumeros.push(Number(projs2014Cl22D[d2]))
        const projs2014Cl2Dolar2 = projs2014Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2014Cl2 = (projs2014Cl2Dolar1+projs2014Cl2Dolar2)
        
        
        //2015Cl2 reais
        const projs2015Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2015Cl21R = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21RLenght = projs2015Cl21R.length
        var projs2015Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2015Cl21RLenght; r1++)
        projs2015Cl21RNumeros.push(Number(projs2015Cl21R[r1]))
        const projs2015Cl2Reais1 = projs2015Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22R = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22RLenght = projs2015Cl22R.length
        var projs2015Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2015Cl22RLenght; r2++)
        projs2015Cl22RNumeros.push(Number(projs2015Cl22R[r2]))
        const projs2015Cl2Reais2 = projs2015Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2015Cl2 = (projs2015Cl2Reais1+projs2015Cl2Reais2)
        
        //2015Cl2 dolar
        const projs2015Cl21D = projs2015Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl21DLenght = projs2015Cl21D.length
        var projs2015Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2015Cl21DLenght; d1++)
        projs2015Cl21DNumeros.push(Number(projs2015Cl21D[d1]))
        const projs2015Cl2Dolar1 = projs2015Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl22D = projs2015Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl22DLenght = projs2015Cl22D.length
        var projs2015Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2015Cl22DLenght; d2++)
        projs2015Cl22DNumeros.push(Number(projs2015Cl22D[d2]))
        const projs2015Cl2Dolar2 = projs2015Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2015Cl2 = (projs2015Cl2Dolar1+projs2015Cl2Dolar2)
        
        //2016Cl2 reais
        const projs2016Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2016Cl21R = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21RLenght = projs2016Cl21R.length
        var projs2016Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2016Cl21RLenght; r1++)
        projs2016Cl21RNumeros.push(Number(projs2016Cl21R[r1]))
        const projs2016Cl2Reais1 = projs2016Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22R = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22RLenght = projs2016Cl22R.length
        var projs2016Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2016Cl22RLenght; r2++)
        projs2016Cl22RNumeros.push(Number(projs2016Cl22R[r2]))
        const projs2016Cl2Reais2 = projs2016Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2016Cl2 = (projs2016Cl2Reais1+projs2016Cl2Reais2)
        
        //2016Cl2 dolar
        const projs2016Cl21D = projs2016Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl21DLenght = projs2016Cl21D.length
        var projs2016Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2016Cl21DLenght; d1++)
        projs2016Cl21DNumeros.push(Number(projs2016Cl21D[d1]))
        const projs2016Cl2Dolar1 = projs2016Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl22D = projs2016Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl22DLenght = projs2016Cl22D.length
        var projs2016Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2016Cl22DLenght; d2++)
        projs2016Cl22DNumeros.push(Number(projs2016Cl22D[d2]))
        const projs2016Cl2Dolar2 = projs2016Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2016Cl2 = (projs2016Cl2Dolar1+projs2016Cl2Dolar2)
        
        //2017Cl2 reais
        const projs2017Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2017Cl21R = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21RLenght = projs2017Cl21R.length
        var projs2017Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2017Cl21RLenght; r1++)
        projs2017Cl21RNumeros.push(Number(projs2017Cl21R[r1]))
        const projs2017Cl2Reais1 = projs2017Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22R = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22RLenght = projs2017Cl22R.length
        var projs2017Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2017Cl22RLenght; r2++)
        projs2017Cl22RNumeros.push(Number(projs2017Cl22R[r2]))
        const projs2017Cl2Reais2 = projs2017Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2017Cl2 = (projs2017Cl2Reais1+projs2017Cl2Reais2)
        
        //2017Cl2 dolar
        const projs2017Cl21D = projs2017Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl21DLenght = projs2017Cl21D.length
        var projs2017Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2017Cl21DLenght; d1++)
        projs2017Cl21DNumeros.push(Number(projs2017Cl21D[d1]))
        const projs2017Cl2Dolar1 = projs2017Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl22D = projs2017Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl22DLenght = projs2017Cl22D.length
        var projs2017Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2017Cl22DLenght; d2++)
        projs2017Cl22DNumeros.push(Number(projs2017Cl22D[d2]))
        const projs2017Cl2Dolar2 = projs2017Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2017Cl2 = (projs2017Cl2Dolar1+projs2017Cl2Dolar2)
        
        //2018Cl2 reais
        const projs2018Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2018Cl21R = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21RLenght = projs2018Cl21R.length
        var projs2018Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2018Cl21RLenght; r1++)
        projs2018Cl21RNumeros.push(Number(projs2018Cl21R[r1]))
        const projs2018Cl2Reais1 = projs2018Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22R = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22RLenght = projs2018Cl22R.length
        var projs2018Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2018Cl22RLenght; r2++)
        projs2018Cl22RNumeros.push(Number(projs2018Cl22R[r2]))
        const projs2018Cl2Reais2 = projs2018Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2018Cl2 = (projs2018Cl2Reais1+projs2018Cl2Reais2)
        
        //2018Cl2 dolar
        const projs2018Cl21D = projs2018Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl21DLenght = projs2018Cl21D.length
        var projs2018Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2018Cl21DLenght; d1++)
        projs2018Cl21DNumeros.push(Number(projs2018Cl21D[d1]))
        const projs2018Cl2Dolar1 = projs2018Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl22D = projs2018Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl22DLenght = projs2018Cl22D.length
        var projs2018Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2018Cl22DLenght; d2++)
        projs2018Cl22DNumeros.push(Number(projs2018Cl22D[d2]))
        const projs2018Cl2Dolar2 = projs2018Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2018Cl2 = (projs2018Cl2Dolar1+projs2018Cl2Dolar2)
        
        //2019Cl2 reais
        const projs2019Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2019Cl21R = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21RLenght = projs2019Cl21R.length
        var projs2019Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2019Cl21RLenght; r1++)
        projs2019Cl21RNumeros.push(Number(projs2019Cl21R[r1]))
        const projs2019Cl2Reais1 = projs2019Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22R = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22RLenght = projs2019Cl22R.length
        var projs2019Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2019Cl22RLenght; r2++)
        projs2019Cl22RNumeros.push(Number(projs2019Cl22R[r2]))
        const projs2019Cl2Reais2 = projs2019Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2019Cl2 = (projs2019Cl2Reais1+projs2019Cl2Reais2)
        
        //2019Cl2 dolar
        const projs2019Cl21D = projs2019Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl21DLenght = projs2019Cl21D.length
        var projs2019Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2019Cl21DLenght; d1++)
        projs2019Cl21DNumeros.push(Number(projs2019Cl21D[d1]))
        const projs2019Cl2Dolar1 = projs2019Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl22D = projs2019Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl22DLenght = projs2019Cl22D.length
        var projs2019Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2019Cl22DLenght; d2++)
        projs2019Cl22DNumeros.push(Number(projs2019Cl22D[d2]))
        const projs2019Cl2Dolar2 = projs2019Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2019Cl2 = (projs2019Cl2Dolar1+projs2019Cl2Dolar2)
        
        //2020Cl2 reais
        const projs2020Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2020Cl21R = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21RLenght = projs2020Cl21R.length
        var projs2020Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2020Cl21RLenght; r1++)
        projs2020Cl21RNumeros.push(Number(projs2020Cl21R[r1]))
        const projs2020Cl2Reais1 = projs2020Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22R = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22RLenght = projs2020Cl22R.length
        var projs2020Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2020Cl22RLenght; r2++)
        projs2020Cl22RNumeros.push(Number(projs2020Cl22R[r2]))
        const projs2020Cl2Reais2 = projs2020Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2020Cl2 = (projs2020Cl2Reais1+projs2020Cl2Reais2)
        
        //2020Cl2 dolar
        const projs2020Cl21D = projs2020Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl21DLenght = projs2020Cl21D.length
        var projs2020Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2020Cl21DLenght; d1++)
        projs2020Cl21DNumeros.push(Number(projs2020Cl21D[d1]))
        const projs2020Cl2Dolar1 = projs2020Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl22D = projs2020Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl22DLenght = projs2020Cl22D.length
        var projs2020Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2020Cl22DLenght; d2++)
        projs2020Cl22DNumeros.push(Number(projs2020Cl22D[d2]))
        const projs2020Cl2Dolar2 = projs2020Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2020Cl2 = (projs2020Cl2Dolar1+projs2020Cl2Dolar2)
        
        //2021Cl2 reais
        const projs2021Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2021Cl21R = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21RLenght = projs2021Cl21R.length
        var projs2021Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2021Cl21RLenght; r1++)
        projs2021Cl21RNumeros.push(Number(projs2021Cl21R[r1]))
        const projs2021Cl2Reais1 = projs2021Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22R = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22RLenght = projs2021Cl22R.length
        var projs2021Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2021Cl22RLenght; r2++)
        projs2021Cl22RNumeros.push(Number(projs2021Cl22R[r2]))
        const projs2021Cl2Reais2 = projs2021Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2021Cl2 = (projs2021Cl2Reais1+projs2021Cl2Reais2)
        
        //2021Cl2 dolar
        const projs2021Cl21D = projs2021Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl21DLenght = projs2021Cl21D.length
        var projs2021Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2021Cl21DLenght; d1++)
        projs2021Cl21DNumeros.push(Number(projs2021Cl21D[d1]))
        const projs2021Cl2Dolar1 = projs2021Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl22D = projs2021Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl22DLenght = projs2021Cl22D.length
        var projs2021Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2021Cl22DLenght; d2++)
        projs2021Cl22DNumeros.push(Number(projs2021Cl22D[d2]))
        const projs2021Cl2Dolar2 = projs2021Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2021Cl2 = (projs2021Cl2Dolar1+projs2021Cl2Dolar2)
        
        //2022Cl2 reais
        const projs2022Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2022Cl21R = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21RLenght = projs2022Cl21R.length
        var projs2022Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2022Cl21RLenght; r1++)
        projs2022Cl21RNumeros.push(Number(projs2022Cl21R[r1]))
        const projs2022Cl2Reais1 = projs2022Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22R = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22RLenght = projs2022Cl22R.length
        var projs2022Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2022Cl22RLenght; r2++)
        projs2022Cl22RNumeros.push(Number(projs2022Cl22R[r2]))
        const projs2022Cl2Reais2 = projs2022Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2022Cl2 = projs2022Cl2Reais1+projs2022Cl2Reais2
        
        //2022Cl2 dolar
        const projs2022Cl21D = projs2022Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl21DLenght = projs2022Cl21D.length
        var projs2022Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2022Cl21DLenght; d1++)
        projs2022Cl21DNumeros.push(Number(projs2022Cl21D[d1]))
        const projs2022Cl2Dolar1 = projs2022Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl22D = projs2022Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl22DLenght = projs2022Cl22D.length
        var projs2022Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2022Cl22DLenght; d2++)
        projs2022Cl22DNumeros.push(Number(projs2022Cl22D[d2]))
        const projs2022Cl2Dolar2 = projs2022Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2022Cl2 = projs2022Cl2Dolar1+projs2022Cl2Dolar2
        
        //2023Cl2 reais
        const projs2023Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2023Cl21R = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21RLenght = projs2023Cl21R.length
        var projs2023Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2023Cl21RLenght; r1++)
        projs2023Cl21RNumeros.push(Number(projs2023Cl21R[r1]))
        const projs2023Cl2Reais1 = projs2023Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22R = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22RLenght = projs2023Cl22R.length
        var projs2023Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2023Cl22RLenght; r2++)
        projs2023Cl22RNumeros.push(Number(projs2023Cl22R[r2]))
        const projs2023Cl2Reais2 = projs2023Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2023Cl2 = projs2023Cl2Reais1+projs2023Cl2Reais2
        
        //2023Cl2 dolar
        const projs2023Cl21D = projs2023Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl21DLenght = projs2023Cl21D.length
        var projs2023Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2023Cl21DLenght; d1++)
        projs2023Cl21DNumeros.push(Number(projs2023Cl21D[d1]))
        const projs2023Cl2Dolar1 = projs2023Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl22D = projs2023Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl22DLenght = projs2023Cl22D.length
        var projs2023Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2023Cl22DLenght; d2++)
        projs2023Cl22DNumeros.push(Number(projs2023Cl22D[d2]))
        const projs2023Cl2Dolar2 = projs2023Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2023Cl2 = projs2023Cl2Dolar1+projs2023Cl2Dolar2
        
        
        //2024Cl2 reais
        const projs2024Cl2 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente2)){return val}})
        const projs2024Cl21R = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21RLenght = projs2024Cl21R.length
        var projs2024Cl21RNumeros = [];
        for (var r1 = 0; r1 < projs2024Cl21RLenght; r1++)
        projs2024Cl21RNumeros.push(Number(projs2024Cl21R[r1]))
        const projs2024Cl2Reais1 = projs2024Cl21RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22R = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22RLenght = projs2024Cl22R.length
        var projs2024Cl22RNumeros = [];
        for (var r2 = 0; r2 < projs2024Cl22RLenght; r2++)
        projs2024Cl22RNumeros.push(Number(projs2024Cl22R[r2]))
        const projs2024Cl2Reais2 = projs2024Cl22RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2024Cl2 = projs2024Cl2Reais1+projs2024Cl2Reais2
        
        //2024Cl2 dolar
        const projs2024Cl21D = projs2024Cl2.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl21DLenght = projs2024Cl21D.length
        var projs2024Cl21DNumeros = [];
        for (var d1 = 0; d1 < projs2024Cl21DLenght; d1++)
        projs2024Cl21DNumeros.push(Number(projs2024Cl21D[d1]))
        const projs2024Cl2Dolar1 = projs2024Cl21DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl22D = projs2024Cl2.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl22DLenght = projs2024Cl22D.length
        var projs2024Cl22DNumeros = [];
        for (var d2 = 0; d2 < projs2024Cl22DLenght; d2++)
        projs2024Cl22DNumeros.push(Number(projs2024Cl22D[d2]))
        const projs2024Cl2Dolar2 = projs2024Cl22DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2024Cl2 = projs2024Cl2Dolar1+projs2024Cl2Dolar2


         //2002Cl3 reais
    const projs2002Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2002')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
        const projs2002Cl31R = projs2002Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl31RLenght = projs2002Cl31R.length
        var projs2002Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2002Cl31RLenght; r1++)
        projs2002Cl31RNumeros.push(Number(projs2002Cl31R[r1]))
        const projs2002Cl3Reais1 = projs2002Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl32R = projs2002Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl32RLenght = projs2002Cl32R.length
        var projs2002Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2002Cl32RLenght; r2++)
        projs2002Cl32RNumeros.push(Number(projs2002Cl32R[r2]))
        const projs2002Cl3Reais2 = projs2002Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Reais2002Cl3 = (projs2002Cl3Reais1+projs2002Cl3Reais2)
    
        //2002Cl3 dolar
        const projs2002Cl31D = projs2002Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2002Cl31DLenght = projs2002Cl31D.length
        var projs2002Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2002Cl31DLenght; d1++)
        projs2002Cl31DNumeros.push(Number(projs2002Cl31D[d1]))
        const projs2002Cl3Dolar1 = projs2002Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2002Cl32D = projs2002Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD' ){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2002Cl32DLenght = projs2002Cl32D.length
        var projs2002Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2002Cl32DLenght; d2++)
        projs2002Cl32DNumeros.push(Number(projs2002Cl32D[d2]))
        const projs2002Cl3Dolar2 = projs2002Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
    
        const Dolar2002Cl3 = (projs2002Cl3Dolar1+projs2002Cl3Dolar2)
    
         //2003Cl3 reais
        const projs2003Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2003') && val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
        const projs2003Cl31R = projs2003Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl31RLenght = projs2003Cl31R.length
        var projs2003Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2003Cl31RLenght; r1++)
        projs2003Cl31RNumeros.push(Number(projs2003Cl31R[r1]))
        const projs2003Cl3Reais1 = projs2003Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl32R = projs2003Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl32RLenght = projs2003Cl32R.length
        var projs2003Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2003Cl32RLenght; r2++)
        projs2003Cl32RNumeros.push(Number(projs2003Cl32R[r2]))
        const projs2003Cl3Reais2 = projs2003Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2003Cl3 = (projs2003Cl3Reais1+projs2003Cl3Reais2)
        
        //2003Cl3 dolar
        const projs2003Cl31D = projs2003Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2003Cl31DLenght = projs2003Cl31D.length
        var projs2003Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2003Cl31DLenght; d1++)
        projs2003Cl31DNumeros.push(Number(projs2003Cl31D[d1]))
        const projs2003Cl3Dolar1 = projs2003Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2003Cl32D = projs2003Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2003Cl32DLenght = projs2003Cl32D.length
        var projs2003Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2003Cl32DLenght; d2++)
        projs2003Cl32DNumeros.push(Number(projs2003Cl32D[d2]))
        const projs2003Cl3Dolar2 = projs2003Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2003Cl3 = (projs2003Cl3Dolar1+projs2003Cl3Dolar2)
    
        //2004Cl3 reais
        const projs2004Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2004')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
        const projs2004Cl31R = projs2004Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl31RLenght = projs2004Cl31R.length
        var projs2004Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2004Cl31RLenght; r1++)
        projs2004Cl31RNumeros.push(Number(projs2004Cl31R[r1]))
        const projs2004Cl3Reais1 = projs2004Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl32R = projs2004Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl32RLenght = projs2004Cl32R.length
        var projs2004Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2004Cl32RLenght; r2++)
        projs2004Cl32RNumeros.push(Number(projs2004Cl32R[r2]))
        const projs2004Cl3Reais2 = projs2004Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2004Cl3 = (projs2004Cl3Reais1+projs2004Cl3Reais2)
        
        //2004Cl3 dolar
        const projs2004Cl31D = projs2004Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2004Cl31DLenght = projs2004Cl31D.length
        var projs2004Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2004Cl31DLenght; d1++)
        projs2004Cl31DNumeros.push(Number(projs2004Cl31D[d1]))
        const projs2004Cl3Dolar1 = projs2004Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2004Cl32D = projs2004Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2004Cl32DLenght = projs2004Cl32D.length
        var projs2004Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2004Cl32DLenght; d2++)
        projs2004Cl32DNumeros.push(Number(projs2004Cl32D[d2]))
        const projs2004Cl3Dolar2 = projs2004Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2004Cl3 = (projs2004Cl3Dolar1+projs2004Cl3Dolar2)
    
        //2005Cl3 reais
        const projs2005Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2005')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
        const projs2005Cl31R = projs2005Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl31RLenght = projs2005Cl31R.length
        var projs2005Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2005Cl31RLenght; r1++)
        projs2005Cl31RNumeros.push(Number(projs2005Cl31R[r1]))
        const projs2005Cl3Reais1 = projs2005Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl32R = projs2005Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl32RLenght = projs2005Cl32R.length
        var projs2005Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2005Cl32RLenght; r2++)
        projs2005Cl32RNumeros.push(Number(projs2005Cl32R[r2]))
        const projs2005Cl3Reais2 = projs2005Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2005Cl3 = (projs2005Cl3Reais1+projs2005Cl3Reais2)
        
        //2005Cl3 dolar
        const projs2005Cl31D = projs2005Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2005Cl31DLenght = projs2005Cl31D.length
        var projs2005Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2005Cl31DLenght; d1++)
        projs2005Cl31DNumeros.push(Number(projs2005Cl31D[d1]))
        const projs2005Cl3Dolar1 = projs2005Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2005Cl32D = projs2005Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2005Cl32DLenght = projs2005Cl32D.length
        var projs2005Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2005Cl32DLenght; d2++)
        projs2005Cl32DNumeros.push(Number(projs2005Cl32D[d2]))
        const projs2005Cl3Dolar2 = projs2005Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2005Cl3 = (projs2005Cl3Dolar1+projs2005Cl3Dolar2)
    
        //2006Cl3 reais
        const projs2006Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2006')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3) ){return val}})
        const projs2006Cl31R = projs2006Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl31RLenght = projs2006Cl31R.length
        var projs2006Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2006Cl31RLenght; r1++)
        projs2006Cl31RNumeros.push(Number(projs2006Cl31R[r1]))
        const projs2006Cl3Reais1 = projs2006Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl32R = projs2006Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl32RLenght = projs2006Cl32R.length
        var projs2006Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2006Cl32RLenght; r2++)
        projs2006Cl32RNumeros.push(Number(projs2006Cl32R[r2]))
        const projs2006Cl3Reais2 = projs2006Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2006Cl3 = (projs2006Cl3Reais1+projs2006Cl3Reais2)
        
        //2006Cl3 dolar
        const projs2006Cl31D = projs2006Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2006Cl31DLenght = projs2006Cl31D.length
        var projs2006Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2006Cl31DLenght; d1++)
        projs2006Cl31DNumeros.push(Number(projs2006Cl31D[d1]))
        const projs2006Cl3Dolar1 = projs2006Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2006Cl32D = projs2006Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2006Cl32DLenght = projs2006Cl32D.length
        var projs2006Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2006Cl32DLenght; d2++)
        projs2006Cl32DNumeros.push(Number(projs2006Cl32D[d2]))
        const projs2006Cl3Dolar2 = projs2006Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2006Cl3 = (projs2006Cl3Dolar1+projs2006Cl3Dolar2)
    
        //2007Cl3 reais
        const projs2007Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2007')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2007Cl31R = projs2007Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl31RLenght = projs2007Cl31R.length
        var projs2007Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2007Cl31RLenght; r1++)
        projs2007Cl31RNumeros.push(Number(projs2007Cl31R[r1]))
        const projs2007Cl3Reais1 = projs2007Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl32R = projs2007Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl32RLenght = projs2007Cl32R.length
        var projs2007Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2007Cl32RLenght; r2++)
        projs2007Cl32RNumeros.push(Number(projs2007Cl32R[r2]))
        const projs2007Cl3Reais2 = projs2007Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2007Cl3 = (projs2007Cl3Reais1+projs2007Cl3Reais2)
        
        //2007Cl3 dolar
        const projs2007Cl31D = projs2007Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2007Cl31DLenght = projs2007Cl31D.length
        var projs2007Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2007Cl31DLenght; d1++)
        projs2007Cl31DNumeros.push(Number(projs2007Cl31D[d1]))
        const projs2007Cl3Dolar1 = projs2007Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2007Cl32D = projs2007Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2007Cl32DLenght = projs2007Cl32D.length
        var projs2007Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2007Cl32DLenght; d2++)
        projs2007Cl32DNumeros.push(Number(projs2007Cl32D[d2]))
        const projs2007Cl3Dolar2 = projs2007Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2007Cl3 = (projs2007Cl3Dolar1+projs2007Cl3Dolar2)
    
        //2008Cl3 reais
        const projs2008Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2008')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2008Cl31R = projs2008Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl31RLenght = projs2008Cl31R.length
        var projs2008Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2008Cl31RLenght; r1++)
        projs2008Cl31RNumeros.push(Number(projs2008Cl31R[r1]))
        const projs2008Cl3Reais1 = projs2008Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl32R = projs2008Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl32RLenght = projs2008Cl32R.length
        var projs2008Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2008Cl32RLenght; r2++)
        projs2008Cl32RNumeros.push(Number(projs2008Cl32R[r2]))
        const projs2008Cl3Reais2 = projs2008Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2008Cl3 = (projs2008Cl3Reais1+projs2008Cl3Reais2)
        
        //2008Cl3 dolar
        const projs2008Cl31D = projs2008Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2008Cl31DLenght = projs2008Cl31D.length
        var projs2008Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2008Cl31DLenght; d1++)
        projs2008Cl31DNumeros.push(Number(projs2008Cl31D[d1]))
        const projs2008Cl3Dolar1 = projs2008Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2008Cl32D = projs2008Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2008Cl32DLenght = projs2008Cl32D.length
        var projs2008Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2008Cl32DLenght; d2++)
        projs2008Cl32DNumeros.push(Number(projs2008Cl32D[d2]))
        const projs2008Cl3Dolar2 = projs2008Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2008Cl3 = (projs2008Cl3Dolar1+projs2008Cl3Dolar2)
    
        //2009Cl3 reais
        const projs2009Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2009')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2009Cl31R = projs2009Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl31RLenght = projs2009Cl31R.length
        var projs2009Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2009Cl31RLenght; r1++)
        projs2009Cl31RNumeros.push(Number(projs2009Cl31R[r1]))
        const projs2009Cl3Reais1 = projs2009Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl32R = projs2009Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl32RLenght = projs2009Cl32R.length
        var projs2009Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2009Cl32RLenght; r2++)
        projs2009Cl32RNumeros.push(Number(projs2009Cl32R[r2]))
        const projs2009Cl3Reais2 = projs2009Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2009Cl3 = (projs2009Cl3Reais1+projs2009Cl3Reais2)
        
        //2009Cl3 dolar
        const projs2009Cl31D = projs2009Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2009Cl31DLenght = projs2009Cl31D.length
        var projs2009Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2009Cl31DLenght; d1++)
        projs2009Cl31DNumeros.push(Number(projs2009Cl31D[d1]))
        const projs2009Cl3Dolar1 = projs2009Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2009Cl32D = projs2009Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2009Cl32DLenght = projs2009Cl32D.length
        var projs2009Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2009Cl32DLenght; d2++)
        projs2009Cl32DNumeros.push(Number(projs2009Cl32D[d2]))
        const projs2009Cl3Dolar2 = projs2009Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2009Cl3 = (projs2009Cl3Dolar1+projs2009Cl3Dolar2)
    
        //2010Cl3 reais
        const projs2010Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2010')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2010Cl31R = projs2010Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl31RLenght = projs2010Cl31R.length
        var projs2010Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2010Cl31RLenght; r1++)
        projs2010Cl31RNumeros.push(Number(projs2010Cl31R[r1]))
        const projs2010Cl3Reais1 = projs2010Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl32R = projs2010Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl32RLenght = projs2010Cl32R.length
        var projs2010Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2010Cl32RLenght; r2++)
        projs2010Cl32RNumeros.push(Number(projs2010Cl32R[r2]))
        const projs2010Cl3Reais2 = projs2010Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2010Cl3 = (projs2010Cl3Reais1+projs2010Cl3Reais2)
        
        //2010Cl3 dolar
        const projs2010Cl31D = projs2010Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2010Cl31DLenght = projs2010Cl31D.length
        var projs2010Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2010Cl31DLenght; d1++)
        projs2010Cl31DNumeros.push(Number(projs2010Cl31D[d1]))
        const projs2010Cl3Dolar1 = projs2010Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2010Cl32D = projs2010Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2010Cl32DLenght = projs2010Cl32D.length
        var projs2010Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2010Cl32DLenght; d2++)
        projs2010Cl32DNumeros.push(Number(projs2010Cl32D[d2]))
        const projs2010Cl3Dolar2 = projs2010Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2010Cl3 = (projs2010Cl3Dolar1+projs2010Cl3Dolar2)
    
        //2011Cl3 reais
        const projs2011Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2011')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2011Cl31R = projs2011Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl31RLenght = projs2011Cl31R.length
        var projs2011Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2011Cl31RLenght; r1++)
        projs2011Cl31RNumeros.push(Number(projs2011Cl31R[r1]))
        const projs2011Cl3Reais1 = projs2011Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl32R = projs2011Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl32RLenght = projs2011Cl32R.length
        var projs2011Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2011Cl32RLenght; r2++)
        projs2011Cl32RNumeros.push(Number(projs2011Cl32R[r2]))
        const projs2011Cl3Reais2 = projs2011Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2011Cl3 = (projs2011Cl3Reais1+projs2011Cl3Reais2)
        
        //2011Cl3 dolar
        const projs2011Cl31D = projs2011Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2011Cl31DLenght = projs2011Cl31D.length
        var projs2011Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2011Cl31DLenght; d1++)
        projs2011Cl31DNumeros.push(Number(projs2011Cl31D[d1]))
        const projs2011Cl3Dolar1 = projs2011Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2011Cl32D = projs2011Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2011Cl32DLenght = projs2011Cl32D.length
        var projs2011Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2011Cl32DLenght; d2++)
        projs2011Cl32DNumeros.push(Number(projs2011Cl32D[d2]))
        const projs2011Cl3Dolar2 = projs2011Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2011Cl3 = (projs2011Cl3Dolar1+projs2011Cl3Dolar2)
    
        //2012Cl3 reais
        const projs2012Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2012')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2012Cl31R = projs2012Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl31RLenght = projs2012Cl31R.length
        var projs2012Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2012Cl31RLenght; r1++)
        projs2012Cl31RNumeros.push(Number(projs2012Cl31R[r1]))
        const projs2012Cl3Reais1 = projs2012Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl32R = projs2012Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl32RLenght = projs2012Cl32R.length
        var projs2012Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2012Cl32RLenght; r2++)
        projs2012Cl32RNumeros.push(Number(projs2012Cl32R[r2]))
        const projs2012Cl3Reais2 = projs2012Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2012Cl3 = (projs2012Cl3Reais1+projs2012Cl3Reais2)
        
        //2012Cl3 dolar
        const projs2012Cl31D = projs2012Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2012Cl31DLenght = projs2012Cl31D.length
        var projs2012Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2012Cl31DLenght; d1++)
        projs2012Cl31DNumeros.push(Number(projs2012Cl31D[d1]))
        const projs2012Cl3Dolar1 = projs2012Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2012Cl32D = projs2012Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2012Cl32DLenght = projs2012Cl32D.length
        var projs2012Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2012Cl32DLenght; d2++)
        projs2012Cl32DNumeros.push(Number(projs2012Cl32D[d2]))
        const projs2012Cl3Dolar2 = projs2012Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2012Cl3 = (projs2012Cl3Dolar1+projs2012Cl3Dolar2)
        
        //2013Cl3 reais
        const projs2013Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2013')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2013Cl31R = projs2013Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl31RLenght = projs2013Cl31R.length
        var projs2013Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2013Cl31RLenght; r1++)
        projs2013Cl31RNumeros.push(Number(projs2013Cl31R[r1]))
        const projs2013Cl3Reais1 = projs2013Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl32R = projs2013Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl32RLenght = projs2013Cl32R.length
        var projs2013Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2013Cl32RLenght; r2++)
        projs2013Cl32RNumeros.push(Number(projs2013Cl32R[r2]))
        const projs2013Cl3Reais2 = projs2013Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2013Cl3 = (projs2013Cl3Reais1+projs2013Cl3Reais2)
        
        //2013Cl3 dolar
        const projs2013Cl31D = projs2013Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2013Cl31DLenght = projs2013Cl31D.length
        var projs2013Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2013Cl31DLenght; d1++)
        projs2013Cl31DNumeros.push(Number(projs2013Cl31D[d1]))
        const projs2013Cl3Dolar1 = projs2013Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2013Cl32D = projs2013Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2013Cl32DLenght = projs2013Cl32D.length
        var projs2013Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2013Cl32DLenght; d2++)
        projs2013Cl32DNumeros.push(Number(projs2013Cl32D[d2]))
        const projs2013Cl3Dolar2 = projs2013Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2013Cl3 = (projs2013Cl3Dolar1+projs2013Cl3Dolar2)
        
        //2014Cl3 reais
        const projs2014Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2014')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2014Cl31R = projs2014Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl31RLenght = projs2014Cl31R.length
        var projs2014Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2014Cl31RLenght; r1++)
        projs2014Cl31RNumeros.push(Number(projs2014Cl31R[r1]))
        const projs2014Cl3Reais1 = projs2014Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl32R = projs2014Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl32RLenght = projs2014Cl32R.length
        var projs2014Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2014Cl32RLenght; r2++)
        projs2014Cl32RNumeros.push(Number(projs2014Cl32R[r2]))
        const projs2014Cl3Reais2 = projs2014Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2014Cl3 = (projs2014Cl3Reais1+projs2014Cl3Reais2)
        
        //2014Cl3 dolar
        const projs2014Cl31D = projs2014Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2014Cl31DLenght = projs2014Cl31D.length
        var projs2014Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2014Cl31DLenght; d1++)
        projs2014Cl31DNumeros.push(Number(projs2014Cl31D[d1]))
        const projs2014Cl3Dolar1 = projs2014Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2014Cl32D = projs2014Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2014Cl32DLenght = projs2014Cl32D.length
        var projs2014Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2014Cl32DLenght; d2++)
        projs2014Cl32DNumeros.push(Number(projs2014Cl32D[d2]))
        const projs2014Cl3Dolar2 = projs2014Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2014Cl3 = (projs2014Cl3Dolar1+projs2014Cl3Dolar2)
        
        
        //2015Cl3 reais
        const projs2015Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2015')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2015Cl31R = projs2015Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl31RLenght = projs2015Cl31R.length
        var projs2015Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2015Cl31RLenght; r1++)
        projs2015Cl31RNumeros.push(Number(projs2015Cl31R[r1]))
        const projs2015Cl3Reais1 = projs2015Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl32R = projs2015Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl32RLenght = projs2015Cl32R.length
        var projs2015Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2015Cl32RLenght; r2++)
        projs2015Cl32RNumeros.push(Number(projs2015Cl32R[r2]))
        const projs2015Cl3Reais2 = projs2015Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2015Cl3 = (projs2015Cl3Reais1+projs2015Cl3Reais2)
        
        //2015Cl3 dolar
        const projs2015Cl31D = projs2015Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2015Cl31DLenght = projs2015Cl31D.length
        var projs2015Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2015Cl31DLenght; d1++)
        projs2015Cl31DNumeros.push(Number(projs2015Cl31D[d1]))
        const projs2015Cl3Dolar1 = projs2015Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2015Cl32D = projs2015Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2015Cl32DLenght = projs2015Cl32D.length
        var projs2015Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2015Cl32DLenght; d2++)
        projs2015Cl32DNumeros.push(Number(projs2015Cl32D[d2]))
        const projs2015Cl3Dolar2 = projs2015Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2015Cl3 = (projs2015Cl3Dolar1+projs2015Cl3Dolar2)
        
        //2016Cl3 reais
        const projs2016Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2016')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2016Cl31R = projs2016Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl31RLenght = projs2016Cl31R.length
        var projs2016Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2016Cl31RLenght; r1++)
        projs2016Cl31RNumeros.push(Number(projs2016Cl31R[r1]))
        const projs2016Cl3Reais1 = projs2016Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl32R = projs2016Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl32RLenght = projs2016Cl32R.length
        var projs2016Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2016Cl32RLenght; r2++)
        projs2016Cl32RNumeros.push(Number(projs2016Cl32R[r2]))
        const projs2016Cl3Reais2 = projs2016Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2016Cl3 = (projs2016Cl3Reais1+projs2016Cl3Reais2)
        
        //2016Cl3 dolar
        const projs2016Cl31D = projs2016Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2016Cl31DLenght = projs2016Cl31D.length
        var projs2016Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2016Cl31DLenght; d1++)
        projs2016Cl31DNumeros.push(Number(projs2016Cl31D[d1]))
        const projs2016Cl3Dolar1 = projs2016Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2016Cl32D = projs2016Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2016Cl32DLenght = projs2016Cl32D.length
        var projs2016Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2016Cl32DLenght; d2++)
        projs2016Cl32DNumeros.push(Number(projs2016Cl32D[d2]))
        const projs2016Cl3Dolar2 = projs2016Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2016Cl3 = (projs2016Cl3Dolar1+projs2016Cl3Dolar2)
        
        //2017Cl3 reais
        const projs2017Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2017')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2017Cl31R = projs2017Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl31RLenght = projs2017Cl31R.length
        var projs2017Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2017Cl31RLenght; r1++)
        projs2017Cl31RNumeros.push(Number(projs2017Cl31R[r1]))
        const projs2017Cl3Reais1 = projs2017Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl32R = projs2017Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl32RLenght = projs2017Cl32R.length
        var projs2017Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2017Cl32RLenght; r2++)
        projs2017Cl32RNumeros.push(Number(projs2017Cl32R[r2]))
        const projs2017Cl3Reais2 = projs2017Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2017Cl3 = (projs2017Cl3Reais1+projs2017Cl3Reais2)
        
        //2017Cl3 dolar
        const projs2017Cl31D = projs2017Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2017Cl31DLenght = projs2017Cl31D.length
        var projs2017Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2017Cl31DLenght; d1++)
        projs2017Cl31DNumeros.push(Number(projs2017Cl31D[d1]))
        const projs2017Cl3Dolar1 = projs2017Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2017Cl32D = projs2017Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2017Cl32DLenght = projs2017Cl32D.length
        var projs2017Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2017Cl32DLenght; d2++)
        projs2017Cl32DNumeros.push(Number(projs2017Cl32D[d2]))
        const projs2017Cl3Dolar2 = projs2017Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2017Cl3 = (projs2017Cl3Dolar1+projs2017Cl3Dolar2)
        
        //2018Cl3 reais
        const projs2018Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2018')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2018Cl31R = projs2018Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl31RLenght = projs2018Cl31R.length
        var projs2018Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2018Cl31RLenght; r1++)
        projs2018Cl31RNumeros.push(Number(projs2018Cl31R[r1]))
        const projs2018Cl3Reais1 = projs2018Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl32R = projs2018Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl32RLenght = projs2018Cl32R.length
        var projs2018Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2018Cl32RLenght; r2++)
        projs2018Cl32RNumeros.push(Number(projs2018Cl32R[r2]))
        const projs2018Cl3Reais2 = projs2018Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2018Cl3 = (projs2018Cl3Reais1+projs2018Cl3Reais2)
        
        //2018Cl3 dolar
        const projs2018Cl31D = projs2018Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2018Cl31DLenght = projs2018Cl31D.length
        var projs2018Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2018Cl31DLenght; d1++)
        projs2018Cl31DNumeros.push(Number(projs2018Cl31D[d1]))
        const projs2018Cl3Dolar1 = projs2018Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2018Cl32D = projs2018Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2018Cl32DLenght = projs2018Cl32D.length
        var projs2018Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2018Cl32DLenght; d2++)
        projs2018Cl32DNumeros.push(Number(projs2018Cl32D[d2]))
        const projs2018Cl3Dolar2 = projs2018Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2018Cl3 = (projs2018Cl3Dolar1+projs2018Cl3Dolar2)
        
        //2019Cl3 reais
        const projs2019Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2019')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2019Cl31R = projs2019Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl31RLenght = projs2019Cl31R.length
        var projs2019Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2019Cl31RLenght; r1++)
        projs2019Cl31RNumeros.push(Number(projs2019Cl31R[r1]))
        const projs2019Cl3Reais1 = projs2019Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl32R = projs2019Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl32RLenght = projs2019Cl32R.length
        var projs2019Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2019Cl32RLenght; r2++)
        projs2019Cl32RNumeros.push(Number(projs2019Cl32R[r2]))
        const projs2019Cl3Reais2 = projs2019Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2019Cl3 = (projs2019Cl3Reais1+projs2019Cl3Reais2)
        
        //2019Cl3 dolar
        const projs2019Cl31D = projs2019Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2019Cl31DLenght = projs2019Cl31D.length
        var projs2019Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2019Cl31DLenght; d1++)
        projs2019Cl31DNumeros.push(Number(projs2019Cl31D[d1]))
        const projs2019Cl3Dolar1 = projs2019Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2019Cl32D = projs2019Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2019Cl32DLenght = projs2019Cl32D.length
        var projs2019Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2019Cl32DLenght; d2++)
        projs2019Cl32DNumeros.push(Number(projs2019Cl32D[d2]))
        const projs2019Cl3Dolar2 = projs2019Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2019Cl3 = (projs2019Cl3Dolar1+projs2019Cl3Dolar2)
        
        //2020Cl3 reais
        const projs2020Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2020')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2020Cl31R = projs2020Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl31RLenght = projs2020Cl31R.length
        var projs2020Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2020Cl31RLenght; r1++)
        projs2020Cl31RNumeros.push(Number(projs2020Cl31R[r1]))
        const projs2020Cl3Reais1 = projs2020Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl32R = projs2020Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl32RLenght = projs2020Cl32R.length
        var projs2020Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2020Cl32RLenght; r2++)
        projs2020Cl32RNumeros.push(Number(projs2020Cl32R[r2]))
        const projs2020Cl3Reais2 = projs2020Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2020Cl3 = (projs2020Cl3Reais1+projs2020Cl3Reais2)
        
        //2020Cl3 dolar
        const projs2020Cl31D = projs2020Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2020Cl31DLenght = projs2020Cl31D.length
        var projs2020Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2020Cl31DLenght; d1++)
        projs2020Cl31DNumeros.push(Number(projs2020Cl31D[d1]))
        const projs2020Cl3Dolar1 = projs2020Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2020Cl32D = projs2020Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2020Cl32DLenght = projs2020Cl32D.length
        var projs2020Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2020Cl32DLenght; d2++)
        projs2020Cl32DNumeros.push(Number(projs2020Cl32D[d2]))
        const projs2020Cl3Dolar2 = projs2020Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2020Cl3 = (projs2020Cl3Dolar1+projs2020Cl3Dolar2)
        
        //2021Cl3 reais
        const projs2021Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2021')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2021Cl31R = projs2021Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl31RLenght = projs2021Cl31R.length
        var projs2021Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2021Cl31RLenght; r1++)
        projs2021Cl31RNumeros.push(Number(projs2021Cl31R[r1]))
        const projs2021Cl3Reais1 = projs2021Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl32R = projs2021Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl32RLenght = projs2021Cl32R.length
        var projs2021Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2021Cl32RLenght; r2++)
        projs2021Cl32RNumeros.push(Number(projs2021Cl32R[r2]))
        const projs2021Cl3Reais2 = projs2021Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2021Cl3 = (projs2021Cl3Reais1+projs2021Cl3Reais2)
        
        //2021Cl3 dolar
        const projs2021Cl31D = projs2021Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2021Cl31DLenght = projs2021Cl31D.length
        var projs2021Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2021Cl31DLenght; d1++)
        projs2021Cl31DNumeros.push(Number(projs2021Cl31D[d1]))
        const projs2021Cl3Dolar1 = projs2021Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2021Cl32D = projs2021Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2021Cl32DLenght = projs2021Cl32D.length
        var projs2021Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2021Cl32DLenght; d2++)
        projs2021Cl32DNumeros.push(Number(projs2021Cl32D[d2]))
        const projs2021Cl3Dolar2 = projs2021Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2021Cl3 = (projs2021Cl3Dolar1+projs2021Cl3Dolar2)
        
        //2022Cl3 reais
        const projs2022Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2022')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2022Cl31R = projs2022Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl31RLenght = projs2022Cl31R.length
        var projs2022Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2022Cl31RLenght; r1++)
        projs2022Cl31RNumeros.push(Number(projs2022Cl31R[r1]))
        const projs2022Cl3Reais1 = projs2022Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl32R = projs2022Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl32RLenght = projs2022Cl32R.length
        var projs2022Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2022Cl32RLenght; r2++)
        projs2022Cl32RNumeros.push(Number(projs2022Cl32R[r2]))
        const projs2022Cl3Reais2 = projs2022Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2022Cl3 = projs2022Cl3Reais1+projs2022Cl3Reais2
        
        //2022Cl3 dolar
        const projs2022Cl31D = projs2022Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2022Cl31DLenght = projs2022Cl31D.length
        var projs2022Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2022Cl31DLenght; d1++)
        projs2022Cl31DNumeros.push(Number(projs2022Cl31D[d1]))
        const projs2022Cl3Dolar1 = projs2022Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2022Cl32D = projs2022Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2022Cl32DLenght = projs2022Cl32D.length
        var projs2022Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2022Cl32DLenght; d2++)
        projs2022Cl32DNumeros.push(Number(projs2022Cl32D[d2]))
        const projs2022Cl3Dolar2 = projs2022Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2022Cl3 = projs2022Cl3Dolar1+projs2022Cl3Dolar2
        
        //2023Cl3 reais
        const projs2023Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2023')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2023Cl31R = projs2023Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl31RLenght = projs2023Cl31R.length
        var projs2023Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2023Cl31RLenght; r1++)
        projs2023Cl31RNumeros.push(Number(projs2023Cl31R[r1]))
        const projs2023Cl3Reais1 = projs2023Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl32R = projs2023Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl32RLenght = projs2023Cl32R.length
        var projs2023Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2023Cl32RLenght; r2++)
        projs2023Cl32RNumeros.push(Number(projs2023Cl32R[r2]))
        const projs2023Cl3Reais2 = projs2023Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2023Cl3 = projs2023Cl3Reais1+projs2023Cl3Reais2
        
        //2023Cl3 dolar
        const projs2023Cl31D = projs2023Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2023Cl31DLenght = projs2023Cl31D.length
        var projs2023Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2023Cl31DLenght; d1++)
        projs2023Cl31DNumeros.push(Number(projs2023Cl31D[d1]))
        const projs2023Cl3Dolar1 = projs2023Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2023Cl32D = projs2023Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2023Cl32DLenght = projs2023Cl32D.length
        var projs2023Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2023Cl32DLenght; d2++)
        projs2023Cl32DNumeros.push(Number(projs2023Cl32D[d2]))
        const projs2023Cl3Dolar2 = projs2023Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2023Cl3 = projs2023Cl3Dolar1+projs2023Cl3Dolar2
        
        
        //2024Cl3 reais
        const projs2024Cl3 = todos.filter((val)=>{if(val.data.projStartDate.includes('2024')&& val.data.projStatus1 !== 'deletado' && val.data.projJurisdictions.includes(cliente3)){return val}})
        const projs2024Cl31R = projs2024Cl3.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl31RLenght = projs2024Cl31R.length
        var projs2024Cl31RNumeros = [];
        for (var r1 = 0; r1 < projs2024Cl31RLenght; r1++)
        projs2024Cl31RNumeros.push(Number(projs2024Cl31R[r1]))
        const projs2024Cl3Reais1 = projs2024Cl31RNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl32R = projs2024Cl3.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl32RLenght = projs2024Cl32R.length
        var projs2024Cl32RNumeros = [];
        for (var r2 = 0; r2 < projs2024Cl32RLenght; r2++)
        projs2024Cl32RNumeros.push(Number(projs2024Cl32R[r2]))
        const projs2024Cl3Reais2 = projs2024Cl32RNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Reais2024Cl3 = projs2024Cl3Reais1+projs2024Cl3Reais2
        
        //2024Cl3 dolar
        const projs2024Cl31D = projs2024Cl3.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
        var projs2024Cl31DLenght = projs2024Cl31D.length
        var projs2024Cl31DNumeros = [];
        for (var d1 = 0; d1 < projs2024Cl31DLenght; d1++)
        projs2024Cl31DNumeros.push(Number(projs2024Cl31D[d1]))
        const projs2024Cl3Dolar1 = projs2024Cl31DNumeros.reduce((acc, curr) => acc + curr, 0)
            
        const projs2024Cl32D = projs2024Cl3.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
        var projs2024Cl32DLenght = projs2024Cl32D.length
        var projs2024Cl32DNumeros = [];
        for (var d2 = 0; d2 < projs2024Cl32DLenght; d2++)
        projs2024Cl32DNumeros.push(Number(projs2024Cl32D[d2]))
        const projs2024Cl3Dolar2 = projs2024Cl32DNumeros.reduce((acc, curr) => acc + curr, 0)
        
        const Dolar2024Cl3 = projs2024Cl3Dolar1+projs2024Cl3Dolar2
    
    
  
    setProjetData({
      labels: anos,
      datasets:[
       {
        label:`Faturamento Reais - ${cliente1}`,
        data: [Reais2002,Reais2003,Reais2004,Reais2005,Reais2006,Reais2007,Reais2008,Reais2009,Reais2010,Reais2011,Reais2012,Reais2013,Reais2014,Reais2015,Reais2016,Reais2017,Reais2018,Reais2019,Reais2020,Reais2021,Reais2022,Reais2023,Reais2024],
        fill: false,
        borderColor: '#FF9900',
        backgroundColor: 'rgba(255,153,0,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente1}`,
        data: [Dolar2002,Dolar2003,Dolar2004,Dolar2005,Dolar2006,Dolar2007,Dolar2008,Dolar2009,Dolar2010,Dolar2011,Dolar2012,Dolar2013,Dolar2014,Dolar2015,Dolar2016,Dolar2017,Dolar2018,Dolar2019,Dolar2020,Dolar2021,Dolar2022,Dolar2023,Dolar2024],
        fill: false,
        borderColor: '#FCD08F',
        backgroundColor: 'rgba(252,208,143,.5)',
      },
      {
        label:`Faturamento Reais - ${cliente2}`,
        data: [Reais2002Cl2,Reais2003Cl2,Reais2004Cl2,Reais2005Cl2,Reais2006Cl2,Reais2007Cl2,Reais2008Cl2,Reais2009Cl2,Reais2010Cl2,Reais2011Cl2,Reais2012Cl2,Reais2013Cl2,Reais2014Cl2,Reais2015Cl2,Reais2016Cl2,Reais2017Cl2,Reais2018Cl2,Reais2019Cl2,Reais2020Cl2,Reais2021Cl2,Reais2022Cl2,Reais2023Cl2,Reais2024Cl2],
        fill: false,
        borderColor: '#42ADA7',
        backgroundColor: 'rgba(66,173,167,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente2}`,
        data: [Dolar2002Cl2,Dolar2003Cl2,Dolar2004Cl2,Dolar2005Cl2,Dolar2006Cl2,Dolar2007Cl2,Dolar2008Cl2,Dolar2009Cl2,Dolar2010Cl2,Dolar2011Cl2,Dolar2012Cl2,Dolar2013Cl2,Dolar2014Cl2,Dolar2015Cl2,Dolar2016Cl2,Dolar2017Cl2,Dolar2018Cl2,Dolar2019Cl2,Dolar2020Cl2,Dolar2021Cl2,Dolar2022Cl2,Dolar2023Cl2,Dolar2024Cl2],
        fill: false,
        borderColor: '#9EAD42',
        backgroundColor: 'rgba(158,173,66,.5)',
      },
      {
        label:`Faturamento Reais - ${cliente3}`,
        data: [Reais2002Cl3,Reais2003Cl3,Reais2004Cl3,Reais2005Cl3,Reais2006Cl3,Reais2007Cl3,Reais2008Cl3,Reais2009Cl3,Reais2010Cl3,Reais2011Cl3,Reais2012Cl3,Reais2013Cl3,Reais2014Cl3,Reais2015Cl3,Reais2016Cl3,Reais2017Cl3,Reais2018Cl3,Reais2019Cl3,Reais2020Cl3,Reais2021Cl3,Reais2022Cl3,Reais2023Cl3,Reais2024Cl3],
        fill: false,
        borderColor: '#0DA1B1',
        backgroundColor: 'rgba(13,161,177,.5)',
      },
      {
        label:`Faturamento Dólares - ${cliente3}`,
        data: [Dolar2002Cl3,Dolar2003Cl3,Dolar2004Cl3,Dolar2005Cl3,Dolar2006Cl3,Dolar2007Cl3,Dolar2008Cl3,Dolar2009Cl3,Dolar2010Cl3,Dolar2011Cl3,Dolar2012Cl3,Dolar2013Cl3,Dolar2014Cl3,Dolar2015Cl3,Dolar2016Cl3,Dolar2017Cl3,Dolar2018Cl3,Dolar2019Cl3,Dolar2020Cl3,Dolar2021Cl3,Dolar2022Cl3,Dolar2023Cl3,Dolar2024Cl3],
        fill: false,
        borderColor: '#11D5EB',
        backgroundColor: 'rgba(17,213,235,.5)',
      }
      
  
     ],
     
     
    })
    setGrafico(true);
  }
  return (
    <div>
    <Header />
    {loading &&
       <div className="pageTitle">
       <div>Carregando...</div>
     </div>
    }
    {!loading &&
      <>
      <div className="pageTitle">
        <div>Jurisdições - Faturamento em Ano</div>
        {
            !paislist &&
            <div><button onClick={lista}>Gerar Lista</button></div>
           }
           
            {paislist  &&
            <>
        <div>
            <p>
                Tipo:
                <select defaultValue={'um'} onChange={(e)=>{setTipo(e.target.value); setGrafico(false); setCliente1(''); setCliente2(''); setCliente3('')}}>
                <option value="">Selecione</option>
                <option value="um">Uma Jurisdição</option>
                <option value="dois">Duas Jurisdições</option>
                <option value="tres">Três Jurisdições</option>
            </select>
            </p>
        </div>
        {tipo === 'um' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select defaultValue={'2024'} onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' &&
                <div><button onClick={handleChart}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'dois' &&
            <div className='tipo'>
               <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' &&
                <div><button onClick={handleChart2}>Gerar Gráfico</button></div>
            }
            </div>
        }
        {tipo === 'tres' &&
          
            <div className='tipo'>
              <div className='selecionar'>
                <p>Selecione um ano:</p>
                <select onChange={(e)=>{setAno(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {anos.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente1(e.target.value); setGrafico(false)}}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente2(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            <div className='selecionar'>
                <p>Selecione uma Jurisdição:</p>
                <select onChange={(e)=>{setCliente3(e.target.value); setGrafico(false); }}>
                    <option value="">Selecione</option>
                    {clientes.map((item, index)=>(
                        <option value={item} key={index}>{item}</option>

                    ))}
                    
                </select>
            </div>
            {cliente1 !== '' && cliente2 !== '' && cliente3 !== '' &&
                <div><button onClick={handleChart3}>Gerar Gráfico</button></div>
            }
            </div>
        }
       </>}
        
      </div>
      {grafico &&
        <div className="pageContent">
        <div className="pageChart">
          <LineChart chartData={projectData} />
        </div>
      </div>
      }

      </>     
    }
</div>
  )
}

export default JurisdicoesAnoFaturamento