import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import BarChart from '../../components/Charts/BarChart';
import ProjetosHistorico from '../../Json/ProjetosHistorico.json'
import Header from '../../components/Header/Header'
import '../pages.css'

function QuatroAnos() {
    const [projetos, setProjetos]=useState([]);
    const [loading, setLoading]=useState(true);
    const [projectData, setProjetData]=useState({})
    const [todos , setTodos]=useState([])
    const [grafico, setGrafico]=useState(false)

    const hoje = new Date()
    const ano = hoje.getFullYear()

    useEffect(()=>{
      const q = query(projectsCollectionRef);
      setLoading(true)
      const change = onSnapshot(q, snapshot=>{
          setProjetos(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
        setLoading(false)
      })
      return()=>{change()}
  
   },[]);



 useLayoutEffect(()=>{
  setLoading(true)
    setTodos(projetos.concat(ProjetosHistorico))
    setLoading(false)
  },[projetos])



  const handleChart = ()=>{

    setGrafico(false)
        
    const anos = [`${ano-4}`, `${ano-3}`, `${ano-2}`, `${ano-1}` , `${ano}`];

    const Num2001 = todos.filter((val)=>{if(val.data.projStartDate.includes(`${ano-4}`)&& val.data.projStatus1 !== 'deletado'){return val}})
    const Num2002 = todos.filter((val)=>{if(val.data.projStartDate.includes(`${ano-3}`)&& val.data.projStatus1 !== 'deletado'){return val}})
    const Num2003 = todos.filter((val)=>{if(val.data.projStartDate.includes(`${ano-2}`)&& val.data.projStatus1 !== 'deletado'){return val}})
    const Num2004 = todos.filter((val)=>{if(val.data.projStartDate.includes(`${ano-1}`) && val.data.projStatus1 !== 'deletado'){return val}})
    const Num2005 = todos.filter((val)=>{if(val.data.projStartDate.includes(`${ano}`)&& val.data.projStatus1 !== 'deletado'){return val}})
    
    //QUANTITATIVO
    const L2001 = Num2001.length
    const L2002 = Num2002.length
    const L2003 = Num2003.length
    const L2004 = Num2004.length
    const L2005 = Num2005.length

    //SOMAS
//ANO -4
const reaisAnoQuatro = Num2001.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var preaisAnoQuatroL = reaisAnoQuatro.length
  var preaisAnoQuatroNumeros1 = [];
  for (var r1 = 0; r1 < preaisAnoQuatroL; r1++)
  preaisAnoQuatroNumeros1.push(Number(reaisAnoQuatro[r1]))
  const reaisAnoQuatroTotal1 = preaisAnoQuatroNumeros1.reduce((acc, curr) => acc + curr, 0)

  const reaisAnoQuatro2 = Num2001.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var preaisAnoQuatroL2 = reaisAnoQuatro2.length
  var preaisAnoQuatroNumeros2 = [];
  for (var r2 = 0; r2 < preaisAnoQuatroL2; r2++)
  preaisAnoQuatroNumeros2.push(Number(reaisAnoQuatro2[r2]))
  const reaisAnoQuatroTotal2 = preaisAnoQuatroNumeros2.reduce((acc, curr) => acc + curr, 0)

  const reaisAnoQuatroTotal = (reaisAnoQuatroTotal1+reaisAnoQuatroTotal2)

  const dolarAnoQuatro = Num2001.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pdolarAnoQuatroL = dolarAnoQuatro.length
    var pdolarAnoQuatroNumeros1 = [];
    for (var r1 = 0; r1 < pdolarAnoQuatroL; r1++)
    pdolarAnoQuatroNumeros1.push(Number(dolarAnoQuatro[r1]))
    const dolarAnoQuatroTotal1 = pdolarAnoQuatroNumeros1.reduce((acc, curr) => acc + curr, 0)

    const dolarAnoQuatro2 = Num2001.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pdolarAnoQuatroL2 = dolarAnoQuatro2.length
    var pdolarAnoQuatroNumeros2 = [];
    for (var r2 = 0; r2 < pdolarAnoQuatroL2; r2++)
    pdolarAnoQuatroNumeros2.push(Number(dolarAnoQuatro2[r2]))
    const dolarAnoQuatroTotal2 = pdolarAnoQuatroNumeros2.reduce((acc, curr) => acc + curr, 0)

    const dolarAnoQuatroTotal = (dolarAnoQuatroTotal1+dolarAnoQuatroTotal2)

  // ANO -3

  const reaisAnoTres = Num2002.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var preaisAnoTresL = reaisAnoTres.length
  var preaisAnoTresNumeros1 = [];
  for (var r1 = 0; r1 < preaisAnoTresL; r1++)
  preaisAnoTresNumeros1.push(Number(reaisAnoTres[r1]))
  const reaisAnoTresTotal1 = preaisAnoTresNumeros1.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoTres2 = Num2002.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var preaisAnoTresL2 = reaisAnoTres2.length
  var preaisAnoTresNumeros2 = [];
  for (var r2 = 0; r2 < preaisAnoTresL2; r2++)
  preaisAnoTresNumeros2.push(Number(reaisAnoTres2[r2]))
  const reaisAnoTresTotal2 = preaisAnoTresNumeros2.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoTresTotal = (reaisAnoTresTotal1+reaisAnoTresTotal2)

  const dolarAnoTres = Num2002.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pdolarAnoTresL = dolarAnoTres.length
    var pdolarAnoTresNumeros1 = [];
    for (var r1 = 0; r1 < pdolarAnoTresL; r1++)
    pdolarAnoTresNumeros1.push(Number(dolarAnoTres[r1]))
    const dolarAnoTresTotal1 = pdolarAnoTresNumeros1.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoTres2 = Num2002.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pdolarAnoTresL2 = dolarAnoTres2.length
    var pdolarAnoTresNumeros2 = [];
    for (var r2 = 0; r2 < pdolarAnoTresL2; r2++)
    pdolarAnoTresNumeros2.push(Number(dolarAnoTres2[r2]))
    const dolarAnoTresTotal2 = pdolarAnoTresNumeros2.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoTresTotal = (dolarAnoTresTotal1+dolarAnoTresTotal2)

  //ANO -2
  const reaisAnoDois = Num2003.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var preaisAnoDoisL = reaisAnoDois.length
  var preaisAnoDoisNumeros1 = [];
  for (var r1 = 0; r1 < preaisAnoDoisL; r1++)
  preaisAnoDoisNumeros1.push(Number(reaisAnoDois[r1]))
  const reaisAnoDoisTotal1 = preaisAnoDoisNumeros1.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoDois2 = Num2003.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var preaisAnoDoisL2 = reaisAnoDois2.length
  var preaisAnoDoisNumeros2 = [];
  for (var r2 = 0; r2 < preaisAnoDoisL2; r2++)
  preaisAnoDoisNumeros2.push(Number(reaisAnoDois2[r2]))
  const reaisAnoDoisTotal2 = preaisAnoDoisNumeros2.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoDoisTotal = (reaisAnoDoisTotal1+reaisAnoDoisTotal2)

  const dolarAnoDois = Num2003.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pdolarAnoDoisL = dolarAnoDois.length
    var pdolarAnoDoisNumeros1 = [];
    for (var r1 = 0; r1 < pdolarAnoDoisL; r1++)
    pdolarAnoDoisNumeros1.push(Number(dolarAnoDois[r1]))
    const dolarAnoDoisTotal1 = pdolarAnoDoisNumeros1.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoDois2 = Num2003.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pdolarAnoDoisL2 = dolarAnoDois2.length
    var pdolarAnoDoisNumeros2 = [];
    for (var r2 = 0; r2 < pdolarAnoDoisL2; r2++)
    pdolarAnoDoisNumeros2.push(Number(dolarAnoDois2[r2]))
    const dolarAnoDoisTotal2 = pdolarAnoDoisNumeros2.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoDoisTotal = (dolarAnoDoisTotal1+dolarAnoDoisTotal2)

  //ANO -1

  const reaisAnoUm = Num2004.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var preaisAnoUmL = reaisAnoUm.length
  var preaisAnoUmNumeros1 = [];
  for (var r1 = 0; r1 < preaisAnoUmL; r1++)
  preaisAnoUmNumeros1.push(Number(reaisAnoUm[r1]))
  const reaisAnoUmTotal1 = preaisAnoUmNumeros1.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoUm2 = Num2004.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var preaisAnoUmL2 = reaisAnoUm2.length
  var preaisAnoUmNumeros2 = [];
  for (var r2 = 0; r2 < preaisAnoUmL2; r2++)
  preaisAnoUmNumeros2.push(Number(reaisAnoUm2[r2]))
  const reaisAnoUmTotal2 = preaisAnoUmNumeros2.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoUmTotal = (reaisAnoUmTotal1+reaisAnoUmTotal2)

  const dolarAnoUm = Num2004.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pdolarAnoUmL = dolarAnoUm.length
    var pdolarAnoUmNumeros1 = [];
    for (var r1 = 0; r1 < pdolarAnoUmL; r1++)
    pdolarAnoUmNumeros1.push(Number(dolarAnoUm[r1]))
    const dolarAnoUmTotal1 = pdolarAnoUmNumeros1.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoUm2 = Num2004.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pdolarAnoUmL2 = dolarAnoUm2.length
    var pdolarAnoUmNumeros2 = [];
    for (var r2 = 0; r2 < pdolarAnoUmL2; r2++)
    pdolarAnoUmNumeros2.push(Number(dolarAnoUm2[r2]))
    const dolarAnoUmTotal2 = pdolarAnoUmNumeros2.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoUmTotal = (dolarAnoUmTotal1+dolarAnoUmTotal2)

  //ANO ZERO

  const reaisAnoZero = Num2005.filter((val)=>{if(val.data.projCurrency === 'BRL'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var preaisAnoZeroL = reaisAnoZero.length
  var preaisAnoZeroNumeros1 = [];
  for (var r1 = 0; r1 < preaisAnoZeroL; r1++)
  preaisAnoZeroNumeros1.push(Number(reaisAnoZero[r1]))
  const reaisAnoZeroTotal1 = preaisAnoZeroNumeros1.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoZero2 = Num2005.filter((val)=>{if(val.data.proj2Currency === 'BRL'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var preaisAnoZeroL2 = reaisAnoZero2.length
  var preaisAnoZeroNumeros2 = [];
  for (var r2 = 0; r2 < preaisAnoZeroL2; r2++)
  preaisAnoZeroNumeros2.push(Number(reaisAnoZero2[r2]))
  const reaisAnoZeroTotal2 = preaisAnoZeroNumeros2.reduce((acc, curr) => acc + curr, 0)
  
  const reaisAnoZeroTotal = (reaisAnoZeroTotal1+reaisAnoZeroTotal2)

  const dolarAnoZero = Num2005.filter((val)=>{if(val.data.projCurrency === 'USD'){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var pdolarAnoZeroL = dolarAnoZero.length
    var pdolarAnoZeroNumeros1 = [];
    for (var r1 = 0; r1 < pdolarAnoZeroL; r1++)
    pdolarAnoZeroNumeros1.push(Number(dolarAnoZero[r1]))
    const dolarAnoZeroTotal1 = pdolarAnoZeroNumeros1.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoZero2 = Num2005.filter((val)=>{if(val.data.proj2Currency === 'USD'){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    var pdolarAnoZeroL2 = dolarAnoZero2.length
    var pdolarAnoZeroNumeros2 = [];
    for (var r2 = 0; r2 < pdolarAnoZeroL2; r2++)
    pdolarAnoZeroNumeros2.push(Number(dolarAnoZero2[r2]))
    const dolarAnoZeroTotal2 = pdolarAnoZeroNumeros2.reduce((acc, curr) => acc + curr, 0)
    
    const dolarAnoZeroTotal = (dolarAnoZeroTotal1+dolarAnoZeroTotal2)
  

   
    setProjetData({
        labels: anos,
        datasets:[
        {
          label:'Faturamento Reais',
          data: [reaisAnoQuatroTotal, reaisAnoTresTotal, reaisAnoDoisTotal, reaisAnoUmTotal, reaisAnoZeroTotal],
          fill: true,
          borderColor: '#FF9900',
          backgroundColor: '#FF9900',
        },
        {
          label:'Faturamento Dólares',
          data: [dolarAnoQuatroTotal, dolarAnoTresTotal, dolarAnoDoisTotal, dolarAnoUmTotal, dolarAnoZeroTotal],
          fill: true,
          borderColor: '#9EAD42',
          backgroundColor: '#9EAD42',
        },
        {
          label:'Quantidade de Projetos',
          data: [L2001, L2002, L2003, L2004, L2005],
          fill: true,
          borderColor: '#FF5400',
          backgroundColor: '#FF5400',
        }


      ]
      })
      setGrafico(true);
  }

  return (
    <div>
        <Header />
        {loading &&
           <div className="pageTitle">
           <div>Carregando...</div>
         </div>
        }
        {!loading &&
          <>
          <div className="pageTitle">
            <div>Quantitativo e Faturamento de Projetos Últimos 5 Anos</div>
            <div><button onClick={handleChart}>Gerar Gráfico</button></div>
          </div>
          {grafico &&
            <div className="pageContent">
            <div className="pageChart">
              <BarChart chartData={projectData} />
            </div>
          </div>
          }
          
          </>     
        }
    </div>
  )
}

export default QuatroAnos