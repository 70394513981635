import React,{useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef, custosCollectionRef} from '../../services/fireref';
import {  onSnapshot, query, where} from "firebase/firestore"
import Header from '../../components/Header/Header'
import '../pages.css'

function Home() {
  const [projetos, setProjetos]=useState([]);
  const [custos, setCustos]=useState([]);
  const [loading, setLoading]=useState(true);
 
  const [dolarAno, setDolarAno]=useState(0)
  const [reaisAno, setReaisAno]=useState(0)
  const [dolarMes, setDolarMes]=useState(0)
  const [reaisMes, setReaisMes]=useState(0)
  const [reaisAndamento, setReaisAndamento]=useState(0)
  const [dolarAndamento, setDolarAndamento]=useState(0)
  const [dolarReceber, setDolarReceber]=useState(0)
  const [reaisReceber, setReaisReceber]=useState(0)
  const [reaisFaturado, setReaisFaturado]=useState(0)
  const [dolarFaturado, setDolarFaturado]=useState(0)


  const [reaisRecebidos,setReaisRecebido]=useState(0)
  const [dolarRecebido, setDolarRecebido]=useState(0)


    

  const [grafico, setGrafico]=useState(false)
  const [grafico2, setGrafico2]=useState(false)
  const [lider, setLider]=useState([])
  const [liderP, setLiderP]=useState([])
  const [analistas, setAnalistas]=useState([])
  const [analistasP, setAnalistasP]=useState([])
  const [clientes, setCLientes]=useState([])
  const [clientesP, setClientesP]=useState([])
  const [pais, setPais]=useState([])
  const [paisP, setPaisP]=useState([])
  const [jurisdicoes, setJuridicoes]=useState([])
  const [jurisdicoesP, setJurisdicoesP]=useState([])
  const hoje = new Date()
  const mesAtualTxt = hoje.toLocaleString('default', { month: 'long' })
  const anoAtual = hoje.getFullYear()
  const mesAtual= hoje.getMonth()
  const MaesAtualDois= (mesAtual+1).toString().padStart(2,0)

  const data2A = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
  const data2 = data2A.toISOString().slice(8, 10)

 
  useEffect(()=>{
    const q = query(projectsCollectionRef);
    const r = query(custosCollectionRef, where('ano', '==', `${anoAtual.toString()}`));
    setLoading(true)
    const change = onSnapshot(q, snapshot=>{
        setProjetos(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      setLoading(false)
    })
    const change2 = onSnapshot(r, snapshot=>{
      setCustos(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
    setLoading(false)
  })
    return()=>{change(); change2()}

 },[]);

 const [balanco, setBalanco]=useState(false)
const [mesBalanco, setMesdalanco]=useState('')
const [mesBtexto, setMesBtexto]=useState('')

 useLayoutEffect(()=>{

  setGrafico(false)
  

  //ANO
  let projetosAno = projetos.filter((val)=>{if(val.data.projStartDate.includes(anoAtual) && val.data.projStatus1 !== 'deletado' ){return val}})
  let reaislist = projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let reaislist2 = projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2 = reaislist2.length;
  var reaisNumeros2 = [];
  for (var i2 = 0; i2 < length2; i2++)
  reaisNumeros2.push(Number(reaislist2[i2]))

  let reaisTotalAno = (reaisNumeros.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2.reduce((acc, curr) => acc + curr, 0))

  let dolarlist =projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var a = 0; a < lengthD; a++)
  dolarNumeros.push(Number(dolarlist[a]))

  let dolarlist2 =projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2 = dolarlist2.length;
  var dolarNumeros2 = [];
  for (var a2 = 0; a2 < lengthD2; a2++)
  dolarNumeros2.push(Number(dolarlist2[a2]))
  let dolarTotalAno = (dolarNumeros.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2.reduce((acc, curr) => acc + curr, 0))



  setReaisAno(reaisTotalAno)
  setDolarAno(dolarTotalAno)
 

  //MES

  let projetosMes = projetos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-${MaesAtualDois}-01` && val.data.projStartDate <= `${anoAtual}-${MaesAtualDois}-${data2}` && val.data.projStatus1 !== 'deletado' ){return val}})

  

  let reaislistMes = projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthRM = reaislistMes.length;
  var reaisNumerosMes = [];
  for (var i3 = 0; i3 < lengthRM; i3++)
  reaisNumerosMes.push(Number(reaislistMes[i3]))

  let reaislist2Mes = projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var  length2Mes = reaislist2Mes.length;
  var reaisNumeros2Mes = [];
  for (var i4 = 0; i4 < length2Mes; i4++)
  reaisNumeros2Mes.push(Number(reaislist2Mes[i4]))

  let reaisTotalMes = (reaisNumerosMes.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2Mes.reduce((acc, curr) => acc + curr, 0))

  let dolarlistMes =projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDMes = dolarlistMes.length;
  var dolarNumerosMes = [];
  for (var a3 = 0; a3 < lengthDMes; a3++)
  dolarNumerosMes.push(Number(dolarlistMes[a3]))

  let dolarlist2Mes =projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2Mes = dolarlist2Mes.length;
  var dolarNumeros2Mes = [];
  for (var a4 = 0; a4 < lengthD2Mes; a4++)
  dolarNumeros2Mes.push(Number(dolarlist2Mes[a4]))
  let dolarTotalMes = (dolarNumerosMes.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2Mes.reduce((acc, curr) => acc + curr, 0))

  setReaisMes(reaisTotalMes)
  setDolarMes(dolarTotalMes)

  //EM ANDAMENTO
  let projetosAndamento = projetos.filter((val)=>{if(val.data.projStatus1 === 'Em Andamento' ){return val}})

  let reaislistAn = projetosAndamento.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthAn = reaislistAn.length;
  var reaisNumerosAn = [];
  for (var ia = 0; ia < lengthAn; ia++)
  reaisNumerosAn.push(Number(reaislistAn[ia]))

  let reaislist2An = projetosAndamento.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2An = reaislist2An.length;
  var reaisNumeros2An = [];
  for (var ia2 = 0; ia2 < length2An; ia2++)
  reaisNumeros2An.push(Number(reaislist2An[ia2]))

  let reaisTotalAndamento = (reaisNumerosAn.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2An.reduce((acc, curr) => acc + curr, 0))

  

  let dolarlistAn =projetosAndamento.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDAn = dolarlistAn.length;
  var dolarNumerosAn = [];
  for (var aA = 0; aA < lengthDAn; aA++)
  dolarNumerosAn.push(Number(dolarlistAn[aA]))

  let dolarlist2An =projetosAndamento.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2An = dolarlist2An.length;
  var dolarNumeros2An = [];
  for (var aA2 = 0; aA2 < lengthD2An; aA2++)
  dolarNumeros2An.push(Number(dolarlist2An[aA2]))
  let dolarTotalAndamento = (dolarNumerosAn.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2An.reduce((acc, curr) => acc + curr, 0))

  setReaisAndamento(reaisTotalAndamento)
  setDolarAndamento(dolarTotalAndamento)


  // A RECEBER

  let projetosReceber = projetos.filter((val)=>{if(val.data.projeStatus2 === 'A Receber' ||  val.data.projeStatus2 === 'A Cobrar'){return val}})
  let reaislistR = projetosReceber.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthR = reaislistR.length;
  var reaisNumerosR = [];
  for (var ir = 0; ir < lengthR; ir++)
  reaisNumerosR.push(Number(reaislistR[ir]))

  let reaislist2R = projetosReceber.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2R = reaislist2R.length;
  var reaisNumeros2R = [];
  for (var ir2 = 0; ir2 < length2R; ir2++)
  reaisNumeros2R.push(Number(reaislist2R[ir2]))

  let reaisTotalReceber = (reaisNumerosR.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2R.reduce((acc, curr) => acc + curr, 0))

  let dolarlistR =projetosReceber.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDR = dolarlistR.length;
  var dolarNumerosR = [];
  for (var ar = 0; ar < lengthDR; ar++)
  dolarNumerosR.push(Number(dolarlistR[ar]))

  let dolarlist2R =projetosReceber.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2R = dolarlist2R.length;
  var dolarNumeros2R = [];
  for (var a2r = 0; a2r < lengthD2R; a2r++)
  dolarNumeros2R.push(Number(dolarlist2R[a2r]))
  let dolarTotalReceber = (dolarNumerosR.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2R.reduce((acc, curr) => acc + curr, 0))

  setReaisReceber(reaisTotalReceber)
  setDolarReceber(dolarTotalReceber)

   // RECEBIDO

   let projetosRecebidos = projetos.filter((val)=>{if(val.data.projeStatus2 === 'Recebido'){return val}})
    let reaislistRe = projetosRecebidos.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  lengthRe = reaislistRe.length;
    var reaisNumerosRe = [];
    for (var ir = 0; ir < lengthRe; ir++)
    reaisNumerosRe.push(Number(reaislistRe[ir]))
  
    let reaislist2Re= projetosRecebidos.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  
    var  length2Re = reaislist2Re.length;
    var reaisNumeros2Re = [];
    for (var ir2 = 0; ir2 < length2Re; ir2++)
    reaisNumeros2Re.push(Number(reaislist2Re[ir2]))
  
    let reaisTotalRecebidos = (reaisNumerosRe.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2Re.reduce((acc, curr) => acc + curr, 0))
  
    let dolarlistRe =projetosRecebidos.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
    var  lengthDRe = dolarlistRe.length;
    var dolarNumerosRe = [];
    for (var ar = 0; ar < lengthDRe; ar++)
    dolarNumerosRe.push(Number(dolarlistRe[ar]))
  
    let dolarlist2Re =projetosRecebidos.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  
    var  lengthD2Re = dolarlist2Re.length;
    var dolarNumeros2Re = [];
    for (var a2r = 0; a2r < lengthD2Re; a2r++)
    dolarNumeros2Re.push(Number(dolarlist2R[a2r]))
    let dolarTotalRecebido = (dolarNumerosRe.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2Re.reduce((acc, curr) => acc + curr, 0))
  
    setReaisRecebido(reaisTotalRecebidos)   
    setDolarRecebido(dolarTotalRecebido)

 
  //lideres
  let lideresAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projLideres? val.data.projLideres:''){return val}}).map((i)=>i.data.projLideres.map((i)=>i.name))
  let lideresNomes = lideresAndamento.reduce((list, sub) => list.concat(sub), [])

  let LideresemDuplicados = lideresNomes.filter((v2, i2, s2) => {
    return s2.indexOf(v2) === i2;
  });


  setLiderP(
    LideresemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": lideresNomes.filter((val)=>{if(val === item){return val}}).length

}
))
)

setLider(liderP.sort((g,h)=>{
    if (g.quantidade < h.quantidade) {
      return 1;
    }
    if (g.quantidade > h.quantidade) {
      return -1;
    }
    return 0;
    }))


  //analistas
  let analistasAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projAnalistas? val.data.projAnalistas:''){return val}}).map((i)=>i.data.projAnalistas.map((i)=>i.name))
  let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])

  let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
    return s1.indexOf(v1) === i1;
  });

  
  setAnalistasP(
    analistasemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": analistasNomes.filter((val)=>{if(val === item){return val}}).length

}
))
)

setAnalistas(analistasP.sort((e,f)=>{
    if (e.quantidade < f.quantidade) {
      return 1;
    }
    if (e.quantidade > f.quantidade) {
      return -1;
    }
    return 0;
    }))


  
  

  //clientes
  let clientesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projClient)
  let clientesemDuplicados = clientesAndamento.filter((valor, indice, self) => {
    return self.indexOf(valor) === indice;
  });

     
  setClientesP(
        clientesemDuplicados.map((item, index)=>(
      {
        "id": index,
        "name":item,
        "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length

    }
  ))
  )

  setCLientes(clientesP.sort((a,b)=>{
    if (a.quantidade < b.quantidade) {
      return 1;
    }
    if (a.quantidade > b.quantidade) {
      return -1;
    }
    return 0;
  }))

  //paises

  let paisesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projHiringCountry)
  let paissemDuplicados = paisesAndamento.filter((v, i, s) => {
    return s.indexOf(v) === i;
  });


  setPaisP(
    paissemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": paisesAndamento.filter((val)=>{if(val === item){return val}}).length

}
))
)

    setPais(paisP.sort((c,d)=>{
    if (c.quantidade < d.quantidade) {
      return 1;
    }
    if (c.quantidade > d.quantidade) {
      return -1;
    }
    return 0;
    }))

    //Jurisdições
    let jurisdicoesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projJurisdictions)
      let juriscicoesNomes = jurisdicoesAndamento.reduce((list, sub) => list.concat(sub), [])
   
      let jurisdicoesSemDuplicados = juriscicoesNomes.filter((v1, i1, s1) => {
        return s1.indexOf(v1) === i1;
      });
    
      
      setJurisdicoesP(
        jurisdicoesSemDuplicados.map((item, index)=>(
      {
        "id": index,
        "name":item,
        "quantidade": juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length
    
    }
    ))
    )

    
    setJuridicoes(jurisdicoesP.sort((e,f)=>{
        if (e.quantidade < f.quantidade) {
          return 1;
        }
        if (e.quantidade > f.quantidade) {
          return -1;
        }
        return 0;
        }))

        //BALANÇO

        let periodo = custos.map((i)=>(i.data.date))
        let periodoMes = periodo[0]
         setMesBtexto(periodoMes)
        setMesdalanco( ()=>{
         if(periodoMes === 'Janeiro'){
           setMesdalanco('01')
         }
         if(periodoMes === 'Fevereiro'){
           setMesdalanco('02')
         }
         if(periodoMes === 'Março'){
           setMesdalanco('03')
         }
         if(periodoMes === 'Abril'){
           setMesdalanco('04')
         }
         if(periodoMes === 'Maio'){
           setMesdalanco('05')
         }
         if(periodoMes === 'Junho'){
           setMesdalanco('06')
         }
         if(periodoMes === 'Julho'){
           setMesdalanco('07')
         }
         if(periodoMes === 'Agosto'){
           setMesdalanco('08')
         }
         if(periodoMes === 'Setembro'){
           setMesdalanco('09')
         }
         if(periodoMes === 'Outubro'){
           setMesdalanco('10')
         }
         if(periodoMes === 'Novembro'){
           setMesdalanco('11')
         }
         if(periodoMes === 'Dezembro'){
           setMesdalanco('12')
         }
       }
        )
        let projetosPeriodo = projetos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-01-01` && val.data.projStartDate <= `${anoAtual}-${mesBalanco}-30` && val.data.projStatus1 !== 'deletado' ){return val}})
     
         let reaislista = projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
           var  lengthP = reaislista.length;
           var reaisNumerosP = [];
           for (var i = 0; i < lengthP; i++)
           reaisNumerosP.push(Number(reaislista[i]))
         
           let reaislista2 = projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
         
           var  length2P = reaislista2.length;
           var reaisNumeros2P = [];
           for (var i2 = 0; i2 < length2P; i2++)
           reaisNumeros2P.push(Number(reaislista2[i2]))
         
           let reaisTotalAnoP = (reaisNumerosP.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2P.reduce((acc, curr) => acc + curr, 0))
         
           let dolarlistP =projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
           var  lengthDP = dolarlistP.length;
           var dolarNumerosP = [];
           for (var a = 0; a < lengthDP; a++)
           dolarNumerosP.push(Number(dolarlistP[a]))
         
           let dolarlistP2 =projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
         
           var  lengthDP2 = dolarlistP2.length;
           var dolarNumerosP2 = [];
           for (var a2 = 0; a2 < lengthDP2; a2++)
           dolarNumerosP2.push(Number(dolarlistP2[a2]))
           let dolarTotalAnoP = (dolarNumerosP.reduce((acc, curr) => acc + curr, 0)+dolarNumerosP2.reduce((acc, curr) => acc + curr, 0))
     
       setReaisFaturado(reaisTotalAnoP)
       setDolarFaturado(dolarTotalAnoP)



  setGrafico(true)


},[projetos])

const handleGeral= ()=>{
  setGrafico2(false)



    
    

  //ANO
  let projetosAno = projetos.filter((val)=>{if(new Date(val.data.projStartDate).getFullYear() === anoAtual && val.data.projStatus1 !== 'deletado' ){return val}})
  let reaislist = projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let reaislist2 = projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2 = reaislist2.length;
  var reaisNumeros2 = [];
  for (var i2 = 0; i2 < length2; i2++)
  reaisNumeros2.push(Number(reaislist2[i2]))

  let reaisTotalAno = (reaisNumeros.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2.reduce((acc, curr) => acc + curr, 0))

  let dolarlist =projetosAno.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var a = 0; a < lengthD; a++)
  dolarNumeros.push(Number(dolarlist[a]))

  let dolarlist2 =projetosAno.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2 = dolarlist2.length;
  var dolarNumeros2 = [];
  for (var a2 = 0; a2 < lengthD2; a2++)
  dolarNumeros2.push(Number(dolarlist2[a2]))
  let dolarTotalAno = (dolarNumeros.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2.reduce((acc, curr) => acc + curr, 0))



  setReaisAno(reaisTotalAno)
  setDolarAno(dolarTotalAno)
 

  //MES

  let projetosMes = projetos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-${MaesAtualDois}-01` && val.data.projStartDate <= `${anoAtual}-${MaesAtualDois}-${data2}` && val.data.projStatus1 !== 'deletado' ){return val}})

  let reaislistMes = projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthRM = reaislistMes.length;
  var reaisNumerosMes = [];
  for (var i3 = 0; i3 < lengthRM; i3++)
  reaisNumerosMes.push(Number(reaislistMes[i3]))

  let reaislist2Mes = projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
  var  length2Mes = reaislist2Mes.length;
  var reaisNumeros2Mes = [];
  for (var i4 = 0; i4 < length2Mes; i4++)
  reaisNumeros2Mes.push(Number(reaislist2Mes[i4]))

  let reaisTotalMes = (reaisNumerosMes.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2Mes.reduce((acc, curr) => acc + curr, 0))

  let dolarlistMes =projetosMes.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDMes = dolarlistMes.length;
  var dolarNumerosMes = [];
  for (var a3 = 0; a3 < lengthDMes; a3++)
  dolarNumerosMes.push(Number(dolarlistMes[a3]))

  let dolarlist2Mes =projetosMes.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2Mes = dolarlist2Mes.length;
  var dolarNumeros2Mes = [];
  for (var a4 = 0; a4 < lengthD2Mes; a4++)
  dolarNumeros2Mes.push(Number(dolarlist2Mes[a4]))
  let dolarTotalMes = (dolarNumerosMes.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2Mes.reduce((acc, curr) => acc + curr, 0))

  setReaisMes(reaisTotalMes)
  setDolarMes(dolarTotalMes)

  //EM ANDAMENTO
  let projetosAndamento = projetos.filter((val)=>{if(val.data.projStatus1 === 'Em Andamento' ){return val}})

  let reaislistAn = projetosAndamento.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthAn = reaislistAn.length;
  var reaisNumerosAn = [];
  for (var ia = 0; ia < lengthAn; ia++)
  reaisNumerosAn.push(Number(reaislistAn[ia]))

  let reaislist2An = projetosAndamento.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2An = reaislist2An.length;
  var reaisNumeros2An = [];
  for (var ia2 = 0; ia2 < length2An; ia2++)
  reaisNumeros2An.push(Number(reaislist2An[ia2]))

  let reaisTotalAndamento = (reaisNumerosAn.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2An.reduce((acc, curr) => acc + curr, 0))

  

  let dolarlistAn =projetosAndamento.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDAn = dolarlistAn.length;
  var dolarNumerosAn = [];
  for (var aA = 0; aA < lengthDAn; aA++)
  dolarNumerosAn.push(Number(dolarlistAn[aA]))

  let dolarlist2An =projetosAndamento.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2An = dolarlist2An.length;
  var dolarNumeros2An = [];
  for (var aA2 = 0; aA2 < lengthD2An; aA2++)
  dolarNumeros2An.push(Number(dolarlist2An[aA2]))
  let dolarTotalAndamento = (dolarNumerosAn.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2An.reduce((acc, curr) => acc + curr, 0))

  setReaisAndamento(reaisTotalAndamento)
  setDolarAndamento(dolarTotalAndamento)


  // A RECEBER

  let projetosReceber = projetos.filter((val)=>{if(val.data.projeStatus2 === 'A Receber'||  val.data.projeStatus2 === 'A Cobrar' ){return val}})
  let reaislistR = projetosReceber.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthR = reaislistR.length;
  var reaisNumerosR = [];
  for (var ir = 0; ir < lengthR; ir++)
  reaisNumerosR.push(Number(reaislistR[ir]))

  let reaislist2R = projetosReceber.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  length2R = reaislist2R.length;
  var reaisNumeros2R = [];
  for (var ir2 = 0; ir2 < length2R; ir2++)
  reaisNumeros2R.push(Number(reaislist2R[ir2]))

  let reaisTotalReceber = (reaisNumerosR.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2R.reduce((acc, curr) => acc + curr, 0))

  let dolarlistR =projetosReceber.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthDR = dolarlistR.length;
  var dolarNumerosR = [];
  for (var ar = 0; ar < lengthDR; ar++)
  dolarNumerosR.push(Number(dolarlistR[ar]))

  let dolarlist2R =projetosReceber.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')

  var  lengthD2R = dolarlist2R.length;
  var dolarNumeros2R = [];
  for (var a2r = 0; a2r < lengthD2R; a2r++)
  dolarNumeros2R.push(Number(dolarlist2R[a2r]))
  let dolarTotalReceber = (dolarNumerosR.reduce((acc, curr) => acc + curr, 0)+dolarNumeros2R.reduce((acc, curr) => acc + curr, 0))

  setReaisReceber(reaisTotalReceber)
  setDolarReceber(dolarTotalReceber)

  //BALANÇO

  setReaisFaturado(reaisNumeros.reduce((acc, curr) => acc + curr, 0))
  setDolarFaturado(dolarNumeros.reduce((acc, curr) => acc + curr, 0))

  //lideres
  let lideresAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projLideres? val.data.projLideres:''){return val}}).map((i)=>i.data.projLideres.map((i)=>i.name))
  let lideresNomes = lideresAndamento.reduce((list, sub) => list.concat(sub), [])

  let LideresemDuplicados = lideresNomes.filter((v2, i2, s2) => {
    return s2.indexOf(v2) === i2;
  });


  setLiderP(
    LideresemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": lideresNomes.filter((val)=>{if(val === item){return val}}).length

}
))
)

setLider(liderP.sort((g,h)=>{
    if (g.quantidade < h.quantidade) {
      return 1;
    }
    if (g.quantidade > h.quantidade) {
      return -1;
    }
    return 0;
    }))


  //analistas
  let analistasAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projAnalistas? val.data.projAnalistas:''){return val}}).map((i)=>i.data.projAnalistas.map((i)=>i.name))
  let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])

  let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
    return s1.indexOf(v1) === i1;
  });

  
  setAnalistasP(
    analistasemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": analistasNomes.filter((val)=>{if(val === item){return val}}).length

}
))
)

setAnalistas(analistasP.sort((e,f)=>{
    if (e.quantidade < f.quantidade) {
      return 1;
    }
    if (e.quantidade > f.quantidade) {
      return -1;
    }
    
    return 0;
    }))


  
  

  //clientes
  let clientesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projClient)
  let clientesemDuplicados = clientesAndamento.filter((valor, indice, self) => {
    return self.indexOf(valor) === indice;
  });

     
  setClientesP(
        clientesemDuplicados.map((item, index)=>(
      {
        "id": index,
        "name":item,
        "quantidade": clientesAndamento.filter((val)=>{if(val === item){return val}}).length

    }
  ))
  )

  setCLientes(clientesP.sort((a,b)=>{
    if (a.quantidade < b.quantidade) {
      return 1;
    }
    if (a.quantidade > b.quantidade) {
      return -1;
    }
    return 0;
  }))

  
   




  //paises

  let paisesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projHiringCountry)
  let paissemDuplicados = paisesAndamento.filter((v, i, s) => {
    return s.indexOf(v) === i;
  });


  setPaisP(
    paissemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": paisesAndamento.filter((val)=>{if(val === item){return val}}).length

}
))
)

    setPais(paisP.sort((c,d)=>{
    if (c.quantidade < d.quantidade) {
      return 1;
    }
    if (c.quantidade > d.quantidade) {
      return -1;
    }
    return 0;
    }))
//Jurisdições
let jurisdicoesAndamento =  projetos.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento')){return val}}).map((i)=>i.data.projJurisdictions)
  let juriscicoesNomes = jurisdicoesAndamento.reduce((list, sub) => list.concat(sub), [])

  let jurisdicoesSemDuplicados = juriscicoesNomes.filter((v1, i1, s1) => {
    return s1.indexOf(v1) === i1;
  });

  
  setJurisdicoesP(
    jurisdicoesSemDuplicados.map((item, index)=>(
  {
    "id": index,
    "name":item,
    "quantidade": juriscicoesNomes.filter((val)=>{if(val === item){return val}}).length

}
))
)


setJuridicoes(jurisdicoesP.sort((e,f)=>{
    if (e.quantidade < f.quantidade) {
      return 1;
    }
    if (e.quantidade > f.quantidade) {
      return -1;
    }
    return 0;
    }))

  setGrafico2(true)

}


const handleBalanco = ()=>{
   //BALANÇO

   let periodo = custos.map((i)=>(i.data.date))
   let periodoMes = periodo[0]
    setMesBtexto(periodoMes)
   setMesdalanco( ()=>{
    if(periodoMes === 'Janeiro'){
      setMesdalanco('01')
    }
    if(periodoMes === 'Fevereiro'){
      setMesdalanco('02')
    }
    if(periodoMes === 'Março'){
      setMesdalanco('03')
    }
    if(periodoMes === 'Abril'){
      setMesdalanco('04')
    }
    if(periodoMes === 'Maio'){
      setMesdalanco('05')
    }
    if(periodoMes === 'Junho'){
      setMesdalanco('06')
    }
    if(periodoMes === 'Julho'){
      setMesdalanco('07')
    }
    if(periodoMes === 'Agosto'){
      setMesdalanco('08')
    }
    if(periodoMes === 'Setembro'){
      setMesdalanco('09')
    }
    if(periodoMes === 'Outubro'){
      setMesdalanco('10')
    }
    if(periodoMes === 'Novembro'){
      setMesdalanco('11')
    }
    if(periodoMes === 'Dezembro'){
      setMesdalanco('12')
    }
  }
   )
   let projetosPeriodo = projetos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-01-01` && val.data.projStartDate <= `${anoAtual}-${mesBalanco}-30` && val.data.projStatus1 !== 'deletado' ){return val}})

    let reaislista = projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var  lengthP = reaislista.length;
      var reaisNumerosP = [];
      for (var i = 0; i < lengthP; i++)
      reaisNumerosP.push(Number(reaislista[i]))
    
      let reaislista2 = projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('BRL'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    
      var  length2P = reaislista2.length;
      var reaisNumeros2P = [];
      for (var i2 = 0; i2 < length2P; i2++)
      reaisNumeros2P.push(Number(reaislista2[i2]))
    
      let reaisTotalAnoP = (reaisNumerosP.reduce((acc, curr) => acc + curr, 0)+reaisNumeros2P.reduce((acc, curr) => acc + curr, 0))
    
      let dolarlistP =projetosPeriodo.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
      var  lengthDP = dolarlistP.length;
      var dolarNumerosP = [];
      for (var a = 0; a < lengthDP; a++)
      dolarNumerosP.push(Number(dolarlistP[a]))
    
      let dolarlistP2 =projetosPeriodo.filter((val)=>{ if(val.data.proj2Currency? val.data.proj2Currency.includes('USD'):''){return val}}).map((item)=>item.data.projCovertedValue).toString().split(',')
    
      var  lengthDP2 = dolarlistP2.length;
      var dolarNumerosP2 = [];
      for (var a2 = 0; a2 < lengthDP2; a2++)
      dolarNumerosP2.push(Number(dolarlistP2[a2]))
      let dolarTotalAnoP = (dolarNumerosP.reduce((acc, curr) => acc + curr, 0)+dolarNumerosP2.reduce((acc, curr) => acc + curr, 0))

  setReaisFaturado(reaisTotalAnoP)
  setDolarFaturado(dolarTotalAnoP)


   setBalanco(true)
}





  return (
    <div>
      <div>
         <Header />
        {loading &&
           <div className="pageTitle">
           <div>Carregando...</div>
         </div>
        }
        {!loading &&
          <>
          <div className="pageTitle">
            <div>Visão Geral</div>
            <div className='subtitle'>Ano:&nbsp;{anoAtual}&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;Mês:&nbsp;{mesAtualTxt} </div>
            
          </div>
        
        {grafico &&
          
          <div className="pageContent collum">
            <div className="contentLine2">Projetos</div>
            <div className="contentLine">
                <div className="bloco">
                    <div className="linesTitulo">Ano</div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Projetos Vendidos:</p></div>
                      <div className="lines-itemR">{projetos.filter((val)=>{if(new Date(val.data.projStartDate).getFullYear() === anoAtual && val.data.projStatus1 !== 'deletado' ){return val}}).length}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores USD:</p></div>
                      <div className="lines-itemR">US$ {dolarAno.toLocaleString('pt-BR')}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores BRL:</p></div>
                      <div className="lines-itemR">RS$ {reaisAno.toLocaleString('pt-BR')}</div>
                    </div>
                    <div className="linesTitulo" style={{marginTop:15+'px'}}>Mês</div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Projetos Vendidos:</p></div>
                      <div className="lines-itemR">{projetos.filter((val)=>{if(val.data.projStartDate >= `${anoAtual}-${MaesAtualDois}-01` && val.data.projStartDate <= `${anoAtual}-${MaesAtualDois}-${data2}` && val.data.projStatus1 !== 'deletado' ){return val}}).length}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores USD:</p></div>
                      <div className="lines-itemR">US$ {dolarMes.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores BRL:</p></div>
                      <div className="lines-itemR">RS$ {reaisMes.toLocaleString('pt-br')}</div>
                    </div>
                  </div>

                  <div className="bloco">
                    <div className="linesTitulo">Em Andamento</div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Quantidade:</p></div>
                      <div className="lines-itemR">{projetos.filter((val)=>{if(val.data.projStatus1 === 'Em Andamento' ){return val}}).length}</div>
                    </div>
                    <div className="lines">
                    <div className="lines-itemL"><p>Valores USD:</p></div>
                      <div className="lines-itemR">US$ {dolarAndamento.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores BRL:</p></div>
                      <div className="lines-itemR">RS$ {reaisAndamento.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="linesTitulo" style={{marginTop:15+'px'}}>A Receber</div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Quantidade:</p></div>
                      <div className="lines-itemR">{projetos.filter((val)=>{if(val.data.projeStatus2 === 'A Receber' || val.data.projeStatus2 === 'A Cobrar' ){return val}}).length}</div>
                    </div>
                    <div className="lines">
                    <div className="lines-itemL"><p>Valores USD:</p></div>
                      <div className="lines-itemR">US$ {dolarReceber.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                      <div className="lines-itemL"><p>Valores BRL:</p></div>
                      <div className="lines-itemR">RS$ {reaisReceber.toLocaleString('pt-br')}</div>
                    </div>
                  </div>

              </div>
              {!balanco &&
              <div className="pageTitle" style={{marginTop:20+'px'}}>
                  <div><button onClick={handleBalanco} >Gerar Balanço</button></div>
                 
                    <div>Clique no botão acima para gerar o balanço do período</div>
                 
                </div>
                 }
               {balanco &&
               <>
               
              <div className="contentLine2">Balanço  {anoAtual} - de Janeiro à {mesBtexto}</div>
              <div className="contentLine">
              <div className="bloco">
                    <div className="linesTitulo">Entradas</div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Fatrumento Dólar:</p></div>
                        <div className="lines-itemR">US$ {dolarFaturado.toLocaleString('pt-BR')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Fatrumento Reais:</p></div>
                        <div className="lines-itemR">RS$ {reaisFaturado.toLocaleString('pt-BR')}</div>
                    </div>
                    <div className="linesTitulo">Saídas</div>
                    {custos.map((item, index)=>(
                    <div className="bloco" key={index}>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Pessoal Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.pessoalReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Impostos Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.impostosReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Gastos Fornecedores Reais:</p></div>
                        <div className="lines-itemR">RS$ {item.data.fornecedoresReais.toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Total de Gastos Reais:</p></div>
                        <div className="lines-itemR">RS$ {(item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais).toLocaleString('pt-br')}</div>
                    </div>

                    <div className="lines">
                        <div className="lines-itemL"><p>Atualizados Até o Mês:</p></div>
                        <div className="lines-itemR">{item.data.date}</div>
                    </div>
                    </div>
                    ))}

                  </div>

                  <div className="bloco">
                  <div className="linesTitulo">Cálculos</div>
                  {custos.map((item, index)=>(
                  <div className="bloco" key={index}>
                    
                    <div className="lines">
                        <div className="lines-itemL"><p>Lucro Reais:</p></div>
                        <div className="lines-itemR">RS$ {(reaisFaturado-(item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais)).toLocaleString('pt-br')}</div>
                    </div>
                    <div className="lines">
                        <div className="lines-itemL"><p>Lucro Reais % :</p></div>
                        <div className="lines-itemR">{Math.round(100-((item.data.pessoalReais+item.data.impostosReais+item.data.fornecedoresReais)/reaisFaturado)*100)} %</div>
                    </div>
                    </div>
                    ))}

                  </div>

              </div>
              </>}  
              {grafico2 === false &&
                <div className="pageTitle" style={{marginTop:20+'px'}}>
                  <div><button onClick={handleGeral} >Gerar Listas</button></div>
                  
                    <div>Clique no botão acima para visualizar a listagem do time, clientes, paises e jurisdições com projetos em andamento</div>
                  
                </div>}

                
                {grafico2 === true &&
                  <>
                    <div className="contentLine2">Time em Atuação</div>
                    <div className="contentLine">
                      <div className="bloco">
                      <div className="linesTitulo">Líderes de Projeto</div>
                          {lider.map((item,index)=>(
                            <div className="lines" key={index}>
                            <div className="lines-itemL"><p>{item.name}: </p></div>
                            <div className="lines-itemR">{item.quantidade}</div>
                          </div>
                          ))}
                        </div>
                        <div className="bloco">
                      <div className="linesTitulo">Analistas</div>
                          {analistas.map((item,index)=>(
                            <div className="lines" key={index}>
                            <div className="lines-itemL"><p>{item.name}: </p></div>
                            <div className="lines-itemR">{item.quantidade}</div>
                          </div>
                          ))}
                        </div>
                    </div>
                    <div className="contentLine2">Clientes - Países de Contratação - Jurisdições</div>
                    <div className="contentLine-3" style={{marginBottom:'50px'}}>
                      <div className="bloco">
                        <div className="linesTitulo">Clientes</div>
                          {clientes.map((item,index)=>(
                            <div className="lines" key={index}>
                            <div className="lines-itemL"><p>{item.name}: </p></div>
                            <div className="lines-itemR">{item.quantidade}</div>
                          </div>
                          ))}
                      </div>
                      <div className="bloco">
                        <div className="linesTitulo">Países</div>
                        {pais.map((item,index)=>(
                            <div className="lines" key={index}>
                            <div className="lines-itemL"><p>{item.name}: </p></div>
                            <div className="lines-itemR">{item.quantidade}</div>
                          </div>
                          ))}
                      </div>
                      <div className="bloco">
                        <div className="linesTitulo">Jurisdições</div>
                        {jurisdicoes.map((item,index)=>(
                            <div className="lines" key={index}>
                            <div className="lines-itemL"><p>{item.name}: </p></div>
                            <div className="lines-itemR">{item.quantidade}</div>
                          </div>
                          ))}
                      </div>
                    </div>
                  </>
                
                }
              
             
              
          </div>
             
        }
          </> }
    </div>
    </div>
   
  )
}

export default Home